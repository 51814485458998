import React from 'react';

function ValueCard({ bgColor, icon, title, description }) {
  return (
    <div className="flex flex-col w-[33%] max-md:ml-0 max-md:w-full">
      <div className={`flex flex-col grow items-center px-14 py-20 w-full text-center ${bgColor} rounded-[39px] max-md:px-5 max-md:mt-6`}>
        <img
          loading="lazy"
          src={icon}
          alt={`${title} Icon`}
          className="object-contain max-w-full aspect-square w-[120px]"
        />
        <div className="mt-16 text-4xl font-black text-black max-md:mt-10">
          {title}
        </div>
        <div className="self-stretch mt-5 text-lg font-medium leading-7 text-stone-500">
          {description}
        </div>
      </div>
    </div>
  );
}

export default ValueCard;