import React from "react";
import Footer from "../../Footer";
import BusinessHero from "../../LandingPage/BusinessHero";
import FreelanceSolution from "./FreelanceSolution";
import Header from "./Header";
import { HeroContent } from "./HeroContent";
import { HeroSection } from "./HeroSection";
import MyComponent from "./MyComponent";
import SearchPage from "./SearchPage";
import TalentLocations from "./TalentLocations";
import TrendingProjects from "./TrendingProjects";
import { WhyChoose } from "./WhyChoose";
import Services from "./Services";
import AnimatedBackground from "./Background/Background";

const LandingPageV2 = () => {
  return (
    <div style={{ position: "relative", minHeight: "100vh" }}>
      <AnimatedBackground />
      <div
       
      >
        <Header />
        <SearchPage />
        <TrendingProjects />
        <Services />
        <TalentLocations />
        <WhyChoose />
        <HeroSection />
        <FreelanceSolution />
        <BusinessHero />
        <MyComponent />
        <Footer />
      </div>
    </div>
  );
};

export default LandingPageV2;
