import * as React from "react";
import { useState } from "react";

const SearchBar = ({ searchValue, setSearchValue }) => {
  return (
    <form className="flex items-center gap-4 px-6 py-4 mt-6 rounded-full bg-white shadow-[0px_4px_10px_rgba(0,0,0,0.1)] w-full max-w-[600px] max-md:w-[90%]">
      <label htmlFor="searchInput" className="sr-only">Ask AI anything</label>
      <input
        type="text"
        id="searchInput"
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        className="flex-grow bg-transparent border-none outline-none text-gray-700 text-lg placeholder-gray-400"
        placeholder="Ask AI anything"
      />
    </form>
  );
};

const SearchSuggestion = ({ text, onClick }) => {
  return (
    <button
      onClick={() => onClick(text)}
      className="px-6 py-2 rounded-full shadow-[0px_3px_5px_rgba(0,0,0,0.1)] bg-white text-gray-700 hover:shadow-[0px_4px_10px_rgba(0,0,0,0.2)] active:scale-95 transition-transform text-sm max-md:px-4 max-md:text-xs"
    >
      {text}
    </button>
  );
};

const SearchPage = () => {
  const [searchValue, setSearchValue] = useState("");
  const topSuggestions = [
    "Find a pro for video editing",
    "Connect me with a social media expert",
    "Find a pro for video editing",
  ];
  const bottomSuggestions = [
    "Find a skilled logo designer",
    "Help me write compelling content",
  ];

  const handleSuggestionClick = (suggestion) => {
    setSearchValue(suggestion);
  };

  return (
    <div className="flex flex-col items-center justify-center px-4 py-12 bg-transparent min-h-screen text-gray-800">
      {/* Title */}
      <h1 className="text-6xl font-bold text-center leading-tight max-md:text-4xl">
        Talent, Working, Earning
      </h1>
      <p className="mt-4 text-center text-2xl max-md:text-lg text-gray-600">
        Discover top talent or your next opportunity with AI-driven tools that put you in charge.
      </p>

      {/* Search Bar */}
      <SearchBar searchValue={searchValue} setSearchValue={setSearchValue} />

      {/* Suggestions */}
      <div className="flex flex-wrap gap-4 justify-center mt-10 max-w-[700px] w-full">
        {topSuggestions.map((suggestion, index) => (
          <SearchSuggestion
            key={index}
            text={suggestion}
            onClick={handleSuggestionClick}
          />
        ))}
      </div>
      <div className="flex flex-wrap gap-4 justify-center mt-6 max-w-[700px] w-full">
        {bottomSuggestions.map((suggestion, index) => (
          <SearchSuggestion
            key={index}
            text={suggestion}
            onClick={handleSuggestionClick}
          />
        ))}
      </div>
    </div>
  );
};

export default SearchPage;
