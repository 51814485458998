import * as React from "react";

export function HeroSection() {
  return (
    <section className="flex justify-center py-16 px-4 bg-transparent">
      <div className="flex flex-col md:flex-row items-center bg-black rounded-[45px] max-w-[1200px] w-full px-6 sm:px-10 py-12 sm:py-14 gap-8 sm:gap-10">
        {/* Left Content */}
        <div className="text-white flex-1">
          <h1 className="text-3xl sm:text-4xl md:text-5xl font-bold leading-tight">
            Start earning on Earn today <br />
            Your skills deserve it!
          </h1>
          <p className="mt-6 text-base sm:text-lg md:text-xl text-gray-300 leading-7 sm:leading-8">
            At Earn, we empower freelancers to take control of their careers
            and clients to access world-class talent. Whether you're just
            starting or a seasoned pro, Earn makes it easy to land gigs, deliver
            results, and grow your business.
          </p>
          <button className="mt-8 px-6 py-3 bg-blue-500 text-white rounded-md text-base sm:text-lg font-medium hover:bg-blue-600 transition-all">
            Get Started
          </button>
        </div>

        {/* Right Image */}
        <div className="flex-1">
          <div className="bg-yellow-400 rounded-[39px] overflow-hidden">
            <img
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/73cd5e4048bdb5d57547389c0ab1a268019004918cd65e5638a9e5527be91290?placeholderIfAbsent=true&apiKey=44862712bdb34f49aaf0b7f367088f08"
              alt="Freelancer working on Earn platform"
              className="w-full h-full object-cover"
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default HeroSection;
