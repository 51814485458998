import React, { useState, useEffect } from "react";
import { collection, query, onSnapshot, where } from "firebase/firestore";
import { Link } from "react-router-dom";
import { db } from "../../config/firebase"; // Import Firebase configuration
import { useAuth } from "../../context/AuthContext"; // Import AuthContext
import { ChevronLeft, ChevronRight } from "lucide-react";
import ServiceCard from "./ServiceCard";
import MarketplaceHeader from "../Dashboard/MarketplaceHeader";

export default function ServiceListingPage() {
  const [services, setServices] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [searchTerm, setSearchTerm] = useState("");
  const { authState, signOut } = useAuth(); // Access AuthContext
  const { selectedSubcategory, setSelectedSubcategory } = useState(); // Access SubcategoryContext

  const categories = [
    "All",
    "Graphic Design",
    "Digital Marketing",
    "Writing & Translation",
    "Video & Animation",
    "Photography",
    "AI Services",
    "Lifestyle",
    "Music & Audio",
    "Business & Finance",
  ];

  useEffect(() => {
    const fetchServices = async () => {
      try {
        // Create Firestore query based on selected category and subcategory
        let servicesQuery;
        if (selectedCategory === "All") {
          servicesQuery = query(collection(db, "services"));
        } else if (selectedSubcategory) {
          servicesQuery = query(
            collection(db, "services"),
            where("subcategory", "==", selectedSubcategory)
          );
        } else {
          servicesQuery = query(
            collection(db, "services"),
            where("category", "==", selectedCategory)
          );
        }
  
        const unsubscribe = onSnapshot(servicesQuery, (snapshot) => {
          const fetchedServices = snapshot.docs
            .map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }))
            .filter((service) =>
              service.serviceTitle?.toLowerCase().includes(searchTerm?.toLowerCase())
            );
          setServices(fetchedServices);
        });
  
        return () => unsubscribe();
      } catch (error) {
        console.error("Error fetching services:", error);
      }
    };
  
    fetchServices();
  }, [selectedCategory, selectedSubcategory, searchTerm]);
  
  return (
    <div className="min-h-screen bg-white">
      {/* Header with Categories and User Info */}
      <MarketplaceHeader
        selectedCategory={selectedCategory}
        setSelectedCategory={setSelectedCategory}
        handleSignOut={signOut}
        user={authState.user}
      />

      {/* Search Bar */}
      <div className="max-w-7xl mx-auto px-4 py-4">
        <input
          type="text"
          placeholder="Search services..."
          className="w-full px-4 py-2 border rounded-lg"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>

      {/* Main Content */}
      <main className="max-w-7xl mx-auto px-4 py-8 space-y-12">
        {/* New Listings Section */}
        <Section
          title={`New listings in ${selectedCategory}`}
          services={services}
        />

        {/* Top Creators Section */}
        <Section title="Top creators" services={services.slice(0, 5)} />

        {/* Design Showcase */}
        <section>
          <h2 className="text-2xl font-bold mb-6">
            Design showcase from our top graphic design creators
          </h2>
          <div className="grid grid-cols-4 gap-4">
            {Array(4)
              .fill(null)
              .map((_, i) => (
                <div key={i} className="aspect-square bg-gray-100 rounded-xl" />
              ))}
          </div>
        </section>
      </main>
    </div>
  );
}

// Section Component
function Section({ title, services }) {
  return (
    <section>
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-bold">{title}</h2>
        <div className="flex items-center gap-4">
          <Link to="/show-all" className="text-sm font-medium">
            Show All
          </Link>
          <div className="flex gap-2">
            <button className="p-1 rounded-full border">
              <ChevronLeft className="w-4 h-4" />
            </button>
            <button className="p-1 rounded-full border">
              <ChevronRight className="w-4 h-4" />
            </button>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-5 gap-4">
        {services.map((service) => (
          <ServiceCard key={service.id} service={service} />
        ))}
      </div>
    </section>
  );
}
