import React, { useState, useEffect } from "react";
import { collection, query, onSnapshot, where } from "firebase/firestore";
import { Link } from "react-router-dom";
import { db } from "../../config/firebase"; // Import Firebase configuration
import { useAuth } from "../../context/AuthContext"; // Import AuthContext
import { ChevronLeft, ChevronRight } from "lucide-react";
import ServiceCard from "./ServiceCard";
import MarketplaceHeader from "../Dashboard/MarketplaceHeader";

export default function MyServicesListingPage() {
  const [services, setServices] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const { authState, signOut } = useAuth(); // Access AuthContext
  const [ selectedCategory, setSelectedCategory ] = useState("All");

  useEffect(() => {
    const fetchMyServices = async () => {
      try {
        // Ensure the user is authenticated
        if (!authState.user?.uid) return;

        // Query Firestore for services created by the authenticated user
        const myServicesQuery = query(
          collection(db, "services"),
          where("uid", "==", authState.user.uid)
        );

        const unsubscribe = onSnapshot(myServicesQuery, (snapshot) => {
          const fetchedServices = snapshot.docs
            .map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }))
            .filter((service) =>
              service.serviceTitle
                ?.toLowerCase()
                .includes(searchTerm.toLowerCase())
            );
          setServices(fetchedServices);
        });

        return () => unsubscribe();
      } catch (error) {
        console.error("Error fetching user services:", error);
      }
    };

    fetchMyServices();
  }, [authState.user?.uid, searchTerm]);

  return (
    <div className="min-h-screen bg-white">
      {/* Header with User Info */}
      <MarketplaceHeader
        handleSignOut={signOut}
        user={authState.user}
        selectedCategory={selectedCategory}
        setSelectedCategory={setSelectedCategory}
      />

      {/* Search Bar */}
      <div className="max-w-7xl mx-auto px-4 py-4">
        <input
          type="text"
          placeholder="Search your services..."
          className="w-full px-4 py-2 border rounded-lg"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>

      {/* Main Content */}
      <main className="max-w-7xl mx-auto px-4 py-8 space-y-12">
        {/* My Services Section */}
        <Section title="My Services" services={services} />

        {/* Empty State */}
        {services.length === 0 && (
          <div className="text-center mt-12">
            <p className="text-gray-600">No services found. Start creating your first service!</p>
            <Link
              to="/createService"
              className="mt-4 inline-block px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition"
            >
              Create Service
            </Link>
          </div>
        )}
      </main>
    </div>
  );
}

// Section Component
function Section({ title, services }) {
  return (
    <section>
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-bold">{title}</h2>
        <div className="flex items-center gap-4">
          <Link to="/show-all" className="text-sm font-medium">
            Show All
          </Link>
          <div className="flex gap-2">
            <button className="p-1 rounded-full border">
              <ChevronLeft className="w-4 h-4" />
            </button>
            <button className="p-1 rounded-full border">
              <ChevronRight className="w-4 h-4" />
            </button>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-5 gap-4">
        {services.map((service) => (
          <ServiceCard key={service.id} service={service} />
        ))}
      </div>
    </section>
  );
}
