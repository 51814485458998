import React, { useEffect, useState } from "react";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../config/firebase";
import { Star, Bookmark, Verified } from "lucide-react";
import { useNavigate } from "react-router-dom";

function ServiceCard({ service }) {
  const [user, setUser] = useState(null);

  const navigate = useNavigate();

  // Fetch user data based on uid
  useEffect(() => {
    const fetchUserData = async () => {
      if (!service.uid) return;
      try {
        const userDoc = await getDoc(doc(db, "users", service.uid));
        if (userDoc.exists()) {
          setUser(userDoc.data());
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, [service.uid]);

  const handleSendMessage = () => {
    navigate(`/messages/${service.uid}`);
  };

  return (
    <div className="bg-white rounded-xl shadow">
      {/* Image Container */}
      <div
        onClick={() => navigate(`/service/${service.id}`)}
        className="relative aspect-[4/3] bg-gray-100 rounded-t-xl"
      >
        {service.media?.images?.[0] ? (
          <img
            src={service.media.images[0]}
            alt={service.serviceTitle}
            className="w-full h-full object-cover rounded-t-xl"
          />
        ) : (
          <div className="flex items-center justify-center h-full text-gray-500">
            No Image
          </div>
        )}
        <button className="absolute top-2 right-2 p-1 bg-white rounded-md shadow-sm">
          <Bookmark className="w-4 h-4" />
        </button>
      </div>

      {/* Content */}
      <div className="p-3 space-y-3">
        {/* Provider Info */}
        <div className="flex items-start justify-between">
          <div>
            <div className="flex items-center gap-1">
              <span className="text-sm font-medium">
                {user?.name || "Loading..."}
              </span>
              {user?.verified && (
                <Verified className="w-4 h-4 text-blue-600 fill-blue-600" />
              )}
            </div>
            <p className="text-xs text-gray-500">
              {user?.address || "Location not available"}
            </p>
          </div>
          <div className="flex items-center bg-blue-600 px-2 py-0.5 rounded-full text-white text-xs">
            <Star className="w-3 h-3 mr-0.5" />
            <span>{service.rating || "0"}</span>
            <span className="ml-0.5">({service.reviews || "0"})</span>
          </div>
        </div>

        {/* Description */}
        <p className="text-xs text-gray-700 line-clamp-2">
          {service.serviceTitle}
        </p>

        {/* Footer */}
        <div className="flex items-center justify-between pt-2 border-t">
          <button
            onClick={() => handleSendMessage()}
            className="px-4 py-1.5 text-xs font-medium border rounded-full hover:bg-gray-50"
          >
            Send Message
          </button>
          <div className="text-right">
            <span className="text-xs text-gray-500">from</span>
            <span className="ml-1 font-bold">
              P{service.packages?.Basic?.price || "N/A"}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ServiceCard;
