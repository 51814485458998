import React from "react";
import YourSVG from "../../../asserts/services.svg"; // Update the path to your SVG file

function Services() {
  return (
    <div className="flex justify-center items-center p-6 md:p-12 w-full">
     
        {/* Wrapping the SVG */}
        <div className="relative w-full max-w-full h-auto">
          <img
            src={YourSVG}
            alt="Graph showing EARN categories"
            className="w-full h-auto object-contain"
          />
        
      </div>
    </div>
  );
}

export default Services;
