import React, { createContext, useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import axios from "../api/axios"; // Axios already configured with baseURL
import { auth, googleProvider } from "../config/firebase";
import { signInWithPopup, GoogleAuthProvider } from "firebase/auth";



// Create the AuthContext
const AuthContext = createContext();

// Custom hook to use the AuthContext
export const useAuth = () => {
  return useContext(AuthContext);
};

// AuthProvider component to wrap your app
export const AuthProvider = ({ children }) => {
  const [authState, setAuthState] = useState(() => {
    const storedState = localStorage.getItem("authState");
    console.log(storedState);
    return storedState
      ? JSON.parse(storedState)
      : {
          isAuthenticated: false,
          user: null,
          token: null,
          status: "idle", // 'idle' | 'loading' | 'success' | 'error'
          error: null,
        };
  });

  useEffect(() => {
    localStorage.setItem("authState", JSON.stringify(authState));
    if (authState.token) {
      setAuthToken(authState.token);
    }
  }, [authState]);

  // useEffect(() => {
  //   const restoreSession = async () => {
  //     const storedState = localStorage.getItem("authState");
  //     if (storedState) {
  //       const parsedState = JSON.parse(storedState);
  //       if (parsedState.token) {
  //         setAuthToken(parsedState.token);
  //         try {
  //           const response = await axios.get("/api/auth/profile");
  //           setAuthState({
  //             isAuthenticated: true,
  //             user: response.data,
  //             token: parsedState.token,
  //             status: "success",
  //             error: null,
  //           });
  //         } catch (error) {
  //           console.error("Error restoring session:", error);
  //           signOut(); // Clear state if session restore fails
  //         }
  //       }
  //     }
  //   };

  //   restoreSession();
  // }, []);

  // Helper: Set token in Axios
  const setAuthToken = (token) => {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  };

  // Helper: Clear token from Axios
  const clearAuthToken = () => {
    delete axios.defaults.headers.common["Authorization"];
  };

  // -------------------- Authentication Functions --------------------

  const signIn = async (credentials) => {
    setAuthState((prev) => ({ ...prev, status: "loading", error: null }));
    try {
      const response = await axios.post("/api/auth/login", credentials);
      const { token, user } = response.data;

      setAuthState({
        isAuthenticated: true,
        user,
        token,
        status: "success",
        error: null,
      });

      return { success: true, user };
    } catch (error) {
      const errorMessage =
        error.response?.data?.message ||
        (error.message.includes("CORS") ? "CORS error. Check backend config." : "Failed to sign in");

      setAuthState((prev) => ({
        ...prev,
        status: "error",
        error: errorMessage,
      }));
      console.error("Sign-in Error:", error); // Log the full error for debugging
      return { success: false, error: errorMessage };
    }
  };

  const signUp = async (data) => {
    setAuthState((prev) => ({ ...prev, status: "loading", error: null }));
    try {
      const response = await axios.post("/api/auth/register", data);
      const { user } = response.data;

      setAuthState((prev) => ({
        ...prev,
        user,
        status: "success",
        error: null,
      }));

      return { success: true, user };
    } catch (error) {
      const errorMessage =
        error.response?.data?.message ||
        (error.message.includes("CORS") ? "CORS error. Check backend config." : "Failed to register");

      setAuthState((prev) => ({
        ...prev,
        status: "error",
        error: errorMessage,
      }));
      console.error("Sign-up Error:", error); // Log the full error for debugging
      return { success: false, error: errorMessage };
    }
  };

  const signOut = () => {
    setAuthState({
      isAuthenticated: false,
      user: null,
      token: null,
      status: "idle",
      error: null,
    });
    clearAuthToken();
    localStorage.removeItem("authState");
  };

  const gSignIn = async () => {
    setAuthState((prev) => ({ ...prev, status: "loading", error: null }));
    const provider = new GoogleAuthProvider();
    try {
      
      const result = await signInWithPopup(auth,provider);
      const { user } = result;
      const token = await user.getIdToken();

      const response = await axios.post("/api/auth/google-login", { tokenId: token });
      const { token: backendToken, user: backendUser, newUser } = response.data;

      setAuthState({
        isAuthenticated: true,
        user: backendUser,
        token: backendToken,
        status: "success",
        error: null,
      });

      console.log('newUser',newUser);

      return { success: true, user: backendUser };
    } catch (error) {
      const errorMessage =
        error.response?.data?.message ||
        (error.message.includes("CORS") ? "CORS error. Check backend config." : "Failed to sign in with Google");

        console.log(provider,error);

      setAuthState((prev) => ({
        ...prev,
        status: "error",
        error: errorMessage,
      }));
      return { success: false, error: errorMessage };
    }
  };

  // -------------------- Profile Management --------------------

  const fetchUserProfile = async () => {
    setAuthState((prev) => ({ ...prev, status: "loading", error: null }));
    try {
      const response = await axios.get("/api/auth/update-profile");
      setAuthState((prev) => ({
        ...prev,
        user: response.data,
        status: "success",
      }));
      return { success: true, user: response.data };
    } catch (error) {
      const errorMessage =
        error.response?.data?.message ||
        (error.message.includes("CORS") ? "CORS error. Check backend config." : "Failed to fetch profile");

      setAuthState((prev) => ({
        ...prev,
        status: "error",
        error: errorMessage,
      }));
      console.error("Fetch Profile Error:", error); // Log the full error for debugging
      return { success: false, error: errorMessage };
    }
  };

  const updateUserProfile = async (updatedData) => {
    setAuthState((prev) => ({ ...prev, status: "loading", error: null }));
    try {
      const response = await axios.put("/api/auth/update-profile", updatedData);
      setAuthState((prev) => ({
        ...prev,
        user: response.data,
        status: "success",
      }));
      return { success: true, user: response.data };
    } catch (error) {
      const errorMessage =
        error.response?.data?.message ||
        (error.message.includes("CORS") ? "CORS error. Check backend config." : "Failed to update profile");

      setAuthState((prev) => ({
        ...prev,
        status: "error",
        error: errorMessage,
      }));
      console.error("Update Profile Error:", error); // Log the full error for debugging
      return { success: false, error: errorMessage };
    }
  };

  const changePassword = async (newPassword) => {
    setAuthState((prev) => ({ ...prev, status: "loading", error: null }));
    try {
      await axios.put("/api/auth/change-password", { newPassword });
      setAuthState((prev) => ({ ...prev, status: "success", error: null }));
      return { success: true };
    } catch (error) {
      const errorMessage =
        error.response?.data?.message ||
        (error.message.includes("CORS") ? "CORS error. Check backend config." : "Failed to change password");

      setAuthState((prev) => ({
        ...prev,
        status: "error",
        error: errorMessage,
      }));
      console.error("Change Password Error:", error); // Log the full error for debugging
      return { success: false, error: errorMessage };
    }
  };

  const updateUsagePlan = async (usageType) => {
    if (!["Freelancer", "Buyer"].includes(usageType)) {
      return { success: false, error: "Invalid usage type. Must be 'Freelancer' or 'Buyer'." };
    }

    return await updateUserProfile({ role: usageType });
  };

  // -------------------- Context Value --------------------

  const value = {
    // Authentication
    signIn,
    signUp,
    signOut,
    gSignIn,

    // Profile Management
    fetchUserProfile,
    updateUserProfile,
    changePassword,
    updateUsagePlan,

    // Auth State
    authState,
    setAuthState,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
