import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import SetupHeader from "./SetuphHeader";
import Footer from "../Footer";

export default function PricingPage() {
  const navigate = useNavigate();
  const location = useLocation();

  // Retrieve data from the previous page
  const { serviceTitle, category, subCategory, description } = location.state || {};

  // Redirect if required fields are missing
  useEffect(() => {
    if (!serviceTitle || !category || !subCategory || !description) {
      alert("Required fields are missing. Redirecting to service creation page.");
      navigate("/createService");
    }
  }, [serviceTitle, category, subCategory, description, navigate]);

  const [packages, setPackages] = useState({
    Basic: {
      isActive: true,
      name: "",
      description: "",
      deliveryTime: { quantity: 0, unit: "" },
      revisions: 0,
      deliverables: [],
      fileTypes: [],
    },
    Standard: {
      isActive: false,
      name: "",
      description: "",
      deliveryTime: { quantity: 0, unit: "" },
      revisions: 0,
      deliverables: [],
      fileTypes: [],
    },
    Premium: {
      isActive: false,
      name: "",
      description: "",
      deliveryTime: { quantity: 0, unit: "" },
      revisions: 0,
      deliverables: [],
      fileTypes: [],
    },
  });

  const togglePackage = (packageName) => {
    setPackages((prev) => ({
      ...prev,
      [packageName]: {
        ...prev[packageName],
        isActive: !prev[packageName].isActive,
      },
    }));
  };

  const handleInputChange = (packageName, field, value) => {
    setPackages((prev) => ({
      ...prev,
      [packageName]: {
        ...prev[packageName],
        [field]: value,
      },
    }));
  };

  const handleDeliveryTimeChange = (packageName, field, value) => {
    setPackages((prev) => ({
      ...prev,
      [packageName]: {
        ...prev[packageName],
        deliveryTime: {
          ...prev[packageName].deliveryTime,
          [field]: value,
        },
      },
    }));
  };

  const handleAddDeliverable = (packageName, deliverable) => {
    if (deliverable.trim()) {
      setPackages((prev) => ({
        ...prev,
        [packageName]: {
          ...prev[packageName],
          deliverables: [...prev[packageName].deliverables, deliverable.trim()],
        },
      }));
    }
  };

  const handleRemoveDeliverable = (packageName, index) => {
    setPackages((prev) => ({
      ...prev,
      [packageName]: {
        ...prev[packageName],
        deliverables: prev[packageName].deliverables.filter((_, i) => i !== index),
      },
    }));
  };

  const validatePackages = () => {
    const activePackages = Object.entries(packages).filter(
      ([_, data]) => data.isActive
    );

    for (const [name, data] of activePackages) {
      if (!data.name.trim()) {
        alert(`Please provide a valid name for the ${name} package.`);
        return false;
      }
      if (data.description.length < 10) {
        alert(`Please provide a more detailed description for the ${name} package.`);
        return false;
      }
      if (!data.deliveryTime?.quantity || data.deliveryTime.quantity < 1) {
        alert(`Please set a valid delivery time for the ${name} package.`);
        return false;
      }
      if (!data.deliveryTime?.unit) {
        alert(`Please select a valid time unit for the ${name} package.`);
        return false;
      }
      if (!data.revisions || isNaN(data.revisions)) {
        alert(`Please set a valid number of revisions for the ${name} package.`);
        return false;
      }
      if (data.deliverables.some((item) => !item.trim())) {
        alert(`Please ensure all deliverables are valid for the ${name} package.`);
        return false;
      }
    }

    return true;
  };

  const handleSaveAndContinue = () => {
    if (!validatePackages()) return;

    navigate("/milestones", {
      state: {
        serviceTitle,
        category,
        subCategory,
        packages,
        description,
      },
    });
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <SetupHeader currentStep={2} />

      <main className="p-6 md:p-10 my-40 max-w-4xl mx-auto bg-white rounded-2xl shadow-md border border-gray-200">
        <h1 className="text-2xl font-bold text-gray-800 mb-6">
          Define Your Packages for {serviceTitle}
        </h1>

        <div className="mb-6">
          <p className="text-lg text-gray-600">
            <strong>Category:</strong> {category}
          </p>
          <p className="text-lg text-gray-600">
            <strong>Sub-Category:</strong> {subCategory}
          </p>
        </div>

        {Object.entries(packages).map(([packageName, packageData]) => (
          <div key={packageName} className="border-t border-gray-300 py-4">
            <div className="flex justify-between items-center">
              <button
                className="w-full flex justify-between items-center text-lg font-semibold text-gray-800"
                onClick={() => togglePackage(packageName)}
                aria-expanded={packageData.isActive}
              >
                {packageName.toUpperCase()}
                <span>{packageData.isActive ? "▼" : "►"}</span>
              </button>
            </div>
            {packageData.isActive && (
              <div className="mt-4 space-y-6">
                <div>
                  <label className="block text-lg font-semibold text-gray-800 mb-2">
                    Package Name
                  </label>
                  <input
                    type="text"
                    placeholder="Name your Package"
                    className="w-full border border-gray-300 rounded-lg px-4 py-3 focus:ring-2 focus:ring-blue-500 focus:outline-none"
                    value={packageData.name}
                    onChange={(e) => handleInputChange(packageName, "name", e.target.value)}
                  />
                </div>

                <div>
                  <label className="block text-lg font-semibold text-gray-800 mb-2">
                    Description
                  </label>
                  <textarea
                    placeholder="Describe your package"
                    rows={4}
                    className="w-full border border-gray-300 rounded-lg px-4 py-3 focus:ring-2 focus:ring-blue-500 focus:outline-none"
                    value={packageData.description}
                    onChange={(e) =>
                      handleInputChange(packageName, "description", e.target.value)
                    }
                  ></textarea>
                </div>

                <div className="grid grid-cols-2 gap-6">
                  <div>
                    <label className="block text-lg font-semibold text-gray-800 mb-2">
                      Delivery Time
                    </label>
                    <div className="flex gap-4">
                      <input
                        type="number"
                        min="1"
                        placeholder="Quantity"
                        className="w-1/2 border border-gray-300 rounded-lg px-4 py-3 focus:ring-2 focus:ring-blue-500 focus:outline-none"
                        value={packageData.deliveryTime.quantity}
                        onChange={(e) =>
                          handleDeliveryTimeChange(packageName, "quantity", e.target.value)
                        }
                      />
                      <select
                        className="w-1/2 border border-gray-300 rounded-lg px-4 py-3 focus:ring-2 focus:ring-blue-500 focus:outline-none"
                        value={packageData.deliveryTime.unit}
                        onChange={(e) =>
                          handleDeliveryTimeChange(packageName, "unit", e.target.value)
                        }
                      >
                        <option value="">Select Unit</option>
                        <option value="days">Days</option>
                        <option value="weeks">Weeks</option>
                        <option value="months">Months</option>
                      </select>
                    </div>
                  </div>
                  <div>
                    <label className="block text-lg font-semibold text-gray-800 mb-2">
                      Revisions
                    </label>
                    <input
                      type="number"
                      min="0"
                      placeholder="Number of revisions"
                      className="w-full border border-gray-300 rounded-lg px-4 py-3 focus:ring-2 focus:ring-blue-500 focus:outline-none"
                      value={packageData.revisions}
                      onChange={(e) =>
                        handleInputChange(packageName, "revisions", parseInt(e.target.value, 10))
                      }
                    />
                  </div>
                </div>

                <div>
                  <label className="block text-lg font-semibold text-gray-800 mb-2">
                    Deliverables
                  </label>
                  <div className="flex flex-wrap gap-3">
                    {packageData.deliverables.map((item, index) => (
                      <div
                        key={index}
                        className="flex items-center px-3 py-1 bg-gray-200 rounded-full"
                      >
                        <span className="text-sm">{item}</span>
                        <button
                          className="ml-2 text-gray-600 hover:text-gray-800"
                          onClick={() => handleRemoveDeliverable(packageName, index)}
                        >
                          ✕
                        </button>
                      </div>
                    ))}
                  </div>
                  <input
                    type="text"
                    placeholder="Add a deliverable"
                    className="w-full mt-2 border border-gray-300 rounded-lg px-4 py-3 focus:ring-2 focus:ring-blue-500 focus:outline-none"
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        handleAddDeliverable(packageName, e.target.value);
                        e.target.value = "";
                      }
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        ))}

        <div className="flex justify-between items-center mt-8">
          <button
            className="text-gray-500 hover:text-gray-700 text-sm font-medium"
            onClick={() =>
              navigate("/createService", {
                state: { serviceTitle, category, subCategory, description },
              })
            }
          >
            Back
          </button>
          <button
            onClick={handleSaveAndContinue}
            className="bg-blue-600 text-white px-6 py-3 rounded-lg font-medium hover:bg-blue-700 transition"
          >
            Save & Continue
          </button>
        </div>
      </main>

      <Footer />
    </div>
  );
}
