import React from "react";
import { motion } from "framer-motion";
import { useAuth } from "../../context/AuthContext"; // Import AuthContext
import { useNavigate } from "react-router-dom";
import profile from "../../asserts/user.png"

export default function ProfileCard() {
  const { authState } = useAuth(); // Access AuthContext
  const navigate = useNavigate();

  const handleProfileClick = () => {
    navigate("/profile");
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.6 }}
      className="flex flex-col items-center justify-center px-8 py-10 bg-blue-600 rounded-2xl w-full h-full"
      role="article"
      aria-label="Profile card"
    >
      {/* Profile Picture */}
      <div
        className="bg-gray-300 rounded-full h-20 w-20 mb-5"
        style={{
          backgroundImage: `url(${authState.user?.avatarUrl || profile})`,
          backgroundSize: "contain",
          backgroundPosition: "center",
        }}
        role="img"
        aria-label="Profile picture"
      />

      {/* User Name */}
      <div className="text-2xl font-semibold text-white mb-6">
        {authState.user?.name || "Guest"}
      </div>

      {/* Button */}
      <button
        className="px-6 py-2 text-sm font-medium text-blue-600 bg-white rounded-full hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition"
        aria-label="View Profile"
        onClick={handleProfileClick}
      >
        View Profile
      </button>
    </motion.div>
  );
}
