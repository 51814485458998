import React, { useState } from "react";
import { useAuth } from "../../context/AuthContext"; // Import AuthContext

function ProfessionalBackground() {
  const { authState, updateUserProfile } = useAuth(); // Access AuthContext
  const user = authState.user; // Extract user details

  const [editing, setEditing] = useState(false); // Track edit mode
  const [portfolioLinks, setPortfolioLinks] = useState(user?.portfolioLinks || [""]); // Portfolio links
  const [socialLinks, setSocialLinks] = useState(user?.socialLinks || [""]); // Social media links
  const [experience, setExperience] = useState(user?.experience || ""); // Experience
  const [skills, setSkills] = useState(user?.skills || ""); // Skills
  const [saving, setSaving] = useState(false); // Track saving state

  const handleSave = async (e) => {
    e.preventDefault();
    setSaving(true);

    try {
      await updateUserProfile({
        portfolioLinks,
        socialLinks,
        experience,
        skills,
      }); // Update professional background
      alert("Professional background updated successfully!");
      setEditing(false); // Exit edit mode
    } catch (error) {
      console.error("Failed to update professional background:", error);
      alert("Failed to update professional background. Please try again.");
    } finally {
      setSaving(false);
    }
  };

  const handleAddLink = (setLinks) => {
    setLinks((prev) => [...prev, ""]);
  };

  const handleUpdateLink = (index, value, setLinks) => {
    setLinks((prev) =>
      prev.map((link, i) => (i === index ? value : link))
    );
  };

  return (
    <div className="flex flex-col p-6 mt-12 w-full bg-white rounded-lg shadow-lg max-w-4xl mx-auto">
      {/* Header Section */}
      <div className="flex justify-between items-center border-b pb-4">
        <h2 className="text-2xl font-semibold text-gray-800">
          Professional Background
        </h2>
        <button
          onClick={() => setEditing(!editing)}
          className="text-blue-500 underline hover:text-blue-600 transition duration-200"
        >
          {editing ? "Cancel" : "Edit"}
        </button>
      </div>

      {editing ? (
        <form
          onSubmit={handleSave}
          className="flex flex-col mt-6 space-y-6 text-lg"
        >
          {/* External Portfolio Links */}
          <div>
            <h3 className="text-xl font-semibold text-gray-800">
              External Portfolio Links
            </h3>
            {portfolioLinks.map((link, index) => (
              <input
                key={index}
                type="text"
                value={link}
                onChange={(e) =>
                  handleUpdateLink(index, e.target.value, setPortfolioLinks)
                }
                placeholder="Add a portfolio link"
                className="block w-full mt-2 px-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            ))}
            {portfolioLinks.length < 5 && (
              <button
                type="button"
                onClick={() => handleAddLink(setPortfolioLinks)}
                className="mt-2 text-blue-500 hover:underline"
              >
                Add another link
              </button>
            )}
          </div>

          {/* Social Media Links */}
          <div>
            <h3 className="text-xl font-semibold text-gray-800">
              Social Media Links
            </h3>
            {socialLinks.map((link, index) => (
              <input
                key={index}
                type="text"
                value={link}
                onChange={(e) =>
                  handleUpdateLink(index, e.target.value, setSocialLinks)
                }
                placeholder="Add a social media link"
                className="block w-full mt-2 px-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            ))}
            {socialLinks.length < 5 && (
              <button
                type="button"
                onClick={() => handleAddLink(setSocialLinks)}
                className="mt-2 text-blue-500 hover:underline"
              >
                Add another link
              </button>
            )}
          </div>

          {/* Experience */}
          <div>
            <h3 className="text-xl font-semibold text-gray-800">Experience</h3>
            <input
              type="text"
              value={experience}
              onChange={(e) => setExperience(e.target.value)}
              placeholder="Add your experience"
              className="block w-full mt-2 px-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>

          {/* Skills */}
          <div>
            <h3 className="text-xl font-semibold text-gray-800">Skills</h3>
            <textarea
              value={skills}
              onChange={(e) => setSkills(e.target.value)}
              placeholder="Add your skills"
              className="block w-full mt-2 px-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>

          {/* Save Button */}
          <button
            type="submit"
            className="px-6 py-3 text-white bg-blue-500 rounded-lg hover:bg-blue-600 transition duration-200 self-start"
            disabled={saving}
          >
            {saving ? "Saving..." : "Save Changes"}
          </button>
        </form>
      ) : (
        <div className="mt-6 space-y-6">
          {/* External Portfolio Links */}
          <div>
            <h3 className="text-xl font-semibold text-gray-800">
              External Portfolio Links
            </h3>
            <ul className="mt-2 text-gray-700">
              {portfolioLinks.length > 0 ? (
                portfolioLinks.map((link, index) => (
                  <li key={index} className="mt-1">
                    <a
                      href={link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-500 hover:underline"
                    >
                      {link}
                    </a>
                  </li>
                ))
              ) : (
                <li className="text-gray-500">No portfolio links added</li>
              )}
            </ul>
          </div>

          {/* Social Media Links */}
          <div>
            <h3 className="text-xl font-semibold text-gray-800">
              Social Media Links
            </h3>
            <ul className="mt-2 text-gray-700">
              {socialLinks.length > 0 ? (
                socialLinks.map((link, index) => (
                  <li key={index} className="mt-1">
                    <a
                      href={link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-500 hover:underline"
                    >
                      {link}
                    </a>
                  </li>
                ))
              ) : (
                <li className="text-gray-500">No social media links added</li>
              )}
            </ul>
          </div>

          {/* Experience */}
          <div>
            <h3 className="text-xl font-semibold text-gray-800">Experience</h3>
            <p className="mt-2 text-gray-700">
              {experience || "No experience provided"}
            </p>
          </div>

          {/* Skills */}
          <div>
            <h3 className="text-xl font-semibold text-gray-800">Skills</h3>
            <p className="mt-2 text-gray-700">
              {skills || "No skills provided"}
            </p>
          </div>
        </div>
      )}
    </div>
  );
}

export default ProfessionalBackground;
