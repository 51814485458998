import React, { useState, useEffect } from "react";
import { FaCog, FaBell, FaEnvelope } from "react-icons/fa";
import { useAuth } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../config/firebase";

export default function SetupHeader({ currentStep }) {
  const { signOut } = useAuth();
  const navigate = useNavigate();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isNotificationsOpen, setIsNotificationsOpen] = useState(false);
  const [isMessagesOpen, setIsMessagesOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [messages, setMessages] = useState([]);
  const [participantsMap, setParticipantsMap] = useState({});

  const { authState } = useAuth();

  // Fetch notifications
  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const user = authState.user;
        if (!user) return;

        const notificationsRef = collection(db, "notifications");
        const snapshot = await getDocs(notificationsRef);

        const fetchedNotifications = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setNotifications(fetchedNotifications);
      } catch (error) {
        console.error("Error fetching notifications:", error);
      }
    };

    fetchNotifications();
  }, [authState.user]);

  // Fetch messages and participants
  useEffect(() => {
    const fetchMessages = async () => {
      try {
        const user = authState.user;
        if (!user) return;

        // Step 1: Fetch messages
        const messagesRef = collection(db, "messages");
        const messagesQuery = query(
          messagesRef,
          where("participants", "array-contains", user.uid)
        );
        const messagesSnapshot = await getDocs(messagesQuery);

        const messagesData = messagesSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setMessages(messagesData);

        // Step 2: Collect unique participant IDs
        const participantIds = new Set();
        messagesData.forEach((msg) =>
          msg.participants.forEach((id) => participantIds.add(id))
        );

        // Step 3: Fetch participant details in a batch
        const usersRef = collection(db, "users");
        const usersQuery = query(
          usersRef,
          where("uid", "in", Array.from(participantIds))
        );
        const usersSnapshot = await getDocs(usersQuery);

        const participants = {};
        usersSnapshot.docs.forEach((doc) => {
          const data = doc.data();
          participants[data.uid] = {
            name: data.name || "Unknown User",
            avatarUrl: data.avatarUrl || null,
          };
        });

        setParticipantsMap(participants);
      } catch (error) {
        console.error("Error fetching messages and participants:", error);
      }
    };

    fetchMessages();
  }, [authState.user]);

  const handleLogoClick = () => navigate("/dashboard");
  const toggleDropdown = () => setIsDropdownOpen((prev) => !prev);
  const toggleNotifications = () => setIsNotificationsOpen((prev) => !prev);
  const toggleMessages = () => setIsMessagesOpen((prev) => !prev);

  const markMessageAsRead = (id) => {
    navigate(`/messages/${id}`);
  };

  const handleProfileClick = () => {
    navigate("/profile");
    setIsDropdownOpen(false);
  };

  const handleLogoutClick = () => {
    signOut();
    navigate("/");
    setIsDropdownOpen(false);
  };

  const handleServicesClick = () => {
    navigate("/serviceListing");
    setIsDropdownOpen(false);
  };

  console.log("notifications", notifications);
  console.log("messages", messages);

  return (
    <header className="w-screen bg-white rounded-2xl shadow-sm p-4 md:p-6">
      <div className="flex flex-col md:flex-row items-center justify-between mb-4 gap-4">
        <div
          className="text-2xl md:text-3xl font-bold text-orange-600 cursor-pointer"
          onClick={handleLogoClick}
        >
          EARN
        </div>
        <nav className="hidden md:flex space-x-6">
          <button
            className="text-gray-700 hover:text-orange-600 focus:outline-none"
            onClick={() => navigate("/dashboard")}
          >
            Dashboard
          </button>
          <button
            className="text-gray-700 hover:text-orange-600 focus:outline-none"
            onClick={handleServicesClick}
          >
            Services
          </button>
          <button
            className="text-gray-700 hover:text-orange-600 focus:outline-none"
            onClick={() => navigate("/profile")}
          >
            Profile
          </button>
        </nav>

        <div className="relative flex items-center space-x-4">
          <button className="p-2 rounded-full border hover:bg-gray-100">
            <FaCog className="text-gray-600 text-lg" />
          </button>

          {/* Notifications */}
          <div className="relative">
            <button
              aria-label="Notifications"
              className="p-2 rounded-full border hover:bg-gray-100"
              onClick={toggleNotifications}
            >
              <FaBell className="text-gray-600 text-lg" />
              {notifications.some((notif) => !notif.isRead) && (
                <span className="absolute top-0 right-0 w-2.5 h-2.5 bg-red-500 rounded-full" />
              )}
            </button>
            {isNotificationsOpen && (
              <div className="absolute right-0 mt-2 w-64 bg-white border rounded-lg shadow-lg">
                <div className="p-4">
                  <h3 className="text-gray-900 font-semibold">Notifications</h3>
                  {notifications.length === 0 ? (
                    <p className="text-gray-500">No notifications</p>
                  ) : (
                    <ul>
                      {notifications.map((notif) => (
                        <li key={notif.id} className="p-2 hover:bg-gray-100">
                          {notif.message}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
            )}
          </div>

          {/* Messages */}
          <div className="relative">
            <button
              aria-label="Messages"
              className="p-2 rounded-full border hover:bg-gray-100"
              onClick={toggleMessages}
            >
              <FaEnvelope className="text-gray-600 text-lg" />
              {messages.some((msg) => !msg.recentMessage.isRead) && (
                <span className="absolute top-0 right-0 w-2.5 h-2.5 bg-red-500 rounded-full" />
              )}
            </button>
            {isMessagesOpen && (
              <div className="absolute right-0 mt-2 w-64 bg-white border rounded-lg shadow-lg">
                <div className="p-4">
                  <h3 className="text-gray-900 font-semibold">Messages</h3>
                  {messages.length === 0 ? (
                    <p className="text-gray-500">No messages</p>
                  ) : (
                    <ul>
                      {messages.map((msg) => {
                        // Find the participant who isn't the current user
                        const senderId = msg.participants.find(
                          (id) => id !== authState.user.uid
                        );

                        // Get sender details
                        const senderDetails = participantsMap[senderId] || {};

                        return (
                          <li
                            key={msg.id}
                            className="p-2 hover:bg-gray-100"
                            onClick={() => markMessageAsRead(senderId)}
                          >
                            <div className="flex items-center space-x-4">
                              {/* Avatar or Initial */}
                              {senderDetails.avatarUrl ? (
                                <img
                                  src={senderDetails.avatarUrl}
                                  alt="Avatar"
                                  className="w-8 h-8 rounded-full"
                                />
                              ) : (
                                <div className="w-8 h-8 rounded-full bg-gray-300 flex items-center justify-center">
                                  <span className="text-gray-700 font-semibold">
                                    {senderDetails.name
                                      ?.charAt(0)
                                      .toUpperCase() || "U"}
                                  </span>
                                </div>
                              )}
                              {/* Name and Message */}
                              <div>
                                <p className="font-semibold text-sm text-gray-800">
                                  {senderDetails.name}
                                </p>
                                <p className="text-sm text-gray-500 mt-1">
                                  {msg.recentMessage.message}
                                </p>
                              </div>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </header>
  );
}
