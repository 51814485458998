import React, { useState, useEffect } from "react";
import { useAuth } from "../../context/AuthContext"; // Import AuthContext
import { storage } from "../../config/firebase"; // Import Firebase Storage instance
import { ref, uploadBytes, getDownloadURL } from "firebase/storage"; // Firebase Storage methods

const GOOGLE_API_KEY = "AIzaSyCevsNtnxSA6ngvEQoYy78pUASuTQwIKR0"; // Load API key from .env

function ProfileCard() {
  const { authState, updateUserProfile } = useAuth(); // Access the user's state and update function from AuthContext
  const user = authState.user; // Extract user details from authState

  const [isEditingLocation, setIsEditingLocation] = useState(false); // Track if location is being edited
  const [locationInput, setLocationInput] = useState(user?.location || ""); // Track location input
  const [suggestions, setSuggestions] = useState([]); // Location suggestions
  const [uploading, setUploading] = useState(false); // Track upload state
  const [currentUploadPreview, setCurrentUploadPreview] = useState(null); // Track the current uploading image

  // Format the createdAt date
  const formatDate = (dateString) => {
    if (!dateString) return "Join date unavailable";
    const date = new Date(dateString);
    return new Intl.DateTimeFormat("en-US", {
      month: "long",
      day: "numeric",
      year: "numeric",
    }).format(date);
  };

  // Handle avatar upload
  const handleAvatarUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    setUploading(true);
    const previewUrl = URL.createObjectURL(file); // Create a local preview URL
    setCurrentUploadPreview(previewUrl);

    try {
      const avatarRef = ref(storage, `avatars/${user.uid}`);
      await uploadBytes(avatarRef, file);

      const downloadURL = await getDownloadURL(avatarRef);
      await updateUserProfile({ avatarUrl: downloadURL });
    } catch (error) {
      console.error("Failed to upload avatar:", error);
    } finally {
      setUploading(false);
      setCurrentUploadPreview(null); // Clear the preview
    }
  };

  // Fetch real-time location suggestions (Google Places API)
  const fetchLocationSuggestions = async (query) => {
    if (!query) {
      setSuggestions([]);
      return;
    }
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/place/autocomplete/json?input=${query}&key=${GOOGLE_API_KEY}&types=(cities)`
      );
      const data = await response.json();
      const places = data.predictions.map((place) => place.description);
      setSuggestions(places);
    } catch (error) {
      console.error("Failed to fetch location suggestions:", error);
    }
  };

  // Use Current Location
  const detectCurrentLocation = async () => {
    if (!navigator.geolocation) {
      alert("Geolocation is not supported by your browser.");
      return;
    }

    navigator.geolocation.getCurrentPosition(
      async (position) => {
        const { latitude, longitude } = position.coords;

        try {
          const response = await fetch(
            `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${GOOGLE_API_KEY}`
          );

          const data = await response.json();
          
          console.log(data, GOOGLE_API_KEY);
          const currentLocation =
            data.results[0]?.formatted_address || "Location unavailable";
          setLocationInput(currentLocation);
        } catch (error) {
          console.error("Failed to fetch current location:", error);
        }
      },
      (error) => {
        console.error("Geolocation error:", error);
      }
    );
  };

  const handleLocationSave = async () => {
    try {
      await updateUserProfile({ location: locationInput });
      setIsEditingLocation(false); // Exit editing mode
      setSuggestions([]); // Clear suggestions
    } catch (error) {
      console.error("Failed to update location:", error);
    }
  };

  useEffect(() => {
    if (isEditingLocation && locationInput) {
      fetchLocationSuggestions(locationInput);
    }
  }, [locationInput, isEditingLocation]);

  return (
    <div className="flex flex-col w-3/12 max-md:ml-0 max-md:w-full">
      <div className="flex flex-col px-5 py-11 mx-auto w-full text-sm font-medium text-black bg-white rounded-3xl border border-solid border-black border-opacity-20 max-md:mt-10">
        {/* User Avatar */}
        <div className="relative w-[100px] h-[100px] self-center">
          <div className="relative">
            {uploading ? (
              <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 rounded-full">
                <span className="text-white text-sm">Uploading...</span>
              </div>
            ) : null}
            <img
              src={currentUploadPreview || user?.avatarUrl || ""}
              alt={`${user?.name || "User"}'s avatar`}
              className="rounded-full w-[100px] h-[100px] object-cover"
            />
          </div>
          <label
            htmlFor="avatar-upload"
            className="absolute bottom-1 right-1 bg-blue-500 text-white p-1 rounded-full cursor-pointer"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M15.232 5.232l3.536 3.536m-2.036-1.5a2.5 2.5 0 11-3.536-3.536l-9.1 9.1A2 2 0 003 16.5V19h2.5a2 2 0 001.414-.586l9.1-9.1z"
              />
            </svg>
            <input
              id="avatar-upload"
              type="file"
              accept="image/*"
              onChange={handleAvatarUpload}
              className="hidden"
            />
          </label>
        </div>

        {/* User Name */}
        <div className="self-center mt-5 text-2xl font-bold">
          {user ? user.name : "Guest"}
        </div>

        {/* Location Section */}
        <div className="flex gap-5 justify-between px-4 py-3.5 mt-9 w-full rounded-xl bg-zinc-100 max-md:pr-5">
          <div className="flex gap-2 items-center w-full">
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/bad263db8b4a09c86d92945d2e9af3abbb56efa1b8f27947c10554082220adc6?placeholderIfAbsent=true&apiKey=44862712bdb34f49aaf0b7f367088f08"
              alt="Location icon"
              className="object-contain shrink-0 aspect-square w-[19px]"
            />
            {isEditingLocation ? (
              <div className="relative w-full">
                <input
                  type="text"
                  value={locationInput}
                  onChange={(e) => setLocationInput(e.target.value)}
                  className="w-full px-2 py-1 border border-gray-300 rounded"
                  placeholder="Search location"
                />
                <ul className="absolute left-0 right-0 max-h-32 overflow-y-auto bg-white border border-gray-300 rounded mt-1 z-10">
                  {suggestions.map((loc, index) => (
                    <li
                      key={index}
                      onClick={() => setLocationInput(loc)}
                      className="px-2 py-1 cursor-pointer hover:bg-gray-200"
                    >
                      {loc}
                    </li>
                  ))}
                </ul>
                <button
                  className="mt-2 text-blue-500 underline"
                  onClick={detectCurrentLocation}
                >
                  Use current location
                </button>
              </div>
            ) : (
              <div className="basis-auto">{user ? user.location : "Location unavailable"}</div>
            )}
          </div>
          <button
            onClick={() => (isEditingLocation ? handleLocationSave() : setIsEditingLocation(true))}
            className="text-blue-500"
          >
            {isEditingLocation ? "Save" : "Edit"}
          </button>
        </div>

        {/* Join Date */}
        <div className="flex gap-5 px-5 py-3.5 mt-3 w-full rounded-xl bg-zinc-100 max-md:pr-5">
          <div className="flex gap-2.5">
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/05c4064b6c54b742bfccd690e30215a9456c7acd384dd3fdb7362e188812f4ea?placeholderIfAbsent=true&apiKey=44862712bdb34f49aaf0b7f367088f08"
              alt="Calendar icon"
              className="object-contain shrink-0 self-start aspect-square w-[17px]"
            />
            <div className="basis-auto">
              {user ? `Joined ${formatDate(user.createdAt)}` : "Join date unavailable"}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProfileCard;
