import { useState } from "react";
import { Settings, Bell, LogOut } from "lucide-react";

const categories = {
  "Graphic Design": [
    "Logo design",
    "Brand identity creation",
    "Illustration",
    "Book covers and album art",
    "Packaging design",
    "T-shirt design",
    "Social media design",
  ],
  "Digital Marketing": [
    "Social media management",
    "SEO (Search Engine Optimization)",
    "Content marketing",
    "Email marketing",
    "Influencer marketing",
    "Paid advertising (Google Ads, Facebook Ads)",
  ],
  "Writing & Translation": [
    "Copywriting",
    "Blog writing",
    "Technical writing",
    "Resume and cover letter creation",
    "Creative writing",
    "Translation services",
    "Proofreading and editing",
  ],
  "Video & Animation": [
    "Video editing",
    "Explainer videos",
    "Whiteboard animations",
    "3D product animation",
    "Subtitles and captions",
    "Visual effects",
  ],
  "Music & Audio": [
    "Voiceover services",
    "Mixing and mastering",
    "Podcast editing",
    "Custom jingles and music composition",
    "Audiobook narration",
  ],
  "Programming & Tech": [
    "Website development",
    "App development",
    "WordPress customization",
    "E-commerce development",
    "IT support",
    "Cybersecurity services",
  ],
  "Business Services": [
    "Virtual assistance",
    "Business consulting",
    "Project management",
    "Data entry",
    "Market research",
    "Financial consulting",
    "Legal consulting",
  ],
  "Lifestyle": [
    "Relationship advice",
    "Life coaching",
    "Fitness coaching",
    "Online tutoring",
  ],
  "AI Services": [
    "Chatbot development",
    "AI-powered tools and solutions",
    "Data labeling and processing",
  ],
  "Photography": [
    "Photo editing and retouching",
    "Product photography",
    "Lifestyle photography",
    "Real estate photography (remote editing)",
  ],
};

function MarketplaceHeader({ selectedCategory, setSelectedCategory, handleSignOut, user }) {
  const [selectedSubcategory, setSelectedSubcategory] = useState(null);

  return (
    <header className="bg-white border-b border-gray-200">
      <div className="max-w-[2000px] mx-auto">
        <div className="flex items-center justify-between px-6 py-4">
          <div className="flex items-center space-x-8">
            <a href="/" className="text-red-500 font-bold text-2xl">
              EARN
            </a>
          </div>

          <div className="flex items-center space-x-4">
            <button className="p-2 hover:bg-gray-100 rounded-full">
              <Settings className="h-5 w-5 text-gray-600" />
            </button>
            <button className="p-2 hover:bg-gray-100 rounded-full">
              <Bell className="h-5 w-5 text-gray-600" />
            </button>
            <button onClick={handleSignOut} className="p-2 hover:bg-gray-100 rounded-full">
              <LogOut className="h-5 w-5 text-gray-600" />
            </button>
            <div className="h-8 w-8 rounded-full bg-gray-200 flex items-center justify-center">
              <span className="text-sm font-medium">{user?.name?.[0]?.toUpperCase() || ""}</span>
            </div>
          </div>
        </div>

        {/* Categories Navigation */}
        <nav className="px-6 py-2 overflow-x-auto">
          <div className="flex space-x-6">
            {Object.keys(categories).map((category) => (
              <button
                key={category}
                onClick={() => {
                  setSelectedCategory(category);
                  setSelectedSubcategory(null); // Reset subcategory
                }}
                className={`whitespace-nowrap px-3 py-2 text-sm font-medium rounded-md transition-colors ${
                  selectedCategory === category
                    ? "bg-gray-100 text-gray-900"
                    : "text-gray-600 hover:text-gray-900 hover:bg-gray-50"
                }`}
              >
                {category}
              </button>
            ))}
          </div>
        </nav>

        {/* Subcategories Navigation */}
        {selectedCategory && categories[selectedCategory] && (
          <nav className="px-6 py-2 overflow-x-auto bg-gray-50">
            <div className="flex space-x-6">
              {categories[selectedCategory].map((subcategory) => (
                <button
                  key={subcategory}
                  onClick={() => setSelectedSubcategory(subcategory)}
                  className={`whitespace-nowrap px-3 py-2 text-sm font-medium rounded-md transition-colors ${
                    selectedSubcategory === subcategory
                      ? "bg-gray-200 text-gray-900"
                      : "text-gray-600 hover:text-gray-900 hover:bg-gray-100"
                  }`}
                >
                  {subcategory}
                </button>
              ))}
            </div>
          </nav>
        )}
      </div>
    </header>
  );
}

export default MarketplaceHeader;
