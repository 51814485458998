import React, { useEffect, useState } from 'react';
import {
  collection,
  query,
  where,
  onSnapshot,
  orderBy,
  getDoc,
  doc,
  updateDoc,
  deleteDoc,
} from 'firebase/firestore';
import { db } from '../../config/firebase';
import { useNavigate } from 'react-router-dom';
import { AiOutlineArrowLeft } from 'react-icons/ai';
import ChatItem from './ChatItem'; // Import the reusable ChatItem component

export default function ChatSidebar({ currentUserId, onSelectChat }) {
  const [chats, setChats] = useState([]);
  const [archivedChats, setArchivedChats] = useState([]);
  const [activeTab, setActiveTab] = useState('active'); // 'active' or 'archived'
  const [usernames, setUsernames] = useState({});
  const [searchQuery, setSearchQuery] = useState(''); // State for search input
  const navigate = useNavigate();

  useEffect(() => {
    const chatsRef = collection(db, 'messages');

    const activeChatsQuery = query(
      chatsRef,
      where('participants', 'array-contains', currentUserId),
      where('isArchived', '==', false),
      orderBy('recentMessage.timestamp', 'desc')
    );

    const unsubscribeActive = onSnapshot(activeChatsQuery, async (snapshot) => {
      const fetchedChats = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setChats(fetchedChats);

      const participantIds = Array.from(
        new Set(fetchedChats.flatMap((chat) => chat.participants.filter((p) => p !== currentUserId)))
      );
      await fetchUsernames(participantIds);
    });

    const archivedChatsQuery = query(
      chatsRef,
      where('participants', 'array-contains', currentUserId),
      where('isArchived', '==', true),
      orderBy('recentMessage.timestamp', 'desc')
    );

    const unsubscribeArchived = onSnapshot(archivedChatsQuery, async (snapshot) => {
      const fetchedArchivedChats = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setArchivedChats(fetchedArchivedChats);

      const participantIds = Array.from(
        new Set(fetchedArchivedChats.flatMap((chat) => chat.participants.filter((p) => p !== currentUserId)))
      );
      await fetchUsernames(participantIds);
    });

    return () => {
      unsubscribeActive();
      unsubscribeArchived();
    };
  }, [currentUserId]);

  const fetchUsernames = async (uids) => {
    const newNames = {};
    const promises = uids.map(async (uid) => {
      if (!usernames[uid]) {
        try {
          const userDoc = doc(db, 'users', uid);
          const userSnapshot = await getDoc(userDoc);
          newNames[uid] = userSnapshot.exists() ? userSnapshot.data().name : 'Unknown User';
        } catch (error) {
          console.error(`Failed to fetch username for UID ${uid}:`, error);
          newNames[uid] = 'Unknown User';
        }
      }
    });

    await Promise.all(promises);
    setUsernames((prev) => ({ ...prev, ...newNames }));
  };

  const handleChatSelect = async (chatId) => {
    try {
      const chatRef = doc(db, 'messages', chatId);
      await updateDoc(chatRef, {
        'recentMessage.isRead': true,
      });
    } catch (error) {
      console.error('Failed to mark chat as read:', error);
    }

    onSelectChat(chatId);
  };

  const handleArchive = async (chatId) => {
    const chatRef = doc(db, 'messages', chatId);
    await updateDoc(chatRef, { isArchived: true });
  };

  const handleDelete = async (chatId) => {
    const chatRef = doc(db, 'messages', chatId);
    await deleteDoc(chatRef);
  };

  const displayedChats = activeTab === 'active' ? chats : archivedChats;

  // Filter chats based on the search query
  const filteredChats = displayedChats.filter(
    (chat) =>
      chat.participants
        .filter((p) => p !== currentUserId)
        .some((uid) => (usernames[uid] || '').toLowerCase().includes(searchQuery.toLowerCase())) ||
      (chat.recentMessage?.message || '').toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="w-80 border-r bg-gray-50">
      {/* Header */}
      <div className="flex flex-col p-4 border-b bg-white">
        <div className="flex items-center gap-4 mb-2">
          <button
            onClick={() => navigate(-1)}
            className="flex items-center text-gray-500 hover:text-blue-500 transition-colors"
          >
            <AiOutlineArrowLeft className="text-xl mr-2" />
          </button>
          <h2 className="text-xl font-semibold">Messages</h2>
        </div>
        <div className="flex gap-4 mb-2">
          <button
            onClick={() => setActiveTab('active')}
            className={`font-medium ${
              activeTab === 'active' ? 'text-blue-500' : 'text-gray-500'
            }`}
          >
            Active
          </button>
          <button
            onClick={() => setActiveTab('archived')}
            className={`font-medium ${
              activeTab === 'archived' ? 'text-blue-500' : 'text-gray-500'
            }`}
          >
            Archived
          </button>
        </div>
        <input
          type="text"
          placeholder="Search chats..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="w-full px-3 py-2 border rounded-lg text-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
      </div>

      {/* Chat List */}
      <div className="overflow-y-auto h-[calc(100vh-120px)]">
        {filteredChats.map((chat) => (
          <ChatItem
            key={chat.id}
            chat={chat}
            currentUserId={currentUserId}
            usernames={usernames}
            onSelect={handleChatSelect}
            onArchive={handleArchive}
            onDelete={handleDelete}
          />
        ))}
      </div>
    </div>
  );
}
