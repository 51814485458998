import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LandingPage from "./pages/Dashboard";
import LoginFlow from "./components/Login/LoginFlow";
import Landing from "./pages/Landing";
import Dashboard from "./pages/Dashboard";
import Profile from "./pages/Profile";
import Home from "./pages/Home"; 
import CreateServicePage from "./components/Home/CreateAServicePage";
import PricingPage from "./components/Home/PricingPage";
import UploadMediaPage from "./components/Home/UploadImagePage";
import PublishServicePage from "./components/Home/PublishServicePage";
import ServiceSuccessPage from "./components/Home/ServiceSuccessPage";
import ServiceListingPage from "./components/Home/ServiceListingPage";
import MessageScreen from "./components/Messages/MessageScreen";
import ServiceDetailPage from "./components/Home/ServiceDetailPage";
import MilestoneWorkflow from "./components/Home/MilestoneCard";
import FAQPage from "./components/Home/FAQ";
import LandingPageV2 from "./components/v2/LandingPage/LandingPage";
import AboutPage from "./components/AboutPage/AboutPage";
import { useAuth } from "./context/AuthContext";
import { Navigate } from "react-router-dom";
import CookieNotice from "./components/Util/CookieNotice";
import MyServicesListingPage from "./components/Home/MyServicesPage";
import "./components/v2/LandingPage/Background/global.css";

function App() {
  const { authState } = useAuth(); // Access authentication state from context

  const PrivateRoute = ({ children }) => {
    if (authState.isAuthenticated) {
      return children;
    }
    return <Navigate to="/login" />;
  };

  const PublicRoute = ({ children }) => {
    if (authState.isAuthenticated) {
      return <Navigate to="/dashboard" />;
    }
    return children;
  };

  return (
    <Router>
      <CookieNotice />
      <Routes>
        {/* Public Routes */}
        <Route
          path="/"
          element={
            <PublicRoute>
              <LandingPageV2 />
            </PublicRoute>
          }
        />
        <Route path="/about-us" element={<AboutPage />} />
        <Route path="/login/*" element={<LoginFlow />} />

        {/* User Dashboard Routes */}
        <Route
          path="/dashboard/*"
          element={
            <PrivateRoute>
              <Home />
            </PrivateRoute>
          }
        />



        {/* Service Creation and Management */}
        <Route
          path="/createService"
          element={
            <PrivateRoute>
              <CreateServicePage />
            </PrivateRoute>
          }
        />
        <Route
          path="/pricing"
          element={
            <PrivateRoute>
              <PricingPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/uploadMedia"
          element={
            <PrivateRoute>
              <UploadMediaPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/publishService"
          element={
            <PrivateRoute>
              <PublishServicePage />
            </PrivateRoute>
          }
        />
        <Route
          path="/serviceSuccess"
          element={
            <PrivateRoute>
              <ServiceSuccessPage />
            </PrivateRoute>
          }
        />

        {/* Services */}
        <Route path="/serviceListing" element={<ServiceListingPage />} />
        <Route path="/service/:serviceId" element={<ServiceDetailPage />} />
        <Route path="/profile/services" element={
          <PrivateRoute>
            <MyServicesListingPage />
          </PrivateRoute>
        } />

        {/* Messaging */}
        <Route
          path="/messages"
          element={
            <PrivateRoute>
              <MessageScreen />
            </PrivateRoute>
          }
        />
        <Route
          path="/messages/:uid"
          element={
            <PrivateRoute>
              <MessageScreen />
            </PrivateRoute>
          }
        />

        {/* Profile and Other Pages */}
        <Route
          path="/profile"
          element={
            <PrivateRoute>
              <Profile />
            </PrivateRoute>
          }
        />
        <Route path="/milestones" element={<MilestoneWorkflow />} />
        <Route path="/faq" element={<FAQPage />} />
      </Routes>
    </Router>
  );
}

export default App;
