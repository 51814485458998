import * as React from "react";
import ProfileSection from "../Profile/ProfileSection";
import FreelancerRating from "../Profile/FreelancerRating";
import ContactInfo from "../Profile/ContactInfo";
import ProfessionalBackground from "../Profile/ProfessionalBackground";

function AccountSettings() {
  return (
    <div className="flex flex-col ml-5 w-9/12 max-md:ml-0 max-md:w-full">
      <div className="flex flex-col grow px-10 py-8 mt-3 w-full bg-white rounded-3xl border border-solid border-black border-opacity-20 max-md:px-5 max-md:mt-8">
        <h1 className="self-start text-2xl font-bold text-black mb-6">
          Account Settings
        </h1>
        <ProfileSection />
        <FreelancerRating />
        <ContactInfo />
        <ProfessionalBackground />
      </div>
    </div>
  );
}

export default AccountSettings;
