import * as React from "react";

function ReviewSection() {
  return (
    <div className="flex flex-col items-center px-14 pt-10 pb-44 mt-14 font-bold bg-white rounded-3xl border border-solid border-black border-opacity-20 max-md:px-5 max-md:pb-24 max-md:mt-10 max-md:max-w-full">
      <div className="self-start text-2xl text-black">Reviews from collaborators</div>
      <img
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/b78e45e7c4f668c15d728b8cf1411dc04b5d433449b4c1478a56261b5655121a?placeholderIfAbsent=true&apiKey=44862712bdb34f49aaf0b7f367088f08"
        alt="No reviews illustration"
        className="object-contain mt-24 aspect-square w-[87px] max-md:mt-10"
      />
      <div className="mt-3.5 mb-0 text-xl text-black text-opacity-40 max-md:mb-2.5">
        No reviews yet
      </div>
    </div>
  );
}

export default ReviewSection;