import React, { useState } from "react";
import { doc, updateDoc } from "firebase/firestore";
import { useAuth } from "../../context/AuthContext";
import { db } from "../../config/firebase";

export default function AvailabilityCard() {
  const { authState } = useAuth();
  const [loading, setLoading] = useState(false);
  const [isAvailable, setIsAvailable] = useState(authState.user?.isAvailable || false);

  const toggleAvailability = async () => {
    setLoading(true);
    try {
      const uid = authState.user?.uid;
      if (!uid) throw new Error("User UID not found");

      const userRef = doc(db, "users", uid); // Reference to Firestore document
      await updateDoc(userRef, { isAvailable: !isAvailable });
      setIsAvailable(!isAvailable);
    } catch (error) {
      console.error("Error updating availability:", error);
    } finally {
      setLoading(false);
    }
  };

  if (!authState.isAuthenticated) {
    return <p>Please log in to manage your availability.</p>;
  }

  return (
    <div className="p-6 bg-white border border-gray-300 rounded-2xl shadow-sm">
      <h2 className="text-2xl font-bold">Availability</h2>
      <p className="mt-4">
        {isAvailable
          ? "You are currently available to take orders."
          : "You are currently unavailable. Your gigs are hidden."}
      </p>
      <button
        onClick={toggleAvailability}
        disabled={loading}
        className="mt-6 px-6 py-3 text-lg font-medium bg-blue-500 text-white rounded-xl shadow-sm hover:bg-blue-600 transition"
      >
        {loading ? "Updating..." : isAvailable ? "Set as Unavailable" : "Set as Available"}
      </button>
    </div>
  );
}
