import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import image1 from "../../asserts/loginimage1.png";
import image2 from "../../asserts/loginimage2.png";
import image3 from "../../asserts/loginimage3.png";
import image4 from "../../asserts/loginimage4.png";

const banners = [
  {
    title: "Beat the competition, Get noticed first",
    description: "Outrank your local competitors by maximizing performance metrics.",
    image: image1,
  },
  {
    title: "Connect with quality clients",
    description: "Showcase your skills and grow your professional network effectively.",
    image: image2,
  },
  {
    title: "Boost your earnings",
    description: "Access tools and resources to maximize your earning potential on Earn.",
    image: image3,
  },
  {
    title: "Build your reputation",
    description: "Create a strong presence to stand out from the competition.",
    image: image4,
  },
];

export default function PromotionBanner() {
  const [currentIndex, setCurrentIndex] = useState(0);

  // Auto change the banner every 5 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % banners.length);
    }, 5000);

    return () => clearInterval(interval); // Cleanup interval on unmount
  }, []);

  const currentBanner = banners[currentIndex];

  return (
    <section className="w-full h-[400px] md:h-[500px] bg-blue-900 text-white rounded-2xl overflow-hidden flex flex-col md:flex-row relative">
      {/* Left Content */}
      <div className="p-6 md:p-10 flex flex-col justify-center md:w-2/3 h-full">
        <AnimatePresence mode="wait">
          <motion.div
            key={currentIndex}
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: 20 }}
            transition={{ duration: 0.5 }}
          >
            <h2 className="text-2xl md:text-4xl font-bold mb-4 leading-tight">
              {currentBanner.title}
            </h2>
            <p className="text-sm md:text-base text-gray-200 mb-6">
              {currentBanner.description}
            </p>
          </motion.div>
        </AnimatePresence>

        {/* Carousel Dots */}
        <div className="flex space-x-2">
          {banners.map((_, index) => (
            <div
              key={index}
              className={`h-3 w-3 rounded-full ${
                index === currentIndex ? "bg-white" : "bg-gray-400"
              }`}
            />
          ))}
        </div>
      </div>

      {/* Right Image */}
      <div className="md:w-1/3 h-full relative">
        <AnimatePresence mode="wait">
          <motion.img
            key={currentBanner.image}
            src={currentBanner.image}
            alt="Promotion Visual"
            className="w-full h-full object-cover absolute top-0 left-0"
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.9 }}
            transition={{ duration: 0.5 }}
          />
        </AnimatePresence>
      </div>
    </section>
  );
}
