import * as React from "react";

function FreelanceSolution() {
  return (
    <section className="flex justify-center py-16 px-4 bg-transparent">
      <div className="flex flex-col items-center bg-black text-white rounded-[45px] w-full max-w-[1200px] px-6 md:px-10 py-10 md:py-14">
        {/* Heading */}
        <h1 className="text-3xl md:text-4xl lg:text-5xl font-bold text-center leading-tight">
          The <span className="italic text-indigo-400">Ultimate</span> Freelance <br />
          Solution for Businesses
        </h1>

        {/* Image Grid */}
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4 mt-10 md:mt-16 w-full justify-items-center">
          {[
            {
              src: "https://cdn.builder.io/api/v1/image/assets/TEMP/087bde4ad23748823692ec2d0d06b95bfe1afdfe0d2f6c670c25b448102e38e6?placeholderIfAbsent=true&apiKey=44862712bdb34f49aaf0b7f367088f08",
              bgColor: "bg-yellow-300",
              alt: "Freelancer 1",
            },
            {
              src: "https://cdn.builder.io/api/v1/image/assets/TEMP/b2cadf0f6876a300cb04dd0a1bfdeae725d83e3999553da84629d8c8eff76c16?placeholderIfAbsent=true&apiKey=44862712bdb34f49aaf0b7f367088f08",
              bgColor: "bg-orange-400",
              alt: "Freelancer 2",
            },
            {
              src: "https://cdn.builder.io/api/v1/image/assets/TEMP/bc0213897a54768afc6a43a834e987f2933706fbc035522454c0f549be70221e?placeholderIfAbsent=true&apiKey=44862712bdb34f49aaf0b7f367088f08",
              bgColor: "bg-teal-300",
              alt: "Freelancer 3",
            },
            {
              src: "https://cdn.builder.io/api/v1/image/assets/TEMP/0bfec24618db9c52aea6731c1c8eb62e457ce16bc334f6de7e757e459044d3ed?placeholderIfAbsent=true&apiKey=44862712bdb34f49aaf0b7f367088f08",
              bgColor: "bg-red-300",
              alt: "Freelancer 4",
            },
            {
              src: "https://cdn.builder.io/api/v1/image/assets/TEMP/8dc854ac86a00480fb9dd395a6af8f0e681211aa5935dd431999967f7afb47f5?placeholderIfAbsent=true&apiKey=44862712bdb34f49aaf0b7f367088f08",
              bgColor: "bg-blue-400",
              alt: "Freelancer 5",
            },
            {
              src: "https://cdn.builder.io/api/v1/image/assets/TEMP/bb0687070a244c7d145a1092e3b77268184aa2815b184576b1f867f1ed0a0c81?placeholderIfAbsent=true&apiKey=44862712bdb34f49aaf0b7f367088f08",
              bgColor: "bg-purple-400",
              alt: "Freelancer 6",
            },
            {
              src: "https://cdn.builder.io/api/v1/image/assets/TEMP/d0a1706b1c0e992ada0ca61c9f53a06b091329f7ec94eff1214dd24d13bed2a8?placeholderIfAbsent=true&apiKey=44862712bdb34f49aaf0b7f367088f08",
              bgColor: "bg-yellow-200",
              alt: "Freelancer 7",
            },
            {
              src: "https://cdn.builder.io/api/v1/image/assets/TEMP/2757ed1c23ffa494ac7d248dd53199d2e79803dbb950f374da495d3f54a386df?placeholderIfAbsent=true&apiKey=44862712bdb34f49aaf0b7f367088f08",
              bgColor: "bg-green-300",
              alt: "Freelancer 8",
            },
            {
              src: "https://cdn.builder.io/api/v1/image/assets/TEMP/9ea165d444cf2df5a27f8fc7cbe9953821b8ac54185f20a27d4911e9acaa63c5?placeholderIfAbsent=true&apiKey=44862712bdb34f49aaf0b7f367088f08",
              bgColor: "bg-pink-300",
              alt: "Freelancer 9",
            },
            {
              src: "https://cdn.builder.io/api/v1/image/assets/TEMP/a9ca48e2c6d2fbf4382ca1b66e0d631ea73cb4877213b7c4dc6a34933c9f4e1b?placeholderIfAbsent=true&apiKey=44862712bdb34f49aaf0b7f367088f08",
              bgColor: "bg-blue-300",
              alt: "Freelancer 10",
            },
          ].map((image, index) => (
            <div
              key={index}
              className={`${image.bgColor} rounded-full overflow-hidden w-20 h-20 sm:w-24 sm:h-24 md:w-28 md:h-28 flex items-center justify-center`}
            >
              <img
                src={image.src}
                alt={image.alt}
                className="w-full h-full object-cover"
              />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default FreelanceSolution;
