import * as React from "react";

function TrendingProjectsCard({
  title,
  pricing,
  deliveredBy,
  image1Alt,
  image2Alt,
  pricingImgSrc = "https://cdn.builder.io/api/v1/image/assets/TEMP/2bc7c3469d08a4cb08c2d5395621313f9f45c6fc1684234bdfcf20abedff66a4?placeholderIfAbsent=true&apiKey=44862712bdb34f49aaf0b7f367088f08",
  deliveredByImgSrc = "https://cdn.builder.io/api/v1/image/assets/TEMP/ad7bab914d030c97d426577f0397eaa989c7f18915d3724c1a2a1b502536af87?placeholderIfAbsent=true&apiKey=44862712bdb34f49aaf0b7f367088f08",
}) {
  return (
    <div className="flex flex-col items-start w-full max-w-[360px] p-6 rounded-2xl bg-white shadow-lg backdrop-blur-6 bg-opacity-80">
      <h3 className="text-xl font-semibold text-gray-800">{title}</h3>
      <div className="flex items-center gap-2 mt-4">
        <img src={pricingImgSrc} alt={image1Alt} className="w-5 h-5" />
        <span className="text-gray-600">{pricing}</span>
      </div>
      <div className="flex items-center gap-2 mt-2">
        <img src={deliveredByImgSrc} alt={image2Alt} className="w-6 h-6" />
        <span className="text-gray-600">{deliveredBy}</span>
      </div>
    </div>
  );
}

function TrendingProjects() {
  const projects = [
    {
      title: "Generative AI applications",
      pricing: "Custom Pricing",
      deliveredBy: "Delivered by Earn",
      image1Alt: "Generative AI custom pricing",
      image2Alt: "Generative AI delivered by Earn",
    },
    {
      title: "Flutter Cross Platform app",
      pricing: "$30 - $40/hr",
      deliveredBy: "Delivered by Earn",
      image1Alt: "Flutter app pricing",
      image2Alt: "Flutter app delivered by Earn",
    },
    {
      title: "Corporate branding",
      pricing: "$100-$200/project",
      deliveredBy: "Delivered by Earn",
      image1Alt: "Corporate branding pricing",
      image2Alt: "Corporate branding delivered by Earn",
    },
  ];

  return (
    <section className="py-16 px-4 bg-transparent">
      <div
        className="max-w-[1200px] mx-auto text-center p-10 rounded-2xl relative bg-white/40 backdrop-blur-lg"
        style={{
          boxShadow: "0 8px 20px rgba(0, 0, 0, 0.15)", // Shadow under the blur effect
        }}
      >
        <h2 className="text-4xl font-bold text-gray-800">Trending Earn projects</h2>
        <p className="text-gray-600 mt-2">
          Discover top projects and services delivered by Earn professionals.
        </p>
        <div className="grid grid-cols-3 gap-8 mt-10 max-md:grid-cols-1">
          {projects.map((project, index) => (
            <TrendingProjectsCard
              key={index}
              title={project.title}
              pricing={project.pricing}
              deliveredBy={project.deliveredBy}
              image1Alt={project.image1Alt}
              image2Alt={project.image2Alt}
            />
          ))}
        </div>
      </div>
    </section>
  );
}

export default TrendingProjects;
