import * as React from "react";

export function WhyChoose() {
  return (
    <section className="flex flex-col items-center text-center py-16 px-4 bg-transparent">
      {/* Heading */}
      <h1 className="text-3xl sm:text-4xl md:text-5xl font-bold text-black">
        Why Choose Earn?
      </h1>

      {/* Description */}
      <p className="mt-6 text-base sm:text-lg md:text-xl font-medium leading-7 sm:leading-8 text-gray-600 max-w-[90%] sm:max-w-[600px] md:max-w-[800px]">
        In a world where collaboration and opportunity transcend borders, we're
        here to bring people together like never before. Our platform empowers
        creators, businesses, and visionaries to connect, innovate, and grow,
        making work without boundaries a reality. With thousands of skilled
        professionals and projects spanning the globe, we're not just building
        a freelance marketplace—we're shaping the future of work. Every month,
        countless partnerships are forged here, and we're committed to driving
        meaningful connections for decades to come. Join us and be part of
        something extraordinary.
      </p>

      {/* Group Image */}
      <img
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/97180d600039e307de3562150797590fc4cbcd777a8de38aa1b81ff079ad07a8?placeholderIfAbsent=true&apiKey=44862712bdb34f49aaf0b7f367088f08"
        alt="Group of four people representing collaboration"
        className="mt-10 w-full max-w-[300px] sm:max-w-[500px] md:max-w-[700px] rounded-xl shadow-lg"
      />
    </section>
  );
}

export default WhyChoose;
