import * as React from "react";
import gradImage from "../../asserts/grad.svg"; // Correct path to the gradient image

function BusinessHero() {
  const handleCTA = () => {
    window.location.href = "/download"; // Navigate to the download page
  };

  return (
    <section
      className="flex items-center justify-center h-screen w-full bg-cover bg-center p-8 md:p-16"
      
    >
      <div className="flex flex-col items-center text-center px-10 md:px-16 py-12 w-full  bg-white/90 rounded-lg shadow-lg"
        style={{
          backgroundImage: `url(${gradImage})`, 
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        {/* Title */}
        <h1 className="text-4xl md:text-5xl font-bold text-black">
          Business in your pocket
        </h1>

        {/* Call to Action */}
        <button
          onClick={handleCTA}
          className="px-6 py-3 mt-6 text-lg font-semibold text-white bg-black rounded-full shadow-md hover:bg-gray-800 transition duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-black focus:ring-offset-2"
        >
          Get the App
        </button>

        {/* Image */}
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/fd42a230b4ae52ad47d73aad479de2c66cffe8fc9acaee4dc05eefc781f9dabe?placeholderIfAbsent=true&apiKey=6d4aaf8f32ab4edf89f44d27866f52ff"
          alt="Business app interface showcase"
          className="object-contain w-full max-w-[350px] md:max-w-[400px] mt-8 rounded-lg"
          onError={(e) => {
            e.currentTarget.src = "/fallback-image.png"; // Replace with your fallback image path
          }}
        />
      </div>
    </section>
  );
}

export default BusinessHero;
