import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import SetupHeader from "./SetuphHeader";
import Footer from "../Footer";

export default function FAQPage() {
  const navigate = useNavigate();
  const location = useLocation();

  // Retrieve state from previous page
  const { serviceTitle, category, subCategory, packages, milestones, description } = location.state || {};

  // Redirect if required data is missing
  useEffect(() => {
    if (!serviceTitle || !category || !subCategory || !packages || !milestones) {
      alert("Required data is missing. Redirecting to the previous page.");
      navigate("/milestones");
    }
  }, [serviceTitle, category, subCategory, packages, milestones, description, navigate]);

  // FAQ State
  const [faqs, setFaqs] = useState([]);
  const [newQuestion, setNewQuestion] = useState("");
  const [newAnswer, setNewAnswer] = useState("");

  const handleAddFAQ = () => {
    if (!newQuestion.trim() || !newAnswer.trim()) {
      alert("Please provide both a question and an answer.");
      return;
    }
    setFaqs([...faqs, { question: newQuestion, answer: newAnswer }]);
    setNewQuestion("");
    setNewAnswer("");
  };

  const handleRemoveFAQ = (index) => {
    setFaqs(faqs.filter((_, i) => i !== index));
  };

  const handleSubmit = () => {
    if (faqs.length === 0) {
      alert("Please add at least one FAQ before continuing.");
      return;
    }

    // Navigate to the ReviewPage with all collected data
    navigate("/uploadMedia", {
      state: {
        serviceTitle,
        category,
        subCategory,
        packages,
        milestones,
        faqs,
        description
      },
    });
  };

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Setup Header */}
      <SetupHeader currentStep={4} />

      <main className="p-6 md:p-10 my-40 max-w-4xl mx-auto bg-white rounded-2xl shadow-md border border-gray-200">
        <h1 className="text-2xl font-bold text-gray-800 mb-6">
          Frequently Asked Questions
        </h1>
        <p className="text-sm text-gray-600 mb-6">
          Add Questions & Answers for your buyers
        </p>

        {/* Existing FAQs */}
        <div className="space-y-4 mb-6">
          {faqs.map((faq, index) => (
            <div
              key={index}
              className="flex justify-between items-center border border-gray-300 rounded-lg p-4 bg-gray-100"
            >
              <div>
                <p className="text-gray-900 font-medium">{faq.question}</p>
                <p className="text-gray-700 text-sm mt-1">{faq.answer}</p>
              </div>
              <button
                onClick={() => handleRemoveFAQ(index)}
                className="text-red-500 hover:text-red-700 text-sm"
              >
                Remove
              </button>
            </div>
          ))}
        </div>

        {/* Add New FAQ */}
        <div className="space-y-4">
          <div>
            <input
              type="text"
              placeholder="Add Questions"
              className="w-full border border-gray-300 rounded-lg px-4 py-3 focus:ring-2 focus:ring-blue-500 focus:outline-none"
              value={newQuestion}
              onChange={(e) => setNewQuestion(e.target.value)}
            />
          </div>
          <div>
            <textarea
              placeholder="Add Answers for your buyers"
              rows={3}
              className="w-full border border-gray-300 rounded-lg px-4 py-3 focus:ring-2 focus:ring-blue-500 focus:outline-none"
              value={newAnswer}
              onChange={(e) => setNewAnswer(e.target.value)}
            ></textarea>
            <p className="text-sm text-gray-500 mt-2">
              {newAnswer.length}/300 Characters
            </p>
          </div>
        </div>

        {/* Navigation Buttons */}
        <div className="flex flex-col md:flex-row justify-between items-center mt-6">
          <button
            onClick={() => navigate(-1, {
                state: { serviceTitle, category, subCategory, packages, milestones, description }
            })} // Navigate back to milestones page
            className="text-gray-500 hover:text-gray-700 text-sm font-medium"
          >
            Back
          </button>
          <button
            onClick={handleAddFAQ}
            className="bg-black text-white px-6 py-3 rounded-lg font-medium hover:bg-gray-800 transition mb-4 md:mb-0"
          >
            Add FAQ
          </button>
          <button
            onClick={handleSubmit}
            className="bg-blue-600 text-white px-6 py-3 rounded-lg font-medium hover:bg-blue-700 transition"
          >
            Submit
          </button>
        </div>
      </main>

      {/* Footer */}
      <Footer />
    </div>
  );
}
