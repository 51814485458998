import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext"; // Import AuthContext
import { db } from "../config/firebase"; // Import Firebase instance
import { collection, query, where,getDocs, onSnapshot } from "firebase/firestore";
import ProfileCard from "../components/Home/ProfileCard";
import ActivityCard from "../components/Home/ActivityCard";
import LevelOverview from "../components/Home/LevelOverview";
import AvailabilityCard from "../components/Home/AvailabilityCard";
import ServiceOverview from "../components/Home/ServiceOverview";
import PromotionBanner from "../components/Home/PromotionBanner";
import SellerTips from "../components/Home/SellerTips";
import FeatureCard from "../components/Home/FeatureCard";
import ProfileProgress from "../components/Home/ProfileProgress";
import Header from "../components/Home/SetuphHeader";
import Footer from "../components/Footer";
import Connect from "../components/Home/Connect";
import MessagesCard from "../components/Home/MesagesCard";

export default function Home() {
  const { authState } = useAuth();
  const navigate = useNavigate();

  const [unreadMessages, setUnreadMessages] = useState(4);

  // Check if the user is authenticated
  useEffect(() => {
    if (!authState.isAuthenticated) {
      navigate("/"); // Redirect to login if not authenticated
    }
  }, [authState.isAuthenticated, navigate]);

  // Fetch unread messages
  useEffect(() => {
    if (!authState.user?.uid) {
      console.error("User ID is undefined. Cannot fetch messages.");
      return; // Exit if no valid user ID
    }
  
    const fetchMessages = async () => {
      try {
        const messagesQuery = query(
          collection(db, "messages"),
          where("receiverId", "==", authState.user.uid),
          where("isRead", "==", false) // Assuming this field tracks unread status
        );
  
        const querySnapshot = await getDocs(messagesQuery);
        setUnreadMessages(querySnapshot.docs.length); // Update unread messages count
      } catch (error) {
        console.error("Error fetching messages:", error);
      }
    };
  
    fetchMessages();
  }, [authState.user?.uid]);
  

  const activityData = [
    { label: "Total Orders", value: "0" },
    { label: "Complete Orders", value: "0" },
    { label: "Unique clients", value: "0" },
    { label: "Earned in December", value: "0" }
  ];

  const featureCards = [
    {
      title: "Get Noticed",
      description:
        "Tap into the power of social media by sharing your Gig, and get expert help to grow your impact.",
      buttonText: "Share your services",
      iconSrc: "/icons/share.svg"
    },
    {
      title: "Create a portfolio",
      description: "Showcase your skills and experience through your portfolio",
      buttonText: "Create Portfolio",
      iconSrc: "/icons/portfolio.svg"
    },
    {
      title: "Get Noticed",
      description:
        "Tap into the power of social media by sharing your Gig, and get expert help to grow your impact.",
      buttonText: "Share your services",
      iconSrc: "/icons/share.svg"
    },
    {
      title: "Get Noticed",
      description:
        "Tap into the power of social media by sharing your Gig, and get expert help to grow your impact.",
      buttonText: "Share your services",
      iconSrc: "/icons/share.svg"
    }
  ];

  return (
    <>
      <Header currentStep={0} />
      <main
        className="flex flex-col mx-auto w-[85%] max-w-[1200px] space-y-8"
        role="main"
      >
        <h1 className="text-3xl text-black mt-4">
          Welcome {authState.user ? authState.user.name : "Guest"}
        </h1>
        <ProfileProgress
          percentage={authState.user ? authState.user.profileCompletion : 0}
        />

        {/* Top Section */}
        <div className="flex flex-col lg:flex-row gap-6">
          <div className="w-full lg:w-1/3 flex">
            <ProfileCard />
          </div>
          <div className="w-full lg:w-2/3 flex">
            <Connect />
          </div>
        </div>

        {/* Middle Section */}
        <div className="flex flex-col lg:flex-row gap-6 ">
          <div className="w-full lg:w-1/3 space-y-6">
            <MessagesCard unreadMessages={unreadMessages} />
            <LevelOverview />
            <AvailabilityCard />
          </div>
          <div className="w-full lg:w-2/3 space-y-6">
            <ServiceOverview />
          </div>
        </div>

        {/* Seller Tips */}
        <SellerTips />

        {/* Feature Cards */}
        <section className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-6 mt-8">
          {featureCards.map((card, index) => (
            <FeatureCard
              key={index}
              {...card}
              aria-label={`Feature card for ${card.title}`}
            />
          ))}
        </section>

        {/* Promotion Banner */}
        <PromotionBanner />
      </main>
      <Footer />
    </>
  );
}
