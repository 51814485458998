import React, { useState, useEffect } from "react";
import { useAuth } from "../../context/AuthContext"; // Import AuthContext

export default function ProfileProgress() {
  const { authState } = useAuth(); // Access user details from AuthContext
  const user = authState?.user; // Get the user object

  const [percentage, setPercentage] = useState(0); // State for profile completion percentage

  // Calculate profile completion percentage based on key fields
  useEffect(() => {
    const calculateCompletion = () => {
      const completionCriteria = {
        name: !!user?.name,
        email: !!user?.email,
        location: !!user?.location,
        bio: !!user?.bio,
        portfolioLinks: user?.portfolioLinks?.length > 0,
        socialLinks: user?.socialLinks?.length > 0,
        experience: !!user?.experience,
        skills: !!user?.skills,
        recentProject: !!user?.recentProject, // Example: completing a project in the last 6 months
      };

      const completedActions = Object.values(completionCriteria).filter(Boolean).length;
      const totalActions = Object.keys(completionCriteria).length;

      return Math.round((completedActions / totalActions) * 100);
    };

    setPercentage(calculateCompletion());
  }, [user]);

  // Define a motivational message based on the progress percentage
  const getProgressMessage = (completion) => {
    if (completion === 100) {
      return "You're all set! Your profile is fully complete.";
    } else if (completion >= 75) {
      return "Almost there! Just a little more to become a top seller.";
    } else if (completion >= 50) {
      return "You're halfway there! Keep going to unlock more opportunities.";
    } else {
      return "Let's get started! Build your profile to stand out.";
    }
  };

  const progressMessage = getProgressMessage(percentage);

  return (
    <div
      className="bg-white rounded-2xl shadow-lg border border-gray-300 p-6 md:p-8 flex flex-col gap-6 transition-all duration-500"
      role="progressbar"
      aria-valuenow={percentage}
      aria-valuemin="0"
      aria-valuemax="100"
    >
      {/* Top Content */}
      <div className="flex items-center justify-between">
        {/* Percentage Display */}
        <div className="text-5xl font-extrabold text-blue-600">{percentage}%</div>

        {/* Motivational Message */}
        <div className="text-lg md:text-2xl font-semibold text-gray-900 text-center flex-1 mx-4">
          {progressMessage}
        </div>

        {/* Action Icon */}
        <div
          className="w-10 h-10 bg-blue-100 rounded-full flex items-center justify-center"
          aria-hidden="true"
        >
          <span className="text-blue-600 text-2xl">→</span>
        </div>
      </div>

      {/* Progress Bar */}
      <div className="relative w-full bg-gray-200 rounded-full h-4">
        <div
          className="absolute top-0 left-0 bg-gradient-to-r from-blue-500 to-blue-600 h-4 rounded-full transition-all duration-700"
          style={{ width: `${percentage}%` }}
        />
      </div>

      {/* Bottom Note */}
      <div className="text-gray-600 text-sm text-center mt-4">
        Complete your profile to unlock exclusive features and maximize your
        potential on <span className="text-blue-600 font-semibold">EARN</span>.
      </div>
    </div>
  );
}
