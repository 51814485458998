import * as React from "react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";


export default function Connect() {

  const navigate = useNavigate();
  const handleServiceClick = () => {
    navigate("/createService");
  };

  return (
    <motion.section
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.6 }}
      aria-label="Hero Section"
      className="flex flex-col justify-center h-full w-full rounded-2xl bg-blue-950"
    >
      <div className="flex flex-col items-start px-8 py-12 lg:px-12 lg:py-14 space-y-4">
        <h1 className="text-3xl lg:text-4xl font-bold text-white leading-tight">
          Connect with Clients <br />
          in your{" "}
          <span className="italic text-indigo-500">league</span>
        </h1>
        <button
          onClick={handleServiceClick}
          aria-label="Sell a Service"
          className="mt-4 px-6 py-3 text-sm font-semibold text-blue-950 bg-white rounded-full hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-colors"
        > 
          Sell a Service
        </button>
      </div>
    </motion.section>
  );
}
