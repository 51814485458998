import React, { useState } from "react";
import { useAuth } from "../../context/AuthContext"; // Import AuthContext

function ProfileSection() {
  const { authState, updateUserProfile } = useAuth(); // Access AuthContext
  const user = authState.user; // Extract user details

  const [username, setUsername] = useState(user?.name || ""); // Username state
  const [biography, setBiography] = useState(user?.bio || ""); // Biography state
  const [editing, setEditing] = useState(false); // Track edit mode
  const [saving, setSaving] = useState(false); // Track saving state

  const handleSave = async (e) => {
    e.preventDefault();
    setSaving(true);

    try {
      await updateUserProfile({ name: username, bio: biography }); // Update profile
      alert("Profile updated successfully!");
      setEditing(false); // Exit edit mode
    } catch (error) {
      console.error("Failed to update profile:", error);
      alert("Failed to update profile. Please try again.");
    } finally {
      setSaving(false);
    }
  };

  return (
    <div className="flex flex-col p-6 mt-12 w-full text-black bg-white rounded-lg shadow-lg max-w-4xl mx-auto">
      {/* Header Section */}
      <div className="flex justify-between items-center border-b pb-4">
        <h2 className="text-2xl font-semibold text-gray-800">Profile</h2>
        <button
          onClick={() => setEditing(!editing)}
          className="text-blue-500 underline hover:text-blue-600 transition duration-200"
        >
          {editing ? "Cancel" : "Edit"}
        </button>
      </div>

      {/* Content Section */}
      {editing ? (
        <form
          onSubmit={handleSave}
          className="flex flex-col mt-6 space-y-6 text-lg"
        >
          {/* Username Field */}
          <div className="flex flex-col">
            <label
              htmlFor="username"
              className="text-gray-700 font-medium mb-2"
            >
              Username
            </label>
            <input
              id="username"
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              className="px-4 py-3 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>

          {/* Biography Field */}
          <div className="flex flex-col">
            <label
              htmlFor="biography"
              className="text-gray-700 font-medium mb-2"
            >
              Biography
            </label>
            <textarea
              id="biography"
              value={biography}
              onChange={(e) => setBiography(e.target.value)}
              className="px-4 py-3 h-32 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>

          {/* Save Button */}
          <button
            type="submit"
            className="px-6 py-3 text-white bg-blue-500 rounded-lg hover:bg-blue-600 transition duration-200 self-start"
            disabled={saving}
          >
            {saving ? "Saving..." : "Save Changes"}
          </button>
        </form>
      ) : (
        <div className="mt-6 space-y-6 text-lg">
          {/* Display Username */}
          <div className="flex flex-col">
            <span className="text-gray-700 font-medium">Username</span>
            <span className="text-gray-800 mt-1">
              {user?.name || "No username set"}
            </span>
          </div>

          {/* Display Biography */}
          <div className="flex flex-col">
            <span className="text-gray-700 font-medium">Biography</span>
            <span className="text-gray-800 mt-1 whitespace-pre-wrap">
              {user?.bio || "No biography available"}
            </span>
          </div>
        </div>
      )}
    </div>
  );
}

export default ProfileSection;
