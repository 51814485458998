import React, { useEffect, useState, useRef } from 'react';
import {
  collection,
  query,
  orderBy,
  onSnapshot,
  doc,
  addDoc,
  serverTimestamp,
  updateDoc,
} from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { db, storage } from '../../config/firebase';
import { ChatMessage } from './ChatMessage';
import { Smile, Paperclip, Send } from 'lucide-react';
import Picker from '@emoji-mart/react';
import data from '@emoji-mart/data';

export default function ChatMessages({ currentUserId, chatId }) {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [file, setFile] = useState(null);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const messagesEndRef = useRef(null);
  const fileInputRef = useRef(null);

  useEffect(() => {
    const messagesRef = collection(db, 'messages', chatId, 'messages');
    const q = query(messagesRef, orderBy('timestamp', 'asc'));

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const fetchedMessages = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setMessages(fetchedMessages);
    });

    return () => unsubscribe();
  }, [chatId]);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  const handleSendMessage = async (e) => {
    e.preventDefault();
    if (!newMessage.trim() && !file) return;

    let mediaUrl = '';
    let mediaType = '';

    if (file) {
      const fileRef = ref(storage, `chat-media/${chatId}/${Date.now()}-${file.name}`);
      const snapshot = await uploadBytes(fileRef, file);
      mediaUrl = await getDownloadURL(snapshot.ref);
      mediaType = file.type;
    }

    const messagesRef = collection(db, 'messages', chatId, 'messages');
    await addDoc(messagesRef, {
      text: newMessage,
      sender: currentUserId,
      timestamp: serverTimestamp(),
      mediaUrl,
      mediaType,
      
    });

    // update recent message
    const chatRef = doc(db, 'messages', chatId); // Use doc to reference the specific chat document
    await updateDoc(chatRef, {
    
      recentMessage: {
        message: newMessage,
        sender: currentUserId,
        timestamp: serverTimestamp(),
        mediaUrl,
        mediaType,
      },
    });

    setNewMessage('');
    setFile(null);
  };

  return (
    <div className="flex-1 flex flex-col h-full bg-white">
      <div className="flex-1 overflow-y-auto p-4">
        {messages.map((message) => (
          <ChatMessage key={message.id} message={{ ...message, currentUserId }} />
        ))}
        <div ref={messagesEndRef} />
      </div>
      <div className="border-t p-4">
        {file && (
          <div className="mb-2 flex items-center text-sm text-gray-600">
            <Paperclip className="w-4 h-4 mr-2" />
            {file.name}
            <button
              onClick={() => setFile(null)}
              className="ml-2 text-red-500 hover:text-red-600"
            >
              ×
            </button>
          </div>
        )}
        <form onSubmit={handleSendMessage} className="flex items-center gap-2">
          <button
            type="button"
            onClick={() => fileInputRef.current?.click()}
            className="p-2 hover:bg-gray-100 rounded-full"
          >
            <Paperclip className="w-5 h-5 text-gray-500" />
          </button>
          <input
            type="file"
            ref={fileInputRef}
            onChange={(e) => setFile(e.target.files[0])}
            className="hidden"
            accept="image/*,video/*"
          />
          <div className="relative">
            <button
              type="button"
              onClick={() => setShowEmojiPicker(!showEmojiPicker)}
              className="p-2 hover:bg-gray-100 rounded-full"
            >
              <Smile className="w-5 h-5 text-gray-500" />
            </button>
            {showEmojiPicker && (
              <div className="absolute bottom-12 left-0 z-10">
                <Picker data={data} onEmojiSelect={(emoji) => setNewMessage((prev) => prev + emoji.native)} />
              </div>
            )}
          </div>
          <input
            type="text"
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            placeholder="Type a message..."
            className="flex-1 p-2 border rounded-full focus:outline-none"
          />
          <button type="submit" className="p-2 bg-blue-500 text-white rounded-full hover:bg-blue-600">
            <Send className="w-5 h-5" />
          </button>
        </form>
      </div>
    </div>
  );
}
