import React from 'react';
import { User } from 'lucide-react';
import { format } from 'date-fns';
import { Menu, Transition } from '@headlessui/react';
import { MoreVertical, Archive, Trash } from 'lucide-react';

const ChatItem = ({ chat, currentUserId, usernames, onSelect, onArchive, onDelete }) => {
  return (
    <div
      key={chat.id}
      className="p-4 border-b cursor-pointer flex items-center hover:shadow-lg hover:border-gray-300 transition-all duration-300 bg-white rounded-lg"
      onClick={() => onSelect(chat.id)}
    >
      {/* User Avatar */}
      <div className="w-12 h-12 bg-gray-100 rounded-full flex items-center justify-center shadow-sm">
        <User className="w-6 h-6 text-gray-500" />
      </div>

      {/* Chat Details */}
      <div className="ml-4 flex-1">
        <div className="flex justify-between items-center">
          <h3 className="font-semibold text-gray-800 truncate">
            {chat.participants
              .filter((p) => p !== currentUserId)
              .map((uid) => usernames[uid] || 'Loading...')}
          </h3>
        </div>
        <p className="text-sm text-gray-600 truncate mt-1">
          {chat.recentMessage?.message || 'No messages yet'}
        </p>
      </div>

      {/* Trailing Icon with Time and Status */}
      <div className="flex items-center gap-2">
        <div className="flex flex-col items-center">
          <span
            className={`w-2.5 h-2.5 rounded-full ${
              chat.recentMessage?.isRead ? 'bg-green-500' : 'bg-red-500'
            }`}
          ></span>
          {chat.recentMessage?.timestamp && (
            <span className="text-xs text-gray-400 mt-1">
              {format(chat.recentMessage.timestamp.toDate(), 'HH:mm')}
            </span>
          )}
        </div>

        {/* Popup Menu for Actions */}
        <Menu as="div" className="relative">
          <Menu.Button className="p-2 hover:bg-gray-200 rounded-full transition-all duration-200">
            <MoreVertical className="w-5 h-5 text-gray-500" />
          </Menu.Button>
          <Transition
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 mt-2 w-36 bg-white border border-gray-200 rounded-lg shadow-lg focus:outline-none z-10">
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      onArchive(chat.id);
                    }}
                    className={`flex items-center w-full px-4 py-2 text-sm ${
                      active ? 'bg-gray-100' : ''
                    } text-gray-700`}
                  >
                    <Archive className="w-4 h-4 mr-2" />
                    Archive
                  </button>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      onDelete(chat.id);
                    }}
                    className={`flex items-center w-full px-4 py-2 text-sm ${
                      active ? 'bg-gray-100' : ''
                    } text-red-500`}
                  >
                    <Trash className="w-4 h-4 mr-2" />
                    Delete
                  </button>
                )}
              </Menu.Item>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </div>
  );
};

export default ChatItem;
