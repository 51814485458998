import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import SetupHeader from "./SetuphHeader";
import Footer from "../Footer";

export default function MilestonesPage() {
  const navigate = useNavigate();
  const location = useLocation();

  // Retrieve data from the previous page
  const { serviceTitle, category, subCategory, packages, description } = location.state || {};

  // Redirect if required fields are missing
  useEffect(() => {
    if (!serviceTitle || !category || !subCategory || !packages || !description) {
      alert("Required fields are missing. Redirecting to pricing page.");
      navigate("/pricing");
    }
  }, [serviceTitle, category, subCategory, packages, description, navigate]);

  const [milestones, setMilestones] = useState([
    { name: "", package: "Basic", concepts: 0, deliveryTime: { quantity: 0, unit: "" }, fee: 0 },
  ]);

  const handleAddMilestone = () => {
    setMilestones([
      ...milestones,
      { name: "", package: "Basic", concepts: 0, deliveryTime: { quantity: 0, unit: "" }, fee: 0 },
    ]);
  };

  const handleRemoveMilestone = (index) => {
    setMilestones(milestones.filter((_, i) => i !== index));
  };

  const handleMilestoneChange = (index, field, value) => {
    const updatedMilestones = [...milestones];
    updatedMilestones[index][field] = value;
    setMilestones(updatedMilestones);
  };

  const handleDeliveryTimeChange = (index, field, value) => {
    const updatedMilestones = [...milestones];
    updatedMilestones[index].deliveryTime[field] = value;
    setMilestones(updatedMilestones);
  };

  const validateMilestones = () => {
    for (const milestone of milestones) {
      if (!milestone.name.trim()) {
        alert("Please provide a name for all milestones.");
        return false;
      }
      if (milestone.concepts <= 0 || isNaN(milestone.concepts)) {
        alert("Please provide a valid number of concepts for each milestone.");
        return false;
      }
      if (milestone.deliveryTime.quantity <= 0 || !milestone.deliveryTime.unit) {
        alert("Please provide a valid delivery time for each milestone.");
        return false;
      }
      if (milestone.fee <= 0 || isNaN(milestone.fee)) {
        alert("Please provide a valid fee for each milestone.");
        return false;
      }
    }
    return true;
  };

  const handleSaveAndContinue = () => {
    if (!validateMilestones()) return;

    navigate("/faq", {
      state: {
        serviceTitle,
        category,
        subCategory,
        packages,
        milestones,
        description,
      },
    });
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <SetupHeader currentStep={3} />

      <main className="p-6 md:p-10 my-40 max-w-4xl mx-auto bg-white rounded-2xl shadow-md border border-gray-200">
        <h1 className="text-2xl font-bold text-gray-800 mb-6">
          Define Milestones for {serviceTitle}
        </h1>

        <div className="mb-6">
          <p className="text-lg text-gray-600">
            <strong>Category:</strong> {category}
          </p>
          <p className="text-lg text-gray-600">
            <strong>Sub-Category:</strong> {subCategory}
          </p>
        </div>

        {milestones.map((milestone, index) => (
          <div
            key={index}
            className="border-t border-gray-300 py-4 mb-4 rounded-lg"
          >
            <div className="flex justify-between items-center">
              <h2 className="text-lg font-semibold text-gray-800">
                Milestone {index + 1}
              </h2>
              <button
                onClick={() => handleRemoveMilestone(index)}
                className="text-red-500 hover:text-red-700 text-sm"
              >
                Remove
              </button>
            </div>
            <div className="mt-4 space-y-4">
              {/* Milestone Name */}
              <div>
                <label className="block text-lg font-semibold text-gray-800 mb-2">
                  Milestone Name
                </label>
                <input
                  type="text"
                  placeholder="Name your milestone"
                  className="w-full border border-gray-300 rounded-lg px-4 py-3 focus:ring-2 focus:ring-blue-500 focus:outline-none"
                  value={milestone.name}
                  onChange={(e) =>
                    handleMilestoneChange(index, "name", e.target.value)
                  }
                />
              </div>

              {/* Package */}
              <div>
                <label className="block text-lg font-semibold text-gray-800 mb-2">
                  Package
                </label>
                <select
                  value={milestone.package}
                  onChange={(e) =>
                    handleMilestoneChange(index, "package", e.target.value)
                  }
                  className="w-full border border-gray-300 rounded-lg px-4 py-3 focus:ring-2 focus:ring-blue-500 focus:outline-none"
                >
                  <option value="Basic">Basic</option>
                  <option value="Standard">Standard</option>
                  <option value="Premium">Premium</option>
                </select>
              </div>

              {/* Concepts & Delivery Time */}
              <div className="grid grid-cols-2 gap-6">
                <div>
                  <label className="block text-lg font-semibold text-gray-800 mb-2">
                    Number of Concepts
                  </label>
                  <input
                    type="number"
                    min="1"
                    placeholder="Enter number of concepts"
                    className="w-full border border-gray-300 rounded-lg px-4 py-3 focus:ring-2 focus:ring-blue-500 focus:outline-none"
                    value={milestone.concepts}
                    onChange={(e) =>
                      handleMilestoneChange(index, "concepts", parseInt(e.target.value, 10))
                    }
                  />
                </div>
                <div>
                  <label className="block text-lg font-semibold text-gray-800 mb-2">
                    Delivery Time
                  </label>
                  <div className="flex gap-4">
                    <input
                      type="number"
                      min="1"
                      placeholder="Quantity"
                      className="w-1/2 border border-gray-300 rounded-lg px-4 py-3 focus:ring-2 focus:ring-blue-500 focus:outline-none"
                      value={milestone.deliveryTime.quantity}
                      onChange={(e) =>
                        handleDeliveryTimeChange(index, "quantity", parseInt(e.target.value, 10))
                      }
                    />
                    <select
                      className="w-1/2 border border-gray-300 rounded-lg px-4 py-3 focus:ring-2 focus:ring-blue-500 focus:outline-none"
                      value={milestone.deliveryTime.unit}
                      onChange={(e) =>
                        handleDeliveryTimeChange(index, "unit", e.target.value)
                      }
                    >
                      <option value="">Select Unit</option>
                      <option value="days">Days</option>
                      <option value="weeks">Weeks</option>
                      <option value="months">Months</option>
                    </select>
                  </div>
                </div>
              </div>

              {/* Fee */}
              <div>
                <label className="block text-lg font-semibold text-gray-800 mb-2">
                  Milestone Fee
                </label>
                <input
                  type="number"
                  min="1"
                  placeholder="Enter milestone fee"
                  className="w-full border border-gray-300 rounded-lg px-4 py-3 focus:ring-2 focus:ring-blue-500 focus:outline-none"
                  value={milestone.fee}
                  onChange={(e) =>
                    handleMilestoneChange(index, "fee", parseFloat(e.target.value))
                  }
                />
              </div>
            </div>
          </div>
        ))}

        <button
          onClick={handleAddMilestone}
          className="mt-4 bg-blue-500 text-white px-4 py-2 rounded-lg font-medium hover:bg-blue-600"
        >
          + Add Milestone
        </button>

        <div className="flex justify-between items-center mt-8">
          <button
            className="text-gray-500 hover:text-gray-700 text-sm font-medium"
            onClick={() =>
              navigate("/pricing", {
                state: { serviceTitle, category, subCategory, packages, description },
              })
            }
          >
            Back
          </button>
          <button
            onClick={handleSaveAndContinue}
            className="bg-blue-600 text-white px-6 py-3 rounded-lg font-medium hover:bg-blue-700 transition"
          >
            Save & Continue
          </button>
        </div>
      </main>

      <Footer />
    </div>
  );
}
