import React, { useState,  } from "react";
import { IoIosArrowForward } from "react-icons/io";
import { FiMenu, FiX } from "react-icons/fi";
import LoginModal from "../../Login/LoginModal";
import { useNavigate } from "react-router-dom";

const NavbarWithOverlayFix = () => {
  const [activeTab, setActiveTab] = useState(""); // Tracks the active tab (Discovery, Integration Tools, or Wise Integration)
  const [activeSidebarItem, setActiveSidebarItem] = useState("clients"); // Tracks active sidebar item for Discovery
  const [menuOpen, setMenuOpen] = useState(false); // Controls the mobile menu visibility// Controls the mobile menu visibility
  const [isLoginModalOpen, setLoginModalOpen] = useState(false);

  const navigate = useNavigate();

  // Content for Discovery Sidebar
  const discoveryContent = {
    clients: (
      <div className="flex-1 p-6 space-y-6">
        <div>
          <h4 className="font-semibold text-lg">AI-Powered Client Discovery Rules</h4>
          <ul className="text-sm mt-2 space-y-2">
            <li>● 0-5 Employees</li>
            <li>● 5-10 Employees</li>
            <li>● 11-50 Employees</li>
          </ul>
        </div>
        <div>
          <h4 className="font-semibold text-lg">Key Roles</h4>
          <ul className="text-sm mt-2 space-y-2">
            <li>● Creative Directors</li>
            <li>● Chief Technology Officers</li>
            <li>● Chief Executive Officers</li>
            <li>● Chief Marketing Officers</li>
          </ul>
        </div>
      </div>
    ),
    freelancers: (
      <div className="flex-1 p-6 space-y-6">
        <div>
          <h4 className="font-semibold text-lg">Experience Levels</h4>
          <ul className="text-sm mt-2 space-y-2">
            <li>● Experienced Mid-Tier Freelancers</li>
            <li>● Mid-Tier Experience</li>
            <li>● New / Fresh Graduates</li>
          </ul>
        </div>
        <div>
          <h4 className="font-semibold text-lg">Diverse Talent Pool</h4>
          <ul className="text-sm mt-2 space-y-2">
            <li>● By Geography</li>
          </ul>
        </div>
      </div>
    ),
    collaboration: (
      <div className="flex-1 p-6">
        <h4 className="font-semibold text-lg">Collaboration Discovery</h4>
        <ul className="text-sm mt-2 space-y-2">
          <li>● Team Design Based on Project Requirements</li>
          <li>● Project Design Based on Team Skills</li>
        </ul>
      </div>
    ),
    influencers: (
      <div className="flex-1 p-6 space-y-6">
        <div>
          <h4 className="font-semibold text-lg">Mid-Tier Influencers</h4>
          <ul className="text-sm mt-2 space-y-2">
            <li>● Growing Following</li>
            <li>● Intimate Engagement with Followers</li>
          </ul>
        </div>
        <div>
          <h4 className="font-semibold text-lg">Discovery Parameters / Filters</h4>
          <ul className="text-sm mt-2 space-y-2">
            <li>● Location</li>
            <li>● Follower Range</li>
            <li>● Age</li>
            <li>● Total Revenue</li>
          </ul>
        </div>
      </div>
    ),
  };

  return (
    <div>
      {/* Navbar */}
      <nav className="fixed inset-x-0 top-0 bg-transparent z-50">
        <div className="flex justify-between items-center px-4 md:px-8 py-4">
          {/* Brand Logo */}
          <div className="text-xl font-bold text-black">EARN</div>

          {/* Hamburger Menu for Mobile */}
          <div className="md:hidden flex items-center space-x-4">
            {/* Action Buttons for Mobile */}
            <a href="#" onClick={() => setLoginModalOpen(true)} className="hover:underline text-black text-sm">
              Sign In
            </a>
            <button onClick={() => setLoginModalOpen(true)} className="bg-blue-500 text-white px-4 py-1 rounded-md text-sm hover:bg-blue-600">
              Sign Up
            </button>
            
          </div>

          {/* Hamburger Menu for Mobile */}
          <div className="md:hidden">
            <button onClick={() => setMenuOpen(!menuOpen)} className="text-black">
              {menuOpen ? <FiX size={24} /> : <FiMenu size={24} />}
            </button>
          </div>

          {/* Navbar Links */}
          <ul
            className={`${
              menuOpen ? "block" : "hidden"
            } md:flex md:space-x-8 absolute md:static left-0 top-16 bg-white md:bg-transparent w-full md:w-auto z-40 md:z-auto`}
          >
            {/* Discovery Tab */}
            <li>
              <button
                onClick={() =>
                  setActiveTab(activeTab === "discovery" ? "" : "discovery")
                }
                className={`relative block w-full md:w-auto text-left hover:text-blue-500 px-4 py-2 md:py-0 text-black ${
                  activeTab === "discovery"
                    ? "font-semibold underline decoration-black"
                    : ""
                }`}
              >
                Discovery
              </button>
            </li>

            {/* Integration Tools Tab */}
            <li>
              <button
                onClick={() =>
                  setActiveTab(activeTab === "integration" ? "" : "integration")
                }
                className={`relative block w-full md:w-auto text-left hover:text-blue-500 px-4 py-2 md:py-0 text-black ${
                  activeTab === "integration"
                    ? "font-semibold underline decoration-black"
                    : ""
                }`}
              >
                Integration Tools
              </button>
            </li>

            {/* Wise Integration Tab */}
            <li>
              <button
                onClick={() =>
                  setActiveTab(activeTab === "wise" ? "" : "wise")
                }
                className={`relative block w-full md:w-auto text-left hover:text-blue-500 px-4 py-2 md:py-0 text-black ${
                  activeTab === "wise"
                    ? "font-semibold underline decoration-black"
                    : ""
                }`}
              >
                Wise Integration
              </button>
            </li>

            {/* Static Links */}
            <li>
              <a 
                onClick={() => navigate("/about-us")}
                href="#"
                className="block w-full md:w-auto px-4 py-2 md:py-0 hover:text-blue-500 text-black"
              >
                About
              </a>
            </li>
            <li>
              <a
                href="#"
                className="block w-full md:w-auto px-4 py-2 md:py-0 hover:text-blue-500 text-black"
              >
                Services
              </a>
            </li>
            <li>
              <a
                href="#"
                className="block w-full md:w-auto px-4 py-2 md:py-0 hover:text-blue-500 text-black"
              >
                Contact
              </a>
            </li>
          </ul>

          {/* Action Buttons */}
          <div className="hidden md:flex space-x-4">
            {/* <a href="#" className="hover:underline text-black">
              Sign In
            </a> */}
            <button onClick={() => setLoginModalOpen(true)} className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600">
              Sign In
            </button>
          </div>
        </div>
      </nav>

      {/* Overlay and Floating Card */}
      {activeTab && (
        <>
          {/* Overlay */}
          <div
            className="fixed inset-0 bg-black bg-opacity-50 z-40"
            onClick={() => setActiveTab("")}
          ></div>

          {/* Floating Card */}
          <div
            className="absolute bg-white shadow-lg rounded-md left-0 top-[4rem] w-full z-50 max-h-[70vh] overflow-auto"
          >
            {/* Close Button */}
            <div className="flex justify-end p-4">
              <button
                onClick={() => setActiveTab("")}
                className="text-gray-500 hover:text-gray-700"
              >
                <FiX size={24} />
              </button>
            </div>

            {activeTab === "discovery" && (
              <div className="flex flex-col md:flex-row">
                {/* Sidebar */}
                <div className="w-full md:w-1/3 border-b md:border-b-0 md:border-r p-4 space-y-4">
                  <ul className="space-y-4">
                    <li
                      className={`p-2 rounded-md cursor-pointer flex justify-between items-center ${
                        activeSidebarItem === "clients"
                          ? "bg-purple-100 text-purple-600"
                          : "hover:bg-gray-100"
                      }`}
                      onClick={() => setActiveSidebarItem("clients")}
                    >
                      Discovery of Clients <IoIosArrowForward />
                    </li>
                    <li
                      className={`p-2 rounded-md cursor-pointer flex justify-between items-center ${
                        activeSidebarItem === "freelancers"
                          ? "bg-purple-100 text-purple-600"
                          : "hover:bg-gray-100"
                      }`}
                      onClick={() => setActiveSidebarItem("freelancers")}
                    >
                      Discovery of Freelancers <IoIosArrowForward />
                    </li>
                    <li
                      className={`p-2 rounded-md cursor-pointer flex justify-between items-center ${
                        activeSidebarItem === "collaboration"
                          ? "bg-purple-100 text-purple-600"
                          : "hover:bg-gray-100"
                      }`}
                      onClick={() => setActiveSidebarItem("collaboration")}
                    >
                      Collaboration Discovery <IoIosArrowForward />
                    </li>
                    <li
                      className={`p-2 rounded-md cursor-pointer flex justify-between items-center ${
                        activeSidebarItem === "influencers"
                          ? "bg-purple-100 text-purple-600"
                          : "hover:bg-gray-100"
                      }`}
                      onClick={() => setActiveSidebarItem("influencers")}
                    >
                      Influencer Discovery <IoIosArrowForward />
                    </li>
                  </ul>
                </div>

                {/* Main Content */}
                <div className="flex-1">
                  {discoveryContent[activeSidebarItem]}
                </div>
              </div>
            )}

            {activeTab === "integration" && (
              <div className="p-6 grid grid-cols-1 md:grid-cols-4 gap-4">
                <div>
                  <h4 className="font-semibold">Shopify Integration</h4>
                  <ul className="text-sm mt-2 space-y-2">
                    <li>● Discount Code</li>
                    <li>● Automatic Tracking</li>
                    <li>● Connect to Shopify</li>
                  </ul>
                </div>
                <div>
                  <h4 className="font-semibold">Social Media Discovery</h4>
                  <ul className="text-sm mt-2 space-y-2">
                    <li>● Facebook</li>
                    <li>● Instagram</li>
                    <li>● TikTok</li>
                    <li>● YouTube</li>
                  </ul>
                </div>
                <div>
                  <h4 className="font-semibold">Performance Evaluation</h4>
                  <ul className="text-sm mt-2 space-y-2">
                    <li>● Evaluate Content & Performance</li>
                    <li>● Track Automatically</li>
                  </ul>
                </div>
                <div>
                  <h4 className="font-semibold">Plug-ins Through Partnerships</h4>
                  <ul className="text-sm mt-2 space-y-2">
                    <li>● Review Code (via Graptile)</li>
                    <li>● Review Graphics</li>
                    <li>● Upgrade Pictures</li>
                  </ul>
                </div>
              </div>
            )}

            {activeTab === "wise" && (
              <div className="p-6">
                <h4 className="font-semibold">Money Transfers Through Wise</h4>
              </div>
            )}
          </div>
        </>
      )}
      <LoginModal isOpen={isLoginModalOpen} onClose={() => setLoginModalOpen(false)} />
    </div>
  );
};

export default NavbarWithOverlayFix;
