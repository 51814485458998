import * as React from "react";
import AnalogClock from "./AnalogClock";
import Clock from "./AnalogClock";

const timeZones = [
  { city: 'New York', zone: 'America/New_York' },
  { city: 'Paris', zone: 'Europe/Paris' },
  { city: 'Gaborone', zone: 'Africa/Gaborone' },
  { city: 'Cape Town', zone: 'Africa/Johannesburg' },
];



function TalentLocations() {
  const locations = ["New York", "Paris", "Gaborone", "Cape Town"];

  return (
    <section className="py-16 px-8 bg-transparent  text-gray-800">
      {/* Section Title */}
      <div className="text-center mb-12">
        <h1 className="text-3xl md:text-4xl lg:text-5xl font-bold leading-tight">
          Around the Clock Talent <br /> No Matter the Time-Zone
        </h1>
      </div>

      {/* Clock Items */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8 items-center justify-center max-w-5xl mx-auto">
      {timeZones.map(({ city, zone }) => (
          <Clock key={city} timeZone={zone} city={city} />
        ))}
      </div>

      {/* World Map (optional) */}
      {/* Uncomment the below code if you want to include the world map */}
      {/*
      <div className="mt-16">
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/b536390d1cc4c5052eb413ee485f021e55ad52257fc5050d700639136c69214d?placeholderIfAbsent=true&apiKey=44862712bdb34f49aaf0b7f367088f08"
          alt="World map showing different time zones"
          className="object-contain mx-auto w-full max-w-4xl rounded-lg shadow-md"
        />
      </div>
      */}
    </section>
  );
}

export default TalentLocations;
