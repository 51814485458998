import React, { useEffect } from "react";
import { useContent } from "../../context/ContentContext";
import { useAuth } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";

export default function ServiceOverview() {
  const {
    serviceCount,
    completedOrders,
    monthlyEarnings,
    fetchServiceCount,
    fetchCompletedOrders,
    fetchMonthlyEarnings,
    loading,
    error,

  } = useContent();

  // navigate
  const navigate = useNavigate();

  const { authState } = useAuth();
  const userId = authState.user.uid;

  // Get the current month name
  const currentMonth = new Date().toLocaleString("default", { month: "long" });

  useEffect(() => {
    // Fetch data on component mount
    fetchServiceCount(userId);
    fetchCompletedOrders(userId);
    fetchMonthlyEarnings(userId);
  }, [userId]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div className="text-red-500">Error: {error}</div>;
  }

  return (
    <div className="p-4 sm:p-6 bg-white border border-gray-200 rounded-2xl shadow-sm w-full h-full flex flex-col">
      {/* Services Section */}
      <div className="mb-8">
        <h2 className="text-2xl font-semibold text-gray-900">Services</h2>
        <p className="text-sm text-gray-500">Your account details at a glance</p>

        {/* Cards */}
        <div className="flex flex-col md:flex-row items-center gap-4 mt-6">
          {/* Services Count */}
          <div onClick={()=>navigate('/profile/services')} className="flex-1 flex items-center justify-between border border-gray-300 rounded-lg p-4 md:p-6 shadow-sm w-full">
            <span className="text-lg font-semibold text-gray-900">Services</span>
            <span className="text-3xl font-bold text-gray-500">{serviceCount}</span>
          </div>

          {/* Create Service */}
          <div className="flex-1 flex items-center justify-between border border-gray-300 rounded-lg p-4 md:p-6 shadow-sm w-full cursor-pointer hover:bg-gray-50 transition">
            <span className="text-lg font-semibold text-gray-900">Create Service</span>
            <div className="h-8 w-8 bg-gray-200 rounded-full flex items-center justify-center">
              <a href="/createService">
                <span className="text-gray-700 font-bold text-lg">+</span>
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* Activity Overview Section */}
      <div className="flex-1 flex flex-col">
        <h2 className="text-2xl font-semibold text-gray-900">Activity Overview</h2>
        <p className="text-sm text-gray-500">Your account details at a glance</p>

        {/* Activity Cards */}
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-6 flex-1">
          {/* Total Orders */}
          <div className="flex items-center justify-between bg-gray-100 rounded-2xl p-4 md:p-6 w-full">
            <div className="text-lg md:text-xl font-semibold text-gray-900 leading-tight">
              Total <br /> Orders
            </div>
            <div className="text-2xl md:text-3xl font-bold text-gray-400 text-right">
              {/* Placeholder for Total Orders */}
              0
            </div>
          </div>

          {/* Complete Orders */}
          <div className="flex items-center justify-between bg-gray-100 rounded-2xl p-4 md:p-6 w-full">
            <div className="text-lg md:text-xl font-semibold text-gray-900 leading-tight">
              Complete <br /> Orders
            </div>
            <div className="text-2xl md:text-3xl font-bold text-gray-400 text-right">
              {completedOrders}
            </div>
          </div>

          {/* Unique Clients */}
          <div className="flex items-center justify-between bg-gray-100 rounded-2xl p-4 md:p-6 w-full">
            <div className="text-lg md:text-xl font-semibold text-gray-900 leading-tight">
              Unique <br /> Clients
            </div>
            <div className="text-2xl md:text-3xl font-bold text-gray-400 text-right">
              {/* Placeholder for Unique Clients */}
              0
            </div>
          </div>

          {/* Earned in Current Month */}
          <div className="flex items-center justify-between bg-gray-100 rounded-2xl p-4 md:p-6 w-full">
            <div className="text-lg md:text-xl font-semibold text-gray-900 leading-tight">
              Earned in <br /> {currentMonth}
            </div>
            <div className="text-2xl md:text-3xl font-bold text-gray-400 text-right">
              ${monthlyEarnings}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
