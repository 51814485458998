import axios from "axios";

// Create an Axios instance
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL || "http://127.0.0.1:5001/earn-5f1b1/us-central1/app", // API base URL
  timeout: 10000, // Optional: Set timeout for requests
  withCredentials: true, // <-- CRUCIAL to include cookies/credentials
});

// Add a request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    // If you use JWT tokens
    const token = localStorage.getItem("authToken"); 
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add a response interceptor
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    // For 401 errors, handle redirect or logout logic
    if (error.response?.status === 401) {
      console.error("Unauthorized, redirecting to login...");
      window.location.href = "/";
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
