import React from "react";
import { useAuth } from "../../context/AuthContext"; // Import AuthContext
import { useEffect, useState } from "react";

const ratingItems = [
  { key: "profile", text: "Build your profile", icon: "path/to/icon1.png" },
  { key: "personalDetails", text: "Fill out your personal details", icon: "path/to/icon2.png" },
  { key: "professionalBackground", text: "Share your professional background", icon: "path/to/icon3.png" },
  { key: "recentProject", text: "Complete a project within the last 6 months", icon: "path/to/icon4.png" },
];

// Map profile completion percentage to user rating tags
const getUserRatingTag = (completion) => {
  if (completion === 100) return "Top Performer";
  if (completion >= 76) return "Accomplished Professional";
  if (completion >= 51) return "Go-Getter";
  if (completion >= 26) return "Rising Star";
  return "Newcomer";
};

// Generate motivational progress message based on completion percentage
const getProgressMessage = (completion) => {
  if (completion === 100) {
    return "Fantastic work! Your profile is 100% complete and ready to attract high-quality clients. Now, let’s put it to work!";
  } else if (completion >= 76) {
    return "You're almost there! Just a little more effort, and your profile will be a magnet for exciting projects.";
  } else if (completion >= 51) {
    return "You're doing amazing! Your profile is coming together beautifully. Complete a few more sections to unlock even more opportunities.";
  } else if (completion >= 26) {
    return "Great start! You're halfway there. Add more details like your professional background and portfolio links to make your profile shine.";
  } else {
    return "You're just starting your journey. Let's get your profile ready to showcase your unique skills and stand out to potential clients!";
  }
};

function FreelancerRating() {
  const { authState } = useAuth(); // Access AuthContext
  const user = authState.user; // Extract user details

  const completedActions = {
    profile: !!user?.name && !!user?.email,
    personalDetails: !!user?.bio && !!user?.location,
    professionalBackground:
      user?.portfolioLinks?.length > 0 &&
      user?.socialLinks?.length > 0 &&
      !!user?.skills,
    recentProject: !!user?.recentProject,
  };

  const [percentage, setPercentage] = useState(0); // State for profile completion percentage

  // Calculate profile completion percentage based on key fields
  useEffect(() => {
    const calculateCompletion = () => {
      const completionCriteria = {
        name: !!user?.name,
        email: !!user?.email,
        location: !!user?.location,
        bio: !!user?.bio,
        portfolioLinks: user?.portfolioLinks?.length > 0,
        socialLinks: user?.socialLinks?.length > 0,
        experience: !!user?.experience,
        skills: !!user?.skills,
        recentProject: !!user?.recentProject, // Example: completing a project in the last 6 months
      };

      const completedActions = Object.values(completionCriteria).filter(Boolean).length;
      const totalActions = Object.keys(completionCriteria).length;

      return Math.round((completedActions / totalActions) * 100);
    };

    setPercentage(calculateCompletion());
  }, [user]);

  

  const completedCount = Object.values(completedActions).filter(Boolean).length;
  const totalActions = ratingItems.length;
  const profileCompletion = Math.round((completedCount / totalActions) * 100);

  const userRatingTag = getUserRatingTag(profileCompletion);
  const progressMessage = getProgressMessage(profileCompletion);

  return (
    <div className="flex flex-col p-6 mt-10 w-full bg-white rounded-lg shadow-lg max-w-4xl mx-auto">
      {/* Header Section */}
      <div className="flex justify-between items-center border-b pb-4">
        <h2 className="text-2xl font-semibold text-gray-800">Freelancer Rating</h2>
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/83ebf207c5b832ff7ba4cb90dbd8747aa68d1de29385d7b52c7fd25c6f57fc0e?placeholderIfAbsent=true&apiKey=44862712bdb34f49aaf0b7f367088f08"
          alt="Rating Icon"
          className="object-contain w-6 h-6"
        />
      </div>

      {/* Rating Details */}
      <div className="mt-6 text-lg text-gray-700">
        <div className="flex justify-between items-center">
          <span className="font-medium text-gray-900">User Rating:</span>
          <div className="px-4 py-2 rounded-xl border-2 border-gray-300 bg-gray-100 text-gray-600">
            {userRatingTag}
          </div>
        </div>
        <p className="mt-4">{progressMessage}</p>
        <div className="mt-4">
          <span className="text-gray-900 font-semibold">Profile Completion: </span>
          <span className="text-blue-500">{percentage}%</span>
        </div>
      </div>

      {/* Rating Improvement Suggestions */}
      <div className="mt-8">
        <h3 className="text-lg font-semibold text-gray-800">Increase Your Freelancer Rating</h3>
        <p className="text-gray-600 mt-2">
          To improve your rating and access higher-rated jobs, complete the following:
        </p>

        {ratingItems.map((item, index) => (
          <div
            key={index}
            className={`flex items-center gap-4 px-5 py-3 mt-5 rounded-lg ${
              completedActions[item.key]
                ? "border border-green-500 bg-green-50 text-gray-900"
                : "border border-gray-300 bg-gray-100 text-gray-500"
            }`}
          >
            <img
              loading="lazy"
              src={item.icon}
              alt={item.text}
              className="w-8 h-8"
            />
            <span className="text-sm font-medium flex-grow">{item.text}</span>
            {completedActions[item.key] && (
              <span className="text-green-500">✔</span>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default FreelancerRating;
