import React from "react";

export default function LevelOverview() {
  return (
    <div
      className="p-6 bg-white border border-gray-300 rounded-2xl shadow-sm w-full"
      role="region"
      aria-label="Level overview"
    >
      {/* Title */}
      <div className="flex justify-between items-start mb-6">
        <h2 className="text-2xl font-bold text-gray-900">Level overview</h2>
        <span className="text-3xl font-bold text-gray-300">0</span>
      </div>

      {/* Content Grid */}
      <div className="grid grid-cols-2 gap-y-4 text-xl font-medium text-gray-900">
        {/* Left Labels */}
        <div>My Level</div>
        <div className="text-right">Newbie</div>

        <div>Ranking</div>
        <div className="text-right">-</div>

        <div>Response Rate</div>
        <div className="text-right">-</div>
      </div>
    </div>
  );
}
