import React from "react";
import { motion } from "framer-motion";
import mic from "../../asserts/mic.svg";

export default function FeatureCard({ title, description, buttonText, iconSrc }) {
  return (
    <motion.div
      whileHover={{ scale: 1.03 }}
      transition={{ duration: 0.3 }}
      className="flex flex-col bg-blue-600 text-white rounded-2xl shadow-lg p-6 h-full"
    >
      {/* Icon */}
      <div className="flex items-center justify-center w-24 h-24  rounded-full mb-4">
        <img
          src={mic} // Fallback for missing images
          alt={title}
          className="object-contain w-16 h-16"
        />
      </div>

      {/* Title */}
      <h3 className="text-2xl font-bold mb-2">{title}</h3>

      {/* Description */}
      <p className="text-sm leading-relaxed flex-grow">{description}</p>

      {/* Button */}
      <button className="bg-white text-blue-600 font-medium px-6 py-2 mt-4 rounded-lg hover:bg-gray-50 focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition">
        {buttonText}
      </button>
    </motion.div>
  );
}
