import React from 'react';
import { useNavigate } from 'react-router-dom';

const MessagesCard = ({ unreadMessages }) => {
  const navigate = useNavigate();

  console.log('unreadMessages:', unreadMessages);

  return (
    <div
      className="bg-white rounded-2xl border-2 border-solid border-black border-opacity-10 p-4 cursor-pointer hover:bg-gray-100 transition relative"
      onClick={() => navigate('/messages')}
    >
      <div className="flex justify-between items-center">
        <div className="flex items-center space-x-2">
          {/* Mail Icon */}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="2"
            stroke="currentColor"
            className="w-6 h-6 text-black"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M3 8l7.89 4.21a2 2 0 001.83 0L21 8m-18 0v8a2 2 0 002 2h14a2 2 0 002-2V8M3 8l8.93-5.36a2 2 0 011.87 0L21 8"
            />
          </svg>
          <h3 className="font-semibold text-lg">Inbox</h3>
        </div>
        {/* Trailing Counter */}
        {unreadMessages > 0 && (
          <div
            className={`flex items-center justify-center w-6 h-6 text-sm font-medium rounded-full bg-red-600 text-white animate-bounce`}
          >
            {unreadMessages}
          </div>
        )}
      </div>
      <p className="mt-2 text-sm text-gray-600">Access your messages here.</p>
      <style>
        {`
          @keyframes bounce {
            0%, 100% {
              transform: translateY(-2px);
            }
            50% {
              transform: translateY(2px);
            }
          }
        `}
      </style>
    </div>
  );
};

export default MessagesCard;
