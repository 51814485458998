import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../../context/AuthContext"; // Import AuthContext
import SetupHeader from "./SetuphHeader";
import Footer from "../Footer";
import image from "../../asserts/publish.svg";

export default function ServiceSuccessPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const { authState } = useAuth(); // Access username from AuthContext

  // Retrieve data passed from the previous page
  const {  serviceData } = location.state || {};

  if (!serviceData.serviceTitle) {
    alert("Missing required data. Redirecting to dashboard.");
    navigate("/dashboard");
    return null;
  }

  // Generate a shareable link dynamically using the username and serviceTitle
  const shareableLink = `https://www.earn365.tech/${serviceData.serviceTitle.replace(
    /\s+/g,
    "-"
  )}/edit?wizard=6&tab=publish`;

  const handleCopy = () => {
    navigator.clipboard.writeText(shareableLink);
    alert("Link copied to clipboard!");
  };

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col">
      <SetupHeader currentStep={6} />
      <main className="flex flex-col items-center justify-center flex-1 px-4 py-12">
        <div className="w-full max-w-2xl bg-white rounded-2xl shadow-lg border border-gray-200 text-center px-8 py-12">
          <div className="flex justify-end mb-4">
            <a href={"/serviceListing"} target="_blank" rel="noopener noreferrer">
              <button className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition">
                View public listing
              </button>
            </a>
          </div>
          <div className="flex justify-center mb-6">
            <img
              src={image}
              alt="Service is up"
              className="w-132 h-100 object-contain"
            />
          </div>
          <h1 className="text-3xl font-bold text-gray-800 mb-2">
            Your Service is up and running!
          </h1>
          <p className="text-lg text-gray-600 mb-6">
            Spread the word to boost your sales
          </p>
          <div className="flex items-center justify-center gap-2 mb-6">
            <input
              type="text"
              value={shareableLink}
              readOnly
              className="flex-1 border border-gray-300 rounded-md px-4 py-2 text-gray-600 w-full"
            />
            <button
              onClick={handleCopy}
              className="px-4 py-2 bg-blue-600 text-white font-medium rounded-md hover:bg-blue-700 transition"
            >
              Copy
            </button>
          </div>
          <div className="flex justify-between items-center">
            <button
              onClick={() =>
                navigate("/publishService", {
                  state: { serviceData },
                })
              }
              className="text-gray-600 hover:underline transition"
            >
              Back
            </button>
            <button
              onClick={() => navigate("/dashboard")}
              className="px-6 py-3 bg-black text-white font-medium rounded-full hover:bg-gray-800 transition"
            >
              Finish
            </button>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}
