import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme";
import { AuthProvider } from "./context/AuthContext";
import { ContentProvider } from "./context/ContentContext";
import { SocketProvider } from "./context/SocketContext";
import { MessageProvider } from "./context/MessageContext";

// Root element initialization with fallback error handling
const rootElement = document.getElementById("root");

if (!rootElement) {
  console.error("Root element not found. Ensure your HTML includes an element with id 'root'.");
  throw new Error("Root element not found");
}

const root = ReactDOM.createRoot(rootElement);

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <SocketProvider>
        
        <AuthProvider>
        <MessageProvider >
          <ContentProvider>
            <App />
          </ContentProvider>
        </MessageProvider>
        </AuthProvider>
      </SocketProvider>
    </ThemeProvider>
  </React.StrictMode>
);
