import React, { useState, useEffect } from 'react';

const timeZones = [
  { city: 'New York', zone: 'America/New_York' },
  { city: 'Paris', zone: 'Europe/Paris' },
  { city: 'Gaborone', zone: 'Africa/Gaborone' },
  { city: 'Cape Town', zone: 'Africa/Johannesburg' },
];

function Clock({ timeZone, city }) {
  const [time, setTime] = useState(new Date());

  useEffect(() => {
    const timer = setInterval(() => {
      setTime(new Date());
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  const date = new Date(time.toLocaleString('en-US', { timeZone }));

  return (
    <div className="backdrop-blur-lg bg-white/30 rounded-3xl p-6 flex flex-col items-center shadow-md">
      <div className="relative aspect-square max-w-[150px] w-full">
        <div className="relative w-full h-full rounded-full bg-white shadow-md">
          {/* Numbers */}
          {[...Array(12)].map((_, i) => {
            const angle = (i + 1) * 30; // Each hour is 30 degrees
            const x = 50 + 40 * Math.sin((angle * Math.PI) / 180); // X position
            const y = 50 - 40 * Math.cos((angle * Math.PI) / 180); // Y position
            return (
              <div
                key={i}
                className="absolute text-sm font-semibold text-black"
                style={{
                  top: `${y}%`,
                  left: `${x}%`,
                  transform: 'translate(-50%, -50%)',
                }}
              >
                {i + 1}
              </div>
            );
          })}

          {/* Tick marks */}
          {[...Array(60)].map((_, i) => {
            const rotation = `rotate(${i * 6}deg)`;
            const isMajor = i % 5 === 0;
            return (
              <div
                key={i}
                className="absolute w-full h-full"
                style={{ transform: rotation }}
              >
                <div
                  className={`absolute left-1/2 top-0 -translate-x-1/2 w-[1.5px] bg-gray-300 ${
                    isMajor ? 'h-[12px]' : 'h-[6px]'
                  }`}
                  style={{
                    opacity: isMajor ? 1 : 0.5,
                  }}
                />
              </div>
            );
          })}

          {/* Clock hands */}
          {['hours', 'minutes', 'seconds'].map((unit) => {
            const degrees =
              unit === 'hours'
                ? ((date.getHours() % 12) + date.getMinutes() / 60) * 30
                : unit === 'minutes'
                ? date.getMinutes() * 6
                : date.getSeconds() * 6;

            return (
              <div
                key={unit}
                className={`absolute left-1/2 top-1/2 origin-bottom ${
                  unit === 'hours'
                    ? 'h-[25%] w-[4px] bg-black rounded-full'
                    : unit === 'minutes'
                    ? 'h-[35%] w-[3px] bg-black rounded-full'
                    : 'h-[40%] w-[2px] bg-orange-400 rounded-full'
                }`}
                style={{
                  transform: `translate(-50%, -100%) rotate(${degrees}deg)`,
                }}
              />
            );
          })}

          {/* Center dot */}
          <div className="absolute left-1/2 top-1/2 w-3 h-3 bg-black rounded-full transform -translate-x-1/2 -translate-y-1/2" />
        </div>
      </div>
      <p className="mt-4 text-center text-lg font-semibold">{city}</p>
    </div>
  );
}

function AnalogClock() {
  return (
   
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 w-full max-w-5xl">
        {timeZones.map(({ city, zone }) => (
          <Clock key={city} timeZone={zone} city={city} />
        ))}
      
    </div>
  );
}

export default Clock;
