import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ChatSidebar from './ChatSidebar';
import ChatMessages from './ChatMessages';
import { useAuth } from '../../context/AuthContext';
import { collection, doc, setDoc, getDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../../config/firebase';

const generateChatId = (userId1, userId2) => {
  return [userId1, userId2].sort().join('_');
};

export default function MessageScreen() {
  const { authState } = useAuth();
  const { user, isAuthenticated } = authState;
  const { uid } = useParams(); // User ID from URL
  const [selectedChatId, setSelectedChatId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (uid && user?.uid) {
      const chatId = generateChatId(user.uid, uid);

      const fetchOrCreateChat = async () => {
        setIsLoading(true);
        setErrorMessage('');

        try {
          const chatDocRef = doc(db, 'messages', chatId);
          const chatDoc = await getDoc(chatDocRef);

          if (!chatDoc.exists()) {
            
            await setDoc(chatDocRef, {
              isArchived: false,
              participants: [user.uid, uid],
              recentMessage: {
                message: '',
                senderId: null,
                timestamp: null,
              },
              createdAt: serverTimestamp(),
            });
          }

          setSelectedChatId(chatId);
        } catch (error) {
          setErrorMessage('Unable to load chat. Please try again later.');
          console.error(error);
        } finally {
          setIsLoading(false);
        }
      };

      fetchOrCreateChat();
    }
  }, [uid, user?.uid]);

  if (!isAuthenticated || !user) {
    return (
      <div className="h-screen flex items-center justify-center">
        <p className="text-gray-600">Loading...</p>
      </div>
    );
  }

  return (
    <div className="flex h-screen bg-gray-50">
      <ChatSidebar currentUserId={user.uid} onSelectChat={setSelectedChatId} />
      {isLoading ? (
        <div className="flex-1 flex items-center justify-center text-gray-500">
          <p>Loading chat...</p>
        </div>
      ) : selectedChatId ? (
        <ChatMessages currentUserId={user.uid} chatId={selectedChatId} />
      ) : errorMessage ? (
        <div className="flex-1 flex items-center justify-center text-red-500">
          <p>{errorMessage}</p>
        </div>
      ) : (
        <div className="flex-1 flex items-center justify-center text-gray-500">
          <p>Select a chat to start messaging</p>
        </div>
      )}
    </div>
  );
}
