import * as React from "react";
import ProfileCard from "../components/Profile/ProfileCard";
import AccountSettings from "../components/Profile/AccountSetting";
import OrderHistory from "../components/Profile/OrderHistoryCard";
import PortfolioPreview from "../components/Profile/PortofolioPreviewCard";
import ReviewSection from "../components/Profile/ReviewsCard";
import Header from "../components/Home/SetuphHeader";
import Footer from "../components/Footer";

function Profile() {
  return (
    <div className="flex flex-col">
      <Header />
      <div className="flex flex-col px-6 lg:px-14 mt-4 max-md:px-4">
        {/* Profile and Account Settings */}
        <div className="flex gap-5 max-md:flex-col">
          <ProfileCard />
          <AccountSettings />
        </div>

        {/* Order History, Portfolio Preview, and Review Section */}
        <div className="flex flex-col self-end mt-12 w-full max-w-[998px] max-md:mt-10">
          <OrderHistory />
          <PortfolioPreview />
          <ReviewSection />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Profile;
