import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import SetupHeader from "./SetuphHeader";
import Footer from "../Footer";
import image from "../../asserts/publish.svg";
import { useContent } from "../../context/ContentContext";
import { useAuth } from "../../context/AuthContext";

export default function PublishServicePage() {
  const navigate = useNavigate();
  const location = useLocation();
  const { addService, progress } = useContent(); // Access the progress state from ContentContext
  const { authState } = useAuth();

  const { serviceData } = location.state || {};

  const [isLoading, setIsLoading] = useState(false);

  const handleSaveAndContinue = async () => {
    if (!serviceData) {
      alert("No service data available. Redirecting back to upload media.");
      navigate("/uploadMedia");
      return;
    }

    try {
      setIsLoading(true);

      const user = authState.user;
      serviceData.uid = user.uid;

      await addService(serviceData); // Trigger the addService function with progress tracking

      navigate("/serviceSuccess", {
        state: {
          username: serviceData.username || user.displayName || "User", // fallback
          serviceName: serviceData.serviceTitle,
          serviceData,
        },
      });
    } catch (error) {
      console.error("Failed to save service:", error);
      alert("An error occurred while saving your service. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col">
      <SetupHeader currentStep={6} />

      <main className="flex flex-1 items-center justify-center px-4 py-12">
        <div className="w-full max-w-3xl bg-white rounded-2xl shadow-lg border border-gray-200 text-center px-8 py-12">
          <div className="flex justify-center mb-8">
            <img
              src={image}
              alt="Almost there"
              className="w-148 h-100 object-contain"
            />
          </div>
          <h1 className="text-4xl font-bold text-gray-800 mb-4">Almost there</h1>
          <p className="text-lg text-gray-600">
            Let’s publish your service and get you ready to start selling.
          </p>

          {/* Progress Tracking */}
          {isLoading && progress && (
            <div className="mt-4 bg-gray-100 rounded-lg p-4">
              <p className="text-sm text-gray-700 font-medium">{progress}</p>
            </div>
          )}

          <div className="flex justify-between items-center mt-8">
            <button
              className="text-gray-600 hover:underline transition"
              onClick={() =>
                navigate("/uploadMedia", { state: { ...serviceData } })
              }
            >
              Back
            </button>

            <button
              onClick={handleSaveAndContinue}
              disabled={isLoading}
              className={`
                px-6 py-3 font-medium rounded-full transition-all 
                ${isLoading ? "bg-gray-400 cursor-not-allowed" : "bg-black text-white hover:bg-gray-800"}
              `}
            >
              {isLoading ? "Saving..." : "Save & Continue"}
            </button>
          </div>
        </div>
      </main>

      <Footer />
    </div>
  );
}
