import React, { createContext, useContext, useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import axios from "../api/axios";
import { useSocket } from "./SocketContext";

const MessageContext = createContext();

export const useMessageContext = () => {
  return useContext(MessageContext);
};

export const MessageProvider = ({ children }) => {
  const [chats, setChats] = useState([]);
  const [chatUsers, setChatUsers] = useState([]); // state to hold user details
  const [messages, setMessages] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);

  const socket = useSocket();

  /**
   * ---------------------
   *  Fetching Chat Lists
   * ---------------------
   */
  const fetchChats = useCallback(async () => {
    try {
      const response = await axios.get("/api/messages/chats");
      setChats(response.data);
      console.log("chat list", response.data);
    } catch (error) {
      console.error("Error fetching chats:", error);
    }
  }, []);

  /**
   * Fetch user details for participants (excluding `currentUserId`)
   */
  const fetchChatUsers = useCallback(
    async (currentUserId) => {
      try {
        // Collect all unique participant IDs that are not the current user
        const userIds = chats
          .map((chat) => chat.participants.find((p) => p !== currentUserId))
          .filter((id, index, self) => id && self.indexOf(id) === index);

        console.log("userids", userIds);
        if (userIds.length === 0) return;

        // For each userId, fetch from /api/auth/:userId
        const userDetails = await Promise.all(
          userIds.map(async (userId) => {
            const response = await axios.get(`/api/auth/${userId}`);
            // Suppose response.data = { name, avatarUrl, ... }
            return { userId, ...response.data };
          })
        );

        setChatUsers(userDetails);
      } catch (error) {
        console.error("Error fetching user details:", error);
      }
    },
    [chats]
  );

  /**
   * ---------------
   *  Fetch Messages
   * ---------------
   */
  const fetchMessages = async (chatId) => {
    try {
      const response = await axios.get(`/api/messages/messages/${chatId}`);
      setMessages(response.data.messages);
    } catch (error) {
      console.error("Error fetching messages:", error);
    }
  };

  /**
   * -------------
   *  User Search
   * -------------
   */
  const handleSearchUsers = async (query) => {
    try {
      const response = await axios.get(`/api/messages/search?query=${query}`);
      // server returns array of { uid, name, avatarUrl, ... } or similar
      const results = response.data.map((u) => ({
        userId: u.uid || u.userId || u.id,
        ...u,
      }));
      setSearchResults(results);
    } catch (error) {
      console.error("Error searching users:", error);
    }
  };

  /**
   * -------------
   *  Send Message
   * -------------
   */
  const sendMessage = async ({ chatId, senderId, receiverId, message }) => {
    try {
      const payload = { chatId, senderId, receiverId, message };
      const response = await axios.post("/api/messages/send", payload);

      // Update local state
      setMessages((prev) => [...prev, response.data]);

      // Emit message to socket (so other clients in that room get it in real-time)
      socket.emit("sendMessage", {
        chatId,
        senderId,
        receiverId,
        message,
      });
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  /**
   * ---------------
   *  Typing Indicator
   * ---------------
   */
  const handleTyping = (chatId, userId, isTyping) => {
    if (!chatId || !userId) return;
    if (isTyping) {
      socket.emit("typing", { chatId, userId });
    } else {
      socket.emit("stopTyping", { chatId, userId });
    }
  };

  /**
   * -----------------------------------
   *  Additional Chat Features (READ, DELETE, EDIT, REACT)
   * -----------------------------------
   */

  /**
   * Mark a message as read (read receipts)
   */
  const markMessageRead = async (chatId, messageTimestamp, userId) => {
    try {
      // 1) Update via REST (this calls your server route, e.g. POST /api/messages/:chatId/markRead)
      await axios.put(`/api/messages/${chatId}/markRead`, {
        messageTimestamp,
        userId,
      });

      // 2) Update local state
      setMessages((prev) =>
        prev.map((msg) => {
          // Compare timestamps if it's Firestore Timestamp => might do .isEqual()
          // If you store them as a string/number, compare directly:
          if (msg.timestamp === messageTimestamp) {
            return {
              ...msg,
              readBy: {
                ...(msg.readBy || {}),
                [userId]: true,
              },
            };
          }
          return msg;
        })
      );

      // 3) Emit socket event
      socket.emit("markMessageRead", {
        chatId,
        messageTimestamp,
        userId,
      });
    } catch (error) {
      console.error("Error marking message read:", error);
    }
  };

  /**
   * Delete a message
   */
  const deleteMessage = async (chatId, messageTimestamp) => {
    try {
      // 1) Call API
      await axios.delete(`/api/messages/${chatId}/${messageTimestamp}`);

      // 2) Update local state
      setMessages((prev) =>
        prev.filter((msg) => msg.timestamp !== messageTimestamp)
      );

      // 3) Emit socket event
      socket.emit("deleteMessage", { chatId, messageTimestamp });
    } catch (error) {
      console.error("Error deleting message:", error);
    }
  };

  /**
   * Edit a message (change text)
   */
  const editMessage = async (chatId, messageTimestamp, newContent) => {
    try {
      // 1) Update via API
      await axios.put(`/api/messages/${chatId}/${messageTimestamp}`, {
        newContent,
      });

      // 2) Update local state
      setMessages((prev) =>
        prev.map((msg) =>
          msg.timestamp === messageTimestamp
            ? { ...msg, message: newContent }
            : msg
        )
      );

      // 3) Emit socket event
      socket.emit("editMessage", {
        chatId,
        messageTimestamp,
        newContent,
      });
    } catch (error) {
      console.error("Error editing message:", error);
    }
  };

  /**
   * React to a message (e.g., add emoji reaction)
   */
  const reactToMessage = async (chatId, messageTimestamp, reaction, userId) => {
    try {
      // 1) Update via API
      await axios.post(`/api/messages/${chatId}/${messageTimestamp}/react`, {
        userId,
        reaction,
      });

      // 2) Update local state
      setMessages((prev) =>
        prev.map((msg) => {
          if (msg.timestamp === messageTimestamp) {
            return {
              ...msg,
              reactions: {
                ...(msg.reactions || {}),
                [userId]: reaction,
              },
            };
          }
          return msg;
        })
      );

      // 3) Emit socket event
      socket.emit("reactToMessage", {
        chatId,
        messageTimestamp,
        reaction,
        userId,
      });
    } catch (error) {
      console.error("Error reacting to message:", error);
    }
  };

  /**
   * ---------------------------
   *  Socket.io Event Listeners
   * ---------------------------
   */
  useEffect(() => {
    if (!socket) return;

    // When another client sends a message
    socket.on("receiveMessage", (newMessage) => {
      setMessages((prev) => [...prev, newMessage]);
    });

    // If a message is marked read by someone else
    socket.on("messageRead", ({ chatId, messageTimestamp, userId }) => {
      // Optionally check if `chatId` is the currently open chat
      setMessages((prev) =>
        prev.map((msg) => {
          if (msg.timestamp === messageTimestamp) {
            return {
              ...msg,
              readBy: {
                ...(msg.readBy || {}),
                [userId]: true,
              },
            };
          }
          return msg;
        })
      );
    });

    // If a message was deleted
    socket.on("messageDeleted", ({ chatId, messageTimestamp }) => {
      setMessages((prev) =>
        prev.filter((msg) => msg.timestamp !== messageTimestamp)
      );
    });

    // If a message was edited
    socket.on("messageEdited", ({ chatId, messageTimestamp, newContent }) => {
      setMessages((prev) =>
        prev.map((msg) =>
          msg.timestamp === messageTimestamp
            ? { ...msg, message: newContent }
            : msg
        )
      );
    });

    // If a message was reacted to
    socket.on("messageReacted", ({ chatId, messageTimestamp, reaction, userId }) => {
      setMessages((prev) =>
        prev.map((msg) => {
          if (msg.timestamp === messageTimestamp) {
            return {
              ...msg,
              reactions: {
                ...(msg.reactions || {}),
                [userId]: reaction,
              },
            };
          }
          return msg;
        })
      );
    });

    // Cleanup on unmount
    return () => {
      socket.off("receiveMessage");
      socket.off("messageRead");
      socket.off("messageDeleted");
      socket.off("messageEdited");
      socket.off("messageReacted");
    };
  }, [socket]);

  // Provide all context data & methods
  return (
    <MessageContext.Provider
      value={{
        chats,
        chatUsers,
        fetchChats,
        fetchChatUsers,
        messages,
        fetchMessages,
        searchResults,
        handleSearchUsers,
        sendMessage,
        handleTyping,

        // Additional chat features
        markMessageRead,
        deleteMessage,
        editMessage,
        reactToMessage,

        selectedUser,
        setSelectedUser,
      }}
    >
      {children}
    </MessageContext.Provider>
  );
};

MessageProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
