export const categories = [
    "Graphic Design",
    "Digital Marketing", 
    "Writing & Translation",
    "Video & Animation",
    "Photography",
    "AI Services",
    "Lifestyle",
    "Music & Audio",
    "Business & Finance",
  ];
  
  export const clientLinks = [
    "How Earn works",
    "Trust & Safety",
    "Quality Guide", 
    "Support",
    "Testimonials"
  ];
  
  export const freelancerLinks = [
    "Become a Freelancer",
    "Community Hub",
    "Forum",
    "Support",
    "Events"
  ];
  
  export const companyLinks = [
    "About Earn",
    "Help and Support",
    "Careers",
    "Privacy Policy", 
    "Affiliates",
    "Press & News"
  ];