import React from "react";
import NavigationTabs from "./NavigationTabs";
import values from "../../asserts/teams.svg";

function Team() {
  return (
    <div className="flex flex-col items-center rounded-none">
      <div className="p-4 md:p-8 w-full">
        <img
          loading="lazy"
          src={values}
          alt="About Us Banner"
          className="object-contain w-full rounded-lg"
        />
      </div>
      <NavigationTabs />
    </div>
  );
}

export default Team;
