import React, { useState } from "react";
import { useAuth } from "../../context/AuthContext"; // Import AuthContext

function ContactInfo() {
  const { authState, updateUserProfile } = useAuth(); // Access AuthContext
  const user = authState.user; // Extract user details

  const [editing, setEditing] = useState(false); // Track edit mode
  const [fullName, setFullName] = useState(user?.name || ""); // Full name
  const [phone, setPhone] = useState(user?.phone || ""); // Phone number
  const [address, setAddress] = useState(user?.address || ""); // Address
  const [email, setEmail] = useState(user?.email || ""); // Email
  const [saving, setSaving] = useState(false); // Track saving state

  const handleSave = async (e) => {
    e.preventDefault();
    setSaving(true);

    try {
      await updateUserProfile({ name: fullName, phone, address, email }); // Update contact info
      alert("Contact information updated successfully!");
      setEditing(false); // Exit edit mode
    } catch (error) {
      console.error("Failed to update contact information:", error);
      alert("Failed to update contact information. Please try again.");
    } finally {
      setSaving(false);
    }
  };

  return (
    <div className="flex flex-col p-6 mt-12 w-full bg-white rounded-lg shadow-lg max-w-4xl mx-auto">
      {/* Header Section */}
      <div className="flex justify-between items-center border-b pb-4">
        <h2 className="text-2xl font-semibold text-gray-800">Contact Info</h2>
        <button
          onClick={() => setEditing(!editing)}
          className="text-blue-500 underline hover:text-blue-600 transition duration-200"
        >
          {editing ? "Cancel" : "Edit"}
        </button>
      </div>

      {/* Content Section */}
      {editing ? (
        <form
          onSubmit={handleSave}
          className="flex flex-col mt-6 space-y-6 text-lg"
        >
          {/* Full Name Field */}
          <div className="flex flex-col">
            <label htmlFor="fullName" className="text-gray-700 font-medium mb-2">
              Full Name
            </label>
            <input
              id="fullName"
              type="text"
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
              className="px-4 py-3 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>

          {/* Phone Number Field */}
          <div className="flex flex-col">
            <label htmlFor="phone" className="text-gray-700 font-medium mb-2">
              Phone Number
            </label>
            <input
              id="phone"
              type="text"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              className="px-4 py-3 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>

          {/* Address Field */}
          <div className="flex flex-col">
            <label htmlFor="address" className="text-gray-700 font-medium mb-2">
              Address
            </label>
            <input
              id="address"
              type="text"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              className="px-4 py-3 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>

          {/* Email Field */}
          <div className="flex flex-col">
            <label htmlFor="email" className="text-gray-700 font-medium mb-2">
              Email
            </label>
            <input
              id="email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="px-4 py-3 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>

          {/* Save Button */}
          <button
            type="submit"
            className="px-6 py-3 text-white bg-blue-500 rounded-lg hover:bg-blue-600 transition duration-200 self-start"
            disabled={saving}
          >
            {saving ? "Saving..." : "Save Changes"}
          </button>
        </form>
      ) : (
        <div className="mt-6 space-y-6 text-lg">
          {/* Display Full Name */}
          <div className="flex flex-col">
            <span className="text-gray-700 font-medium">Full Name</span>
            <span className="text-gray-800 mt-1">{user?.name || "N/A"}</span>
          </div>

          {/* Display Phone Number */}
          <div className="flex flex-col">
            <span className="text-gray-700 font-medium">Phone Number</span>
            <span className="text-gray-800 mt-1">{user?.phone || "N/A"}</span>
          </div>

          {/* Display Address */}
          <div className="flex flex-col">
            <span className="text-gray-700 font-medium">Address</span>
            <span className="text-gray-800 mt-1">{user?.address || "N/A"}</span>
          </div>

          {/* Display Email */}
          <div className="flex flex-col">
            <span className="text-gray-700 font-medium">Email</span>
            <span className="text-gray-800 mt-1">{user?.email || "N/A"}</span>
          </div>
        </div>
      )}
    </div>
  );
}

export default ContactInfo;
