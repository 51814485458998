import React from "react";

export default function SellerTips() {
  return (
    <section className="mt-9" role="region" aria-labelledby="seller-tips-title">
      <h2 id="seller-tips-title" className="text-2xl font-bold text-neutral-900">
        How to become a top seller on Earn
      </h2>
      <p className="mt-3.5 text-xl font-medium text-neutral-900 max-md:mr-1 max-md:max-w-full">
        At Earn, we believe that talent deserves opportunity and effort deserves reward. 
        Showcase your expertise, connect with quality clients, and access the tools and resources 
        you need to succeed in the freelance world. Build your reputation and unlock your full potential.
      </p>
    </section>
  );
}
