import React, { useEffect, useState } from "react";
import { useAuth } from "../../context/AuthContext"; // Import AuthContext

function PortfolioPreview() {
  const { authState, fetchUserPortfolio } = useAuth(); // Access portfolio via AuthContext
  const [portfolio, setPortfolio] = useState([]); // State for portfolio items
  const [loading, setLoading] = useState(true); // Loading state

  // Fetch portfolio items on component mount
  useEffect(() => {
    const loadPortfolio = async () => {
      try {
        setLoading(true);
        const userPortfolio = await fetchUserPortfolio();
        setPortfolio(userPortfolio || []);
      } catch (error) {
        console.error("Failed to load portfolio:", error);
      } finally {
        setLoading(false);
      }
    };

    loadPortfolio();
  }, [fetchUserPortfolio]);

  return (
    <div className="flex flex-col pt-7 pb-10 mt-12 w-full bg-white rounded-3xl border border-solid border-black border-opacity-20 max-md:max-w-full">
      {/* Header Section */}
      <div className="flex justify-between items-center px-12 max-md:px-6">
        <h2 className="text-2xl font-semibold text-black">Portfolio Preview</h2>
        <button className="text-blue-500 underline hover:text-blue-600 transition">
          See all
        </button>
      </div>
      <div className="flex shrink-0 mt-4 h-0.5 bg-black bg-opacity-10 max-md:max-w-full" />

      {/* Portfolio Items */}
      <div className="flex flex-wrap gap-6 px-12 mt-6 max-md:px-6">
        {loading ? (
          <div className="text-gray-500 text-lg">Loading portfolio...</div>
        ) : portfolio.length > 0 ? (
          portfolio.slice(0, 3).map((item, index) => (
            <div
              key={index}
              className="flex flex-col w-[250px] bg-white rounded-2xl border border-gray-300 shadow hover:shadow-lg transition duration-200"
            >
              <img
                src={item.imageUrl || "https://via.placeholder.com/250"}
                alt={item.title || "Portfolio Item"}
                className="object-cover w-full h-[150px] rounded-t-2xl"
              />
              <div className="flex flex-col p-4">
                <h3 className="text-lg font-semibold text-gray-800 truncate">
                  {item.title || "Untitled"}
                </h3>
                <p className="mt-2 text-sm text-gray-600 line-clamp-3">
                  {item.description || "No description available."}
                </p>
                {item.link && (
                  <a
                    href={item.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="mt-3 text-blue-500 hover:underline text-sm"
                  >
                    View Project
                  </a>
                )}
              </div>
            </div>
          ))
        ) : (
          <div className="text-gray-500 text-lg">
            No portfolio items found. Add projects to showcase your work.
          </div>
        )}
      </div>
    </div>
  );
}

export default PortfolioPreview;
