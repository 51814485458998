import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../config/firebase";
import Footer from "../Footer";
import MarketplaceHeader from "../Dashboard/MarketplaceHeader";

// Import Swiper components and styles
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/effect-coverflow";

// Import Swiper modules
import { Navigation, Pagination, EffectCoverflow } from "swiper/modules";
import Modal from "react-modal";

export default function ServiceDetailPage() {
  const { serviceId } = useParams();
  const [service, setService] = useState(null);
  const [user, setUser] = useState(null);
  const [modalImage, setModalImage] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState("All");
  

  useEffect(() => {
    const fetchServiceDetails = async () => {
      try {
        const serviceRef = doc(db, "services", serviceId);
        const serviceDoc = await getDoc(serviceRef);
        if (serviceDoc.exists()) {
          setService({ id: serviceDoc.id, ...serviceDoc.data() });

          // Fetch user details
          const userRef = doc(db, "users", serviceDoc.data().uid);
          const userDoc = await getDoc(userRef);
          if (userDoc.exists()) {
            setUser(userDoc.data());
          }
        } else {
          console.error("Service not found!");
        }
      } catch (error) {
        console.error("Error fetching service details:", error);
      }
    };

    fetchServiceDetails();
  }, [serviceId]);

  if (!service) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gradient-to-r from-blue-50 to-blue-100">
        <p className="text-lg font-semibold text-blue-600">Loading service details...</p>
      </div>
    );
  }

  return (
    <div className="bg-gray-50 min-h-screen">
      <MarketplaceHeader />

      {/* Hero Header */}
      <header className="bg-gradient-to-r from-blue-600 to-purple-600 text-white p-10 text-center shadow-lg">
        <h1 className="text-3xl font-bold">{service.serviceTitle}</h1>
        <p className="text-lg mt-2">
          <span className="font-medium">{service.category}</span> -{" "}
          <span className="italic">{service.subCategory}</span>
        </p>
      </header>

      {/* Content */}
      <main className="mx-auto p-8 max-w-6xl space-y-12">
        {/* Fancy User Profile Section */}
        {user && (
          <section className="bg-gradient-to-r from-purple-100 to-blue-100 p-6 rounded-xl shadow-xl flex items-center space-x-6">
            <img
              src={user.avatarUrl}
              alt={user.name}
              className="w-24 h-24 rounded-full border-4 border-blue-400 shadow-lg"
            />
            <div className="flex-1">
              <h2 className="text-2xl font-bold text-blue-700">{user.name}</h2>
              <p className="text-gray-600 mt-2">
                <span className="font-medium">{user.role}</span>{" "}
                {user.verified && <span className="text-green-500 ml-2">✔️ Verified</span>}
              </p>
              <p className="text-gray-600">{user.location}</p>
              <p className="text-gray-600 mt-1">
                Profile Completion:{" "}
                <span className="text-blue-700 font-medium">
                  {user.profileCompletion}%
                </span>
              </p>
            </div>
            <button
              className="px-6 py-2 bg-blue-600 text-white rounded-full shadow-lg hover:bg-blue-700 transform hover:scale-105 transition duration-300"
              onClick={() => alert(`Message sent to ${user.name}`)}
            >
              Send Message
            </button>
          </section>
        )}

        {/* Image Carousel */}
        <section>
          <h2 className="text-3xl font-bold mb-6 text-center text-gray-800">Gallery</h2>
          <Swiper
            modules={[Navigation, Pagination, EffectCoverflow]}
            navigation
            pagination={{ clickable: true }}
            effect="coverflow"
            centeredSlides
            slidesPerView="auto"
            coverflowEffect={{
              rotate: 50,
              stretch: 0,
              depth: 100,
              modifier: 1,
              slideShadows: true,
            }}
            className="w-full h-96"
          >
            {service.media?.images.map((image, index) => (
              <SwiperSlide key={index}>
                <img
                  src={image}
                  alt={`Service Image ${index}`}
                  className="w-full h-full object-cover rounded-lg cursor-pointer shadow-lg"
                  onClick={() => setModalImage(image)}
                />
              </SwiperSlide>
            ))}
          </Swiper>

          {/* Image Modal */}
          <Modal
            isOpen={!!modalImage}
            onRequestClose={() => setModalImage(null)}
            overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
            className="bg-white rounded-lg p-6 shadow-lg max-w-3xl w-full"
          >
            <button
              className="absolute top-4 right-4 text-gray-700 hover:text-gray-900 text-2xl"
              onClick={() => setModalImage(null)}
            >
              ✖
            </button>
            {modalImage && (
              <img
                src={modalImage}
                alt="Enlarged"
                className="max-w-full max-h-screen mx-auto rounded-lg"
              />
            )}
          </Modal>
        </section>

        {/* Documents Section */}
        <section>
          <h2 className="text-3xl font-bold mb-6 text-center text-gray-800">Documents</h2>
          <div className="grid grid-cols-3 gap-6">
            {service.media?.documents.map((doc, index) => (
              <a
                key={index}
                href={doc}
                target="_blank"
                rel="noopener noreferrer"
                className="bg-white p-6 rounded-lg shadow-lg hover:shadow-2xl transform hover:scale-105 transition duration-300"
              >
                <img
                  src="https://via.placeholder.com/150x200?text=PDF"
                  alt={`PDF ${index + 1}`}
                  className="w-16 h-20 mx-auto mb-4"
                />
                <p className="text-center text-blue-600 font-medium">
                  View Document {index + 1}
                </p>
              </a>
            ))}
          </div>
        </section>

        {/* FAQs Section */}
        {service.faqs && service.faqs.length > 0 && (
          <section>
            <h2 className="text-3xl font-bold mb-6 text-center text-gray-800">FAQs</h2>
            <div className="space-y-4">
              {service.faqs.map((faq, index) => (
                <div
                  key={index}
                  className="bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition"
                >
                  <h3 className="text-xl font-bold">{faq.question}</h3>
                  <p className="text-gray-600 mt-2">{faq.answer}</p>
                </div>
              ))}
            </div>
          </section>
        )}

        {/* Service Milestones */}
        {service.milestones && (
          <section>
            <h2 className="text-3xl font-bold mb-6 text-center text-gray-800">Milestones</h2>
            <ul className="space-y-4">
              {service.milestones.map((milestone, index) => (
                <li
                  key={index}
                  className="bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition"
                >
                  <h3 className="text-xl font-semibold">{milestone.name}</h3>
                  <p className="mt-2 text-gray-600">
                    Delivery: {milestone.deliveryTime.quantity} {milestone.deliveryTime.unit}
                  </p>
                  <p className="mt-1 text-gray-600">Fee: ${milestone.fee}</p>
                </li>
              ))}
            </ul>
          </section>
        )}

        {/* Service Packages */}
        <section>
          <h2 className="text-3xl font-bold mb-6 text-center text-gray-800">Packages</h2>
          <div className="space-y-6">
            {Object.entries(service.packages || {})
              .filter(([_, pkg]) => pkg.isActive)
              .map(([packageName, pkg], index) => (
                <div
                  key={index}
                  className="bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition"
                >
                  <h3 className="text-xl font-bold text-blue-600">
                    {packageName} Package
                  </h3>
                  <p className="text-gray-600 mt-2">{pkg.description}</p>
                  <p className="text-gray-800 font-semibold mt-1">Price: ${pkg.price}</p>
                  <p className="text-gray-600">
                    Delivery Time: {pkg.deliveryTime.quantity} {pkg.deliveryTime.unit}
                  </p>
                  <p className="text-gray-600">Revisions: {pkg.revisions}</p>
                </div>
              ))}
          </div>
        </section>
      </main>

      {/* Footer */}
      <Footer />
    </div>
  );
}
