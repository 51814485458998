import React, { useState } from "react";
import SetupHeader from "./SetuphHeader";
import Footer from "../Footer";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import { useContent } from "../../context/ContentContext";

export default function CreateServicePage() {
  const { authState } = useAuth();
  const { searchQuery, updateSearchQuery } = useContent(); // Use ContentContext
  const navigate = useNavigate();

  const [serviceTitle, setServiceTitle] = useState("");
  const [category, setCategory] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [tags, setTags] = useState([]);
  const [description, setDescription] = useState(""); // New description field

  // Categories and Subcategories from the document
  const categories = {
    "Graphic Design": [
      "Logo design",
      "Brand identity creation",
      "Illustration",
      "Book covers and album art",
      "Packaging design",
      "T-shirt design",
      "Social media design",
    ],
    "Digital Marketing": [
      "Social media management",
      "SEO (Search Engine Optimization)",
      "Content marketing",
      "Email marketing",
      "Influencer marketing",
      "Paid advertising (Google Ads, Facebook Ads)",
    ],
    "Writing & Translation": [
      "Copywriting",
      "Blog writing",
      "Technical writing",
      "Resume and cover letter creation",
      "Creative writing",
      "Translation services",
      "Proofreading and editing",
    ],
    "Video & Animation": [
      "Video editing",
      "Explainer videos",
      "Whiteboard animations",
      "3D product animation",
      "Subtitles and captions",
      "Visual effects",
    ],
    "Music & Audio": [
      "Voiceover services",
      "Mixing and mastering",
      "Podcast editing",
      "Custom jingles and music composition",
      "Audiobook narration",
    ],
    "Programming & Tech": [
      "Website development",
      "App development",
      "WordPress customization",
      "E-commerce development",
      "IT support",
      "Cybersecurity services",
    ],
    "Business Services": [
      "Virtual assistance",
      "Business consulting",
      "Project management",
      "Data entry",
      "Market research",
      "Financial consulting",
      "Legal consulting",
    ],
    "Lifestyle": [
      "Relationship advice",
      "Life coaching",
      "Fitness coaching",
      "Online tutoring",
    ],
    "AI Services": [
      "Chatbot development",
      "AI-powered tools and solutions",
      "Data labeling and processing",
    ],
    "Photography": [
      "Photo editing and retouching",
      "Product photography",
      "Lifestyle photography",
      "Real estate photography (remote editing)",
    ],
    "Business & Finance": [
      "Accounting services",
      "Tax preparation and filing",
      "Investment consulting",
      "Financial planning",
      "Payroll management",
      "Budgeting and forecasting",
      "Business strategy development",
    ],
  };
  

  const handleAddTag = (tag) => {
    if (tags.length < 5 && tag.trim() && !tags.includes(tag)) {
      setTags([...tags, tag]);
    }
  };

  const handleRemoveTag = (tag) => {
    setTags(tags.filter((t) => t !== tag));
  };

  const handleSaveAndContinue = () => {
    if (!serviceTitle || !category || !subCategory || !description.trim()) {
      alert("Please complete all required fields before continuing.");
      return;
    }

    // Navigate to the next screen with state
    navigate("/pricing", {
      state: {
        serviceTitle,
        category,
        subCategory,
        tags,
        description, // Include description
      },
    });
  };

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Setup Header with Step Highlighted */}
      <SetupHeader currentStep={1} />

      {/* Page Content */}
      <main className="p-6 md:p-10 my-20 max-w-4xl mx-auto bg-white rounded-2xl shadow-md border border-gray-200">
        <div className="space-y-6">
          {/* Service Title */}
          <div>
            <label className="block text-lg font-semibold text-gray-800 mb-2">
              Service Title
            </label>
            <p className="text-sm text-gray-500 mb-3">
              Choose a title that best describes your service.
            </p>
            <input
              type="text"
              className="w-full border border-gray-300 rounded-lg px-4 py-3 focus:ring-2 focus:ring-blue-500 focus:outline-none"
              placeholder="Enter your service title"
              value={serviceTitle}
              onChange={(e) => setServiceTitle(e.target.value)}
            />
          </div>

          {/* Category */}
          <div>
            <label className="block text-lg font-semibold text-gray-800 mb-2">
              Category
            </label>
            <p className="text-sm text-gray-500 mb-3">
              Choose the category and sub-category most suitable for your Gig.
            </p>
            <div className="flex gap-4">
              <select
                className="w-1/2 border border-gray-300 rounded-lg px-4 py-3 focus:ring-2 focus:ring-blue-500 focus:outline-none"
                value={category}
                onChange={(e) => {
                  setCategory(e.target.value);
                  setSubCategory(""); // Reset subcategory when category changes
                }}
              >
                <option value="">Select Category</option>
                {Object.keys(categories).map((cat, index) => (
                  <option key={index} value={cat}>
                    {cat}
                  </option>
                ))}
              </select>
              <select
                className="w-1/2 border border-gray-300 rounded-lg px-4 py-3 focus:ring-2 focus:ring-blue-500 focus:outline-none"
                value={subCategory}
                onChange={(e) => setSubCategory(e.target.value)}
              >
                <option value="">Select Sub-Category</option>
                {category &&
                  categories[category].map((subCat, index) => (
                    <option key={index} value={subCat}>
                      {subCat}
                    </option>
                  ))}
              </select>
            </div>
          </div>

          {/* Description */}
          <div>
            <label className="block text-lg font-semibold text-gray-800 mb-2">
              Description
            </label>
            <p className="text-sm text-gray-500 mb-3">
              Provide a detailed description of the service you are offering.
            </p>
            <textarea
              className="w-full border border-gray-300 rounded-lg px-4 py-3 focus:ring-2 focus:ring-blue-500 focus:outline-none"
              placeholder="Write a detailed description of your service..."
              rows={6}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>

          {/* Search Tags */}
          <div>
            <label className="block text-lg font-semibold text-gray-800 mb-2">
              Search Tags
            </label>
            <p className="text-sm text-gray-500 mb-3">
              Add relevant keywords to help users find your service. You can add up to 5 tags.
            </p>
            <input
              type="text"
              className="w-full border border-gray-300 rounded-lg px-4 py-3 focus:ring-2 focus:ring-blue-500 focus:outline-none"
              placeholder="Add a search tag"
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  handleAddTag(e.target.value);
                  e.target.value = "";
                }
              }}
            />
            {/* Tag List */}
            <div className="flex flex-wrap gap-3 mt-4">
              {tags.map((tag, index) => (
                <div
                  key={index}
                  className="flex items-center space-x-2 px-3 py-1 bg-gray-200 text-gray-700 rounded-full text-sm"
                >
                  <span>{tag}</span>
                  <button
                    aria-label={`Remove ${tag}`}
                    className="text-gray-500 hover:text-gray-700"
                    onClick={() => handleRemoveTag(tag)}
                  >
                    ✕
                  </button>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Buttons */}
        <div className="flex justify-between items-center mt-8">
          <button
            className="text-gray-500 hover:text-gray-700 text-sm font-medium"
            onClick={() => navigate("/previous-page")} // Adjust this path
          >
            Back
          </button>
          <button
            onClick={handleSaveAndContinue}
            className="bg-blue-600 text-white px-6 py-3 rounded-lg font-medium hover:bg-blue-700 transition"
          >
            Save & Continue
          </button>
        </div>
      </main>

      {/* Footer */}
      <Footer />
    </div>
  );
}
