import React, { createContext, useContext, useEffect, useState } from "react";
import { io } from "socket.io-client";

const SocketContext = createContext();

export const useSocket = () => {
  return useContext(SocketContext);
};

export const SocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    // 1) Optional: Retrieve a token if you have authenticated users
    const token = localStorage.getItem("authToken"); // or from another source

    // 2) Create the socket connection with optional auth & reconnection settings
    const newSocket = io(process.env.REACT_APP_SOCKET_URL || "http://localhost:3000", {
      withCredentials: true, // If you need credentials/cookies
      auth: { token }, // Pass token (or any other data) to the server in handshake if needed
      reconnectionAttempts: 5, // try to reconnect up to 5 times
      reconnectionDelay: 5000, // wait 5s between reconnection attempts
    });

    // Log all events received by the socket
    newSocket.onAny((eventName, ...args) => {
      console.log(`Socket Event: ${eventName}`, args);
    });

    // Log errors or disconnect events for better debugging
    newSocket.on("connect_error", (err) => {
      console.error("Socket connection error:", err);
    });

    newSocket.on("disconnect", (reason) => {
      console.warn(`Socket disconnected: ${reason}`);
    });

    setSocket(newSocket);

    // 3) Cleanup when component unmounts
    return () => {
      newSocket.close();
    };
  }, []);

  return <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>;
};
