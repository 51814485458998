import React from 'react';
import image from "../../asserts/gr.svg";

function ImageGallery() {
  return (
    <div className="flex flex-wrap gap-5 items-center mt-16 w-full max-w-[1118px] max-md:mt-10 max-md:max-w-full">
      
        <img
         
          loading="lazy"
          src={image}
          className={image.className}
        />
    
    </div>
  );
}

export default ImageGallery;