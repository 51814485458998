import * as React from "react";
import LoginModal from "../../Login/LoginModal"; // Import the LoginModal component

function MyComponent() {
  const [isLoginModalOpen, setIsLoginModalOpen] = React.useState(false);

  const handleClick = () => {
    // Toggle the modal when the button is clicked
    setIsLoginModalOpen(true);
  };

  const closeModal = () => {
    // Close the modal
    setIsLoginModalOpen(false);
  };

  return (
    <div className="flex flex-col items-center text-center px-8">
      <div className="flex flex-col justify-center items-center w-full px-10 py-24 bg-black rounded-[40px] max-w-[1164px] shadow-lg md:px-16 md:py-36">
        <TextBlock text="At Earn, we’re more than a platform—we’re a partner in your success. Whether you're building your career or growing your business, choose Earn and experience freelancing the way it’s meant to be." />
        <Button text="Work with us" onClick={handleClick} />
      </div>

      {/* Render the LoginModal */}
      <LoginModal isOpen={isLoginModalOpen} onClose={closeModal} />
    </div>
  );
}

function Button({ text, onClick }) {
  return (
    <button
      onClick={onClick}
      className="px-8 py-4 mt-10 text-lg font-medium text-black bg-white rounded-[59px] shadow-md hover:bg-gray-200 transition-all md:px-12 md:py-5 md:text-xl"
    >
      {text}
    </button>
  );
}

function TextBlock({ text }) {
  return (
    <div className="text-2xl font-semibold text-white leading-8 md:text-4xl md:leading-[72px]">
      {text}
    </div>
  );
}

export default MyComponent;
