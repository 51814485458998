// Import required Firebase SDK functions from the modular Firebase v9+ syntax
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAuth, GoogleAuthProvider } from "firebase/auth"; // Import Firebase Auth

// Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyA8sGRffilaczR9c4AA4O1Ij437d6Ubpy8",
  authDomain: "earn-5f1b1.firebaseapp.com",
  projectId: "earn-5f1b1",
  storageBucket: "earn-5f1b1.firebasestorage.app",
  messagingSenderId: "585380640497",
  appId: "1:585380640497:web:317666ebef9aa2db86db50"
};

// Initialize Firebase app
const app = initializeApp(firebaseConfig);

// Initialize Firestore, Firebase Storage, and Firebase Auth
const db = getFirestore(app);
const storage = getStorage(app);
const auth = getAuth(app); // Initialize Firebase Authentication
const googleProvider = new GoogleAuthProvider(); // Initialize Google Auth Provider

// Export the instances for use throughout the app
export { db, storage, auth, googleProvider };
