import React from 'react';

export const ChatMessage = ({ message }) => {
  const isCurrentUser = message.sender === message.currentUserId;

  return (
    <div className={`mb-4 ${isCurrentUser ? 'text-right' : 'text-left'}`}>
      <div
        className={`inline-block p-2 rounded ${
          isCurrentUser ? 'bg-blue-500 text-white' : 'bg-gray-200 text-black'
        }`}
      >
        {/* Render Text */}
        {message.text && <p>{message.text}</p>}

        {/* Render Media */}
        {message.mediaUrl && (
          <>
            {message.mediaType?.startsWith('image/') && (
              <img
                src={message.mediaUrl}
                alt="Shared media"
                className="mt-2 rounded max-w-[200px]"
              />
            )}
            {message.mediaType?.startsWith('video/') && (
              <video
                controls
                src={message.mediaUrl}
                className="mt-2 rounded max-w-[200px]"
              />
            )}
          </>
        )}
      </div>
      <div className="text-xs mt-1 text-gray-500">
        {new Date(message.timestamp?.toDate()).toLocaleTimeString()}
      </div>
    </div>
  );
};
