import React from "react";

function NavigationTabs({ tabs, activeTab, setActiveTab }) {
  if (!tabs || !Array.isArray(tabs)) {
    console.error("Tabs prop is missing or not an array.");
    return null;
  }

  return (
    <div className="flex gap-10 items-center mt-8 max-w-full text-xl font-medium text-center text-black w-[600px]">
      {tabs.map((tab, index) => (
        <div
          key={index}
          role="tab"
          tabIndex={0}
          className={`px-7 py-3 bg-opacity-30 rounded-[84px] shadow-[0px_3px_39px_rgba(0,0,0,0.25)] ${
            activeTab === tab.key ? "bg-slate-200 font-bold" : "hover:bg-gray-100"
          }`}
          onClick={() => setActiveTab(tab.key)}
        >
          {tab.label}
        </div>
      ))}
    </div>
  );
}

export default NavigationTabs;
