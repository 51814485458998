import React, { useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { storage } from "../../config/firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import SetupHeader from "./SetuphHeader";
import Footer from "../Footer";

export default function UploadMediaPage() {
  const navigate = useNavigate();
  const location = useLocation();

  const [images, setImages] = useState([]);
  const [video, setVideo] = useState(null);
  const [documents, setDocuments] = useState([]);

  const imageInputRef = useRef(null);
  const videoInputRef = useRef(null);
  const documentInputRef = useRef(null);

  const { serviceTitle, category, subCategory, packages, milestones, faqs, description } =
    location.state || {};

  // Redirect if required data is missing
  if (!serviceTitle || !category || !subCategory || !packages || !milestones || !faqs || !description) {
    alert("Required data is missing. Redirecting...");
    navigate("/faq");
    return null;
  }

  // Upload file to Firebase Storage
  const uploadFile = (file, folder, onProgress) => {
    const fileRef = ref(storage, `${folder}/${file.name}`);
    const uploadTask = uploadBytesResumable(fileRef, file);

    return new Promise((resolve, reject) => {
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          if (onProgress) onProgress(progress);
        },
        (error) => reject(error),
        async () => {
          const url = await getDownloadURL(uploadTask.snapshot.ref);
          resolve(url);
        }
      );
    });
  };

  const handleImageUpload = async (e) => {
    const files = Array.from(e.target.files).slice(0, 8 - images.length); // Limit to 8
  
    files.forEach((file) => {
      const localPreview = URL.createObjectURL(file);
      const newImage = { file, localPreview, progress: 0, url: null, state: "uploading" };
  
      setImages((prevImages) => [...prevImages, newImage]); // Add new file to the state
  
      uploadFile(file, "images", (progress) => {
        setImages((prevImages) =>
          prevImages.map((img) =>
            img.file === file ? { ...img, progress } : img
          )
        );
      })
        .then((url) => {
          setImages((prevImages) =>
            prevImages.map((img) =>
              img.file === file ? { ...img, url, state: "finished" } : img
            )
          );
        })
        .catch(() => {
          setImages((prevImages) =>
            prevImages.map((img) =>
              img.file === file ? { ...img, state: "error" } : img
            )
          );
        });
    });
  };
  

  // Handle Video Upload
  const handleVideoUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const localPreview = URL.createObjectURL(file);
      const newVideo = { file, localPreview, progress: 0, url: null, state: "uploading" };
      setVideo(newVideo); // Add the new video to the state
  
      uploadFile(file, "videos", (progress) => {
        setVideo((prevVideo) =>
          prevVideo && prevVideo.file === file ? { ...prevVideo, progress } : prevVideo
        );
      })
        .then((url) => {
          setVideo((prevVideo) =>
            prevVideo && prevVideo.file === file
              ? { ...prevVideo, url, state: "finished" }
              : prevVideo
          );
        })
        .catch(() => {
          setVideo((prevVideo) =>
            prevVideo && prevVideo.file === file
              ? { ...prevVideo, state: "error" }
              : prevVideo
          );
        });
    }
  };
  

  // Handle Document Upload
  const handleDocumentUpload = async (e) => {
    const files = Array.from(e.target.files).slice(0, 3 - documents.length); // Limit to 3
  
    files.forEach((file) => {
      const newDocument = { file, progress: 0, url: null, state: "uploading" };
  
      setDocuments((prevDocuments) => [...prevDocuments, newDocument]); // Add new document to the state
  
      uploadFile(file, "documents", (progress) => {
        setDocuments((prevDocuments) =>
          prevDocuments.map((doc) =>
            doc.file === file ? { ...doc, progress } : doc
          )
        );
      })
        .then((url) => {
          setDocuments((prevDocuments) =>
            prevDocuments.map((doc) =>
              doc.file === file ? { ...doc, url, state: "finished" } : doc
            )
          );
        })
        .catch(() => {
          setDocuments((prevDocuments) =>
            prevDocuments.map((doc) =>
              doc.file === file ? { ...doc, state: "error" } : doc
            )
          );
        });
    });
  };
  
 

  // Remove File
  const removeFile = (type, index) => {
    if (type === "image") setImages(images.filter((_, i) => i !== index));
    if (type === "video") setVideo(null);
    if (type === "document") setDocuments(documents.filter((_, i) => i !== index));
  };

  // Save & Continue
  const handleSaveAndContinue = () => {
    const serviceData = {
      serviceTitle,
      category,
      subCategory,
      packages,
      milestones,
      description,
      faqs,
      media: {
        images: images.map((img) => img.url),
        video: video?.url || null,
        documents: documents.map((doc) => doc.url),
      },
    };

    navigate("/publishService", { state: { serviceData } });
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <SetupHeader currentStep={3} />

      <main className="p-6 md:p-10 my-20 max-w-4xl mx-auto bg-white rounded-2xl shadow-md border border-gray-200">
        <h1 className="text-2xl font-bold text-gray-800 mb-6">
          Upload Media for {serviceTitle}
        </h1>
        <p className="text-gray-600 mb-8">
          <strong>Category:</strong> {category} &nbsp; | &nbsp;
          <strong>Sub-Category:</strong> {subCategory}
        </p>

        {/* Images Section */}
        <section className="mb-8">
          <h2 className="text-xl font-semibold mb-2">Images (up to 8)</h2>
          <button
            className="px-4 py-2 bg-gray-200 rounded-lg font-medium hover:bg-gray-300"
            onClick={() => imageInputRef.current.click()}
          >
            Add Images
          </button>
          <input
            ref={imageInputRef}
            type="file"
            multiple
            accept="image/*"
            onChange={handleImageUpload}
            className="hidden"
          />
          <div className="grid grid-cols-4 gap-4 mt-4">
            {images.map((img, index) => (
              <div key={index} className="relative w-full h-32 bg-gray-100 rounded-lg">
                {img.localPreview && (
                  <img
                    src={img.localPreview}
                    alt={`Image ${index}`}
                    className={`w-full h-full object-cover rounded-lg ${
                      img.state === "uploading" ? "opacity-50" : "opacity-100"
                    }`}
                  />
                )}
                <button
                  className="absolute top-2 right-2 bg-red-500 text-white rounded-full p-1 text-sm"
                  onClick={() => removeFile("image", index)}
                >
                  ✕
                </button>
                {img.progress < 100 && img.state === "uploading" && (
                  <div
                    className="absolute bottom-0 left-0 h-1 bg-blue-500"
                    style={{ width: `${img.progress}%` }}
                  />
                )}
              </div>
            ))}
          </div>
        </section>

        {/* Video Section */}
        <section className="mb-8">
          <h2 className="text-xl font-semibold mb-2">Video (one only)</h2>
          <button
            className="px-4 py-2 bg-gray-200 rounded-lg font-medium hover:bg-gray-300"
            onClick={() => videoInputRef.current.click()}
          >
            Add Video
          </button>
          <input
            ref={videoInputRef}
            type="file"
            accept="video/*"
            onChange={handleVideoUpload}
            className="hidden"
          />
          {video && (
            <div className="relative mt-4">
              <video
                controls
                src={video.localPreview}
                className="w-full h-48 bg-gray-300 rounded-lg"
              />
              <button
                className="absolute top-2 right-2 bg-red-500 text-white rounded-full p-1 text-sm"
                onClick={() => removeFile("video")}
              >
                ✕
              </button>
              {video.progress < 100 && video.state === "uploading" && (
                <div
                  className="absolute bottom-0 left-0 h-1 bg-blue-500"
                  style={{ width: `${video.progress}%` }}
                />
              )}
            </div>
          )}
        </section>

        {/* Documents Section */}
        <section>
          <h2 className="text-xl font-semibold mb-2">Documents (up to 3)</h2>
          <button
            className="px-4 py-2 bg-gray-200 rounded-lg font-medium hover:bg-gray-300"
            onClick={() => documentInputRef.current.click()}
          >
            Add Documents
          </button>
          <input
            ref={documentInputRef}
            type="file"
            multiple
            accept="application/pdf"
            onChange={handleDocumentUpload}
            className="hidden"
          />
          <ul className="list-disc pl-5 mt-4 space-y-2">
            {documents.map((doc, index) => (
              <li key={index} className="relative text-gray-600 font-medium">
                Document {index + 1}
                <button
                  className="absolute right-0 text-red-500 text-sm hover:underline"
                  onClick={() => removeFile("document", index)}
                >
                  ✕
                </button>
                {doc.progress < 100 && doc.state === "uploading" && (
                  <div
                    className="absolute bottom-0 left-0 h-1 bg-blue-500"
                    style={{ width: `${doc.progress}%` }}
                  />
                )}
              </li>
            ))}
          </ul>
        </section>

        {/* Buttons */}
        <div className="flex justify-between items-center mt-8">
          <button
            className="text-gray-500 hover:text-gray-700 text-sm font-medium"
            onClick={() =>
              navigate("/faq", {
                state: { serviceTitle, category, subCategory, packages, milestones, description },
              })
            }
          >
            Back
          </button>
          <button
            onClick={handleSaveAndContinue}
            className="bg-blue-600 text-white px-6 py-3 rounded-lg font-medium hover:bg-blue-700 transition"
          >
            Save & Continue
          </button>
        </div>
      </main>

      <Footer />
    </div>
  );
}
