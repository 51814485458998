import React, { useState } from "react";
import {
  Box,
  Typography,
  Button,
  Grid,
  TextField,
  IconButton,
  CircularProgress,
} from "@mui/material";
import GoogleIcon from "@mui/icons-material/Google";
import EmailIcon from "@mui/icons-material/Email";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Img1 from "../../asserts/loginimage1.png";
import Img2 from "../../asserts/loginimage2.png";

const CreateAccount = ({ onNext }) => {
  const [showEmailForm, setShowEmailForm] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleEmailClick = () => {
    setShowEmailForm(true);
    setError("");
  };

  const handleBackClick = () => {
    setShowEmailForm(false);
    setError("");
  };

  const handleCreateAccount = async () => {
    onNext({ email, name });
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        bgcolor: "#EAF4E9",
        position: "relative",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "70vw",
          height: "70vh",
          bgcolor: "white",
          borderRadius: "16px",
          overflow: "hidden",
          boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Box
          sx={{
            flex: 1,
            bgcolor: "#0D3D12",
            position: "relative",
          }}
        >
          <Box
            component="img"
            src={Img1}
            alt="Profile 1"
            sx={{
              position: "absolute",
              top: "20%",
              left: "20%",
              width: "180px",
              height: "220px",
              borderRadius: "12px",
              objectFit: "cover",
              zIndex: 2,
              border: "4px solid #D3F26A",
            }}
          />
          <Box
            sx={{
              position: "absolute",
              top: "18%",
              left: "18%",
              width: "200px",
              height: "240px",
              borderRadius: "16px",
              zIndex: 1,
            }}
          />
          <Box
            component="img"
            src={Img2}
            alt="Profile 2"
            sx={{
              position: "absolute",
              bottom: "20%",
              right: "20%",
              width: "200px",
              height: "240px",
              borderRadius: "12px",
              objectFit: "cover",
              zIndex: 2,
              border: "4px solid #D3F26A",
            }}
          />
          <Box
            sx={{
              position: "absolute",
              bottom: "18%",
              right: "18%",
              width: "220px",
              height: "260px",
              borderRadius: "16px",
              zIndex: 1,
            }}
          />
        </Box>

        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            px: 6,
          }}
        >
          {showEmailForm ? (
            <Box
              sx={{
                width: "100%",
                maxWidth: "400px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <IconButton
                onClick={handleBackClick}
                sx={{
                  alignSelf: "flex-start",
                  mb: 2,
                  color: "#4caf50",
                }}
              >
                <ArrowBackIcon />
              </IconButton>

              <Typography
                variant="h5"
                sx={{
                  fontWeight: "bold",
                  mb: 4,
                  color: "#333",
                  textAlign: "center",
                }}
              >
                Sign up with Email
              </Typography>

              <TextField
                fullWidth
                label="Full Name"
                variant="outlined"
                value={name}
                onChange={(e) => setName(e.target.value)}
                sx={{ mb: 2 }}
              />

              <TextField
                fullWidth
                label="Email Address"
                variant="outlined"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                sx={{ mb: 2 }}
              />

              <TextField
                fullWidth
                type="password"
                label="Password"
                variant="outlined"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                sx={{ mb: 4 }}
              />

              {error && (
                <Typography
                  variant="body2"
                  sx={{
                    color: "red",
                    mb: 2,
                    textAlign: "center",
                  }}
                >
                  {error}
                </Typography>
              )}

              <Button
                variant="contained"
                fullWidth
                sx={{
                  bgcolor: "#4caf50",
                  color: "white",
                  textTransform: "none",
                  fontWeight: "bold",
                  borderRadius: "12px",
                  px: 4,
                  py: 1.5,
                  "&:hover": { bgcolor: "#45a049" },
                }}
                onClick={handleCreateAccount}
                disabled={loading}
              >
                {loading ? (
                  <CircularProgress size={24} sx={{ color: "white" }} />
                ) : (
                  "Create Account"
                )}
              </Button>
            </Box>
          ) : (
            <>
              <Typography
                variant="h4"
                sx={{ fontWeight: "bold", mb: 2, color: "#333" }}
              >
                Create a new account
              </Typography>
              <Typography variant="body1" sx={{ mb: 4, color: "#666" }}>
                Already have an account?{" "}
                <Typography
                  component="span"
                  sx={{
                    color: "#4caf50",
                    fontWeight: "bold",
                    cursor: "pointer",
                  }}
                >
                  Sign in
                </Typography>
              </Typography>
              <Button
                variant="outlined"
                startIcon={<GoogleIcon />}
                fullWidth
                sx={{
                  borderColor: "#E0E0E0",
                  color: "#333",
                  mb: 2,
                  py: 1.5,
                  textTransform: "none",
                  fontWeight: "bold",
                  "&:hover": {
                    borderColor: "#ccc",
                    backgroundColor: "#f9f9f9",
                  },
                }}
              >
                Continue with Google
              </Button>
              <Button
                variant="outlined"
                startIcon={<EmailIcon />}
                fullWidth
                sx={{
                  borderColor: "#E0E0E0",
                  color: "#333",
                  mb: 4,
                  py: 1.5,
                  textTransform: "none",
                  fontWeight: "bold",
                  "&:hover": {
                    borderColor: "#ccc",
                    backgroundColor: "#f9f9f9",
                  },
                }}
                onClick={handleEmailClick}
              >
                Continue with Email
              </Button>
             
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default CreateAccount;
