import React, { useState } from "react";
import NavigationTabs from "./NavigationTabs";
import ImageGallery from "./ImageGallery";
import ValueCard from "./ValueCard";
import MeetTheTeam from "./Team";
import ValueSystem from "./ValueSystem";
import Header from "../v2/LandingPage/Header";
import Footer from "../Footer";

const valueCardsData = [
  {
    bgColor: "bg-slate-200",
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/80400be013c3aeacefa3f1a1334718806b785a283f4e31e0ebae833976a39b15",
    title: "Our Mission",
    description:
      "To empower freelancers and businesses worldwide to collaborate, innovate, and thrive together in a borderless economy.",
  },
  {
    bgColor: "bg-orange-100",
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/46bcd81e8411d6f9e08dc9fe64834f8fe26e25de223f271d68e9671e05d5ba16",
    title: "Our Vision",
    description:
      "To lead the future of work by building a community where creativity and opportunity transcend boundaries, and where every connection drives meaningful success.",
  },
  {
    bgColor: "bg-gray-100",
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/930aba079f5d43918237618477b48d4bbee20ef67ead8846ad79fc327660964f",
    title: "Our Proposition",
    description:
      "A trusted platform to connect, create, and achieve—where great minds come together to redefine what's possible.",
  },
];

const tabs = [
  { label: "Who We Are", key: "whoWeAre" },
  { label: "Our Value System", key: "valueSystem" },
  { label: "Meet The Team", key: "meetTheTeam" },
];

function AboutPage() {
  const [activeTab, setActiveTab] = useState("whoWeAre");

  const renderContent = () => {
    switch (activeTab) {
      case "valueSystem":
        return <ValueSystem />;
      case "meetTheTeam":
        return <MeetTheTeam />;
      default:
        return (
          <>
           
            <div className="mt-20 text-7xl font-black text-center text-black max-md:mt-10 max-md:max-w-full max-md:text-4xl">
              Who We Are
            </div>
            <div className="mt-12 text-xl font-medium leading-8 text-center text-stone-500 w-[883px] max-md:mt-10 max-md:max-w-full">
              At Earn, we believe the power of connection can transform ideas
              into reality. Our platform is more than a freelance
              marketplace—it's a global community where businesses, creators,
              and professionals unite to innovate and grow. We're committed to
              breaking down barriers, fostering collaboration, and empowering
              individuals to achieve their goals without limits.
              <br />
              Every day, we see opportunities take flight as talented
              freelancers and visionary clients connect across the globe. By
              providing a seamless, secure, and supportive environment, we're
              shaping the way people work and paving the path for a more
              connected future.
            </div>
            <ImageGallery />
            <div className="mt-28 ml-3 text-7xl font-black text-center text-black max-md:mt-10 max-md:max-w-full max-md:text-4xl">
              What We Believe In
            </div>
            <div className="mt-32 w-full max-w-[1197px] max-md:mt-10 max-md:max-w-full">
              <div className="flex gap-5 max-md:flex-col">
                {valueCardsData.map((card, index) => (
                  <ValueCard key={index} {...card} />
                ))}
              </div>
            </div>
          </>
        );
    }
  };

  return (
    <div className="flex flex-col items-center rounded-none">
       <Header />
      <img
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/8956a9a9ece53ca6075d68475ddef451dcc0e1a61a19104784c976fad3ee3274"
        alt="About Us Banner"
        className="object-contain self-stretch w-full aspect-[6.29] max-md:max-w-full"
      />
      <NavigationTabs
        tabs={tabs}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
      {renderContent()}
      <Footer />
    </div>
  );
}

export default AboutPage;
