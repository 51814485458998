import * as React from "react";

const demoOrderData = [
  {
    serviceTitle: "Professional Copywriting Services",
    category: "Writing & Translation",
    subCategory: "Copywriting",
    packages: {
      Basic: {
        name: "Basic Copywriting Package",
        description: "Short-form content for blogs and articles.",
        deliverables: ["3 blog articles", "SEO-optimized"],
        fileTypes: ["Document files (PDF, Word)"],
        deliveryTime: "5 days",
        revisions: "2 revisions",
        isActive: true,
      },
      Standard: {
        name: "Standard Copywriting Package",
        description: "Detailed content for business websites.",
        deliverables: ["5 web pages", "SEO-optimized", "Proofreading"],
        fileTypes: ["Document files (PDF, Word)"],
        deliveryTime: "7 days",
        revisions: "3 revisions",
        isActive: false,
      },
      Premium: {
        name: "Premium Copywriting Package",
        description: "Comprehensive content strategy and implementation.",
        deliverables: ["10 web pages", "Content strategy", "SEO", "Proofreading"],
        fileTypes: ["Document files (PDF, Word)"],
        deliveryTime: "10 days",
        revisions: "Unlimited revisions",
        isActive: false,
      },
    },
  },
];

function OrderHistory() {
  return (
    <div className="flex flex-col p-6 mt-12 w-full bg-white rounded-lg shadow-lg max-w-4xl mx-auto">
      {/* Header Section */}
      <div className="flex justify-between items-center border-b pb-4">
        <h2 className="text-2xl font-semibold text-gray-800">Order History</h2>
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/14d1c6fe48f832e501d6b7c12511d04bf042941bb8cbd7aa50138ad941d7eb77?placeholderIfAbsent=true&apiKey=44862712bdb34f49aaf0b7f367088f08"
          alt="Order history icon"
          className="object-contain w-8 h-8"
        />
      </div>

      <div className="mt-6">
        {demoOrderData.map((order, index) => (
          <div
            key={index}
            className="p-6 mb-6 bg-gray-50 border border-gray-300 rounded-lg"
          >
            {/* Service Title */}
            <h3 className="text-xl font-bold text-gray-800">
              {order.serviceTitle}
            </h3>
            <div className="mt-2 text-sm text-gray-600">
              Category: <span className="font-medium">{order.category}</span> | Subcategory:{" "}
              <span className="font-medium">{order.subCategory}</span>
            </div>

            {/* Packages */}
            <div className="mt-4 grid grid-cols-1 md:grid-cols-3 gap-4">
              {Object.entries(order.packages).map(
                ([key, packageDetails]) =>
                  packageDetails.isActive && (
                    <div
                      key={key}
                      className="p-4 bg-white border border-green-500 rounded-lg shadow"
                    >
                      <h4 className="text-lg font-semibold text-green-600">
                        {packageDetails.name || `${key} Package`}
                      </h4>
                      <p className="mt-2 text-sm text-gray-600">
                        {packageDetails.description}
                      </p>
                      <ul className="mt-3 text-sm text-gray-800 list-disc list-inside">
                        {packageDetails.deliverables.map((item, idx) => (
                          <li key={idx}>{item}</li>
                        ))}
                      </ul>
                      <div className="mt-3">
                        <span className="block text-sm text-gray-500">
                          File Types:{" "}
                        </span>
                        <ul className="list-disc list-inside text-sm text-gray-800">
                          {packageDetails.fileTypes.map((fileType, idx) => (
                            <li key={idx}>{fileType}</li>
                          ))}
                        </ul>
                      </div>
                      <div className="mt-3 text-sm text-gray-600">
                        <span className="block">Delivery Time:</span>{" "}
                        <span className="font-medium">
                          {packageDetails.deliveryTime}
                        </span>
                      </div>
                      <div className="mt-1 text-sm text-gray-600">
                        <span className="block">Revisions:</span>{" "}
                        <span className="font-medium">
                          {packageDetails.revisions}
                        </span>
                      </div>
                    </div>
                  )
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default OrderHistory;
