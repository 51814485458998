import React from "react";
import "./AnimatedBackground.css"; // Styling for the animated background

const Background = () => {
  return (
    <div className="animated-background">
      <div className="gradient gradient-primary"></div>
      <div className="gradient gradient-secondary"></div>
    </div>
  );
};

export default Background;
