import React, { createContext, useContext, useState } from "react";
import axios from "../api/axios";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage"; // Firebase imports
import { storage } from "../config/firebase"; // Import Firebase app initialization


// Create the context
export const ContentContext = createContext();

export const useContent = () => {
  const context = useContext(ContentContext);
  if (!context) {
    throw new Error("useContent must be used within a ContentProvider");
  }
  return context;
};

// Provider component
export const ContentProvider = ({ children }) => {
  const [services, setServices] = useState([]);
  const [totalEarnings, setTotalEarnings] = useState(0);
  const [monthlyEarnings, setMonthlyEarnings] = useState(0);
  const [completedOrders, setCompletedOrders] = useState(0);
  const [serviceSuggestions, setServiceSuggestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [serviceCount, setServiceCount] = useState(0);

  // Fetch services
  const fetchServices = async (userId) => {
    setLoading(true);
    setError("");
    try {
      const response = await axios.get(`/api/products/services/count/${userId}`);
      setServices(response.data.services || []);
    } catch (err) {
      console.error("Error fetching services:", err);
      setError("Failed to fetch services.");
    } finally {
      setLoading(false);
    }
  };

  async function uploadMedia(media) {
    const uploadedMedia = {
      images: [],
      video: null,
      documents: [],
    };
  
    // Upload images
    if (media.images) {
      for (const image of media.images) {
        if (image) {
          const formData = new FormData();
          formData.append("file", image.blob);
          const response = await axios.post("/api/upload", formData);
          uploadedMedia.images.push(response.data.url);
        }
      }
    }
  
    // Upload video
    if (media.video && media.video.blob) {
      const formData = new FormData();
      formData.append("file", media.video.blob);
      const response = await axios.post("/api/upload", formData);
      uploadedMedia.video = response.data.url;
    }
  
    // Upload documents
    if (media.documents) {
      for (const doc of media.documents) {
        if (doc) {
          const formData = new FormData();
          formData.append("file", doc.blob);
          const response = await axios.post("/api/upload", formData);
          uploadedMedia.documents.push(response.data.url);
        }
      }
    }
  
    return uploadedMedia;
  }
  

  // Add a new service
  const addService = async (serviceData) => {
    console.log("serviceData", serviceData);
    setLoading(true);
    const files = serviceData.media;
    serviceData.files = null;
    setError("");
    try {

  
      
      const response = await axios.post("/api/products/services", serviceData, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.status === 201) {
        alert("Service added successfully.");
      }


      setServices((prevServices) => [...prevServices, response.data]);
    } catch (err) {
      console.error("Error adding service:", err);
      setError("Failed to add service.");
    } finally {
      setLoading(false);
    }
  };

  // Fetch total earnings
  const fetchTotalEarnings = async (userId) => {
    setLoading(true);
    setError("");
    try {
      const response = await axios.get(`/api/products/earnings/total/${userId}`);
      setTotalEarnings(response.data.totalEarnings || 0);
    } catch (err) {
      console.error("Error fetching total earnings:", err);
      setError("Failed to fetch total earnings.");
    } finally {
      setLoading(false);
    }
  };

  // Fetch monthly earnings
  const fetchMonthlyEarnings = async (userId) => {
    setLoading(true);
    setError("");
    try {
      const response = await axios.get(`/api/products/earnings/monthly/${userId}`);
      setMonthlyEarnings(response.data.monthlyEarnings || 0);
    } catch (err) {
      console.error("Error fetching monthly earnings:", err);
      setError("Failed to fetch monthly earnings.");
    } finally {
      setLoading(false);
    }
  };

  // Fetch completed orders
  const fetchCompletedOrders = async (userId) => {
    setLoading(true);
    setError("");
    try {
      const response = await axios.get(`/api/products/orders/completed/${userId}`);
      setCompletedOrders(response.data.completedOrders || 0);
    } catch (err) {
      console.error("Error fetching completed orders:", err);
      setError("Failed to fetch completed orders.");
    } finally {
      setLoading(false);
    }
  };

  // Fetch service suggestions
  const fetchServiceSuggestions = async (userId) => {
    setLoading(true);
    setError("");
    try {
      const response = await axios.get(`/api/products/services/suggestions/${userId}`);
      setServiceSuggestions(response.data.suggestions || []);
    } catch (err) {
      console.error("Error fetching service suggestions:", err);
      setError("Failed to fetch service suggestions.");
    } finally {
      setLoading(false);
    }
  };

  // Place an order
  const placeOrder = async (orderData) => {
    setLoading(true);
    setError("");
    try {
      const response = await axios.post(`/api/products/orders`, orderData);
      console.log("Order placed:", response.data);
    } catch (err) {
      console.error("Error placing order:", err);
      setError("Failed to place order.");
    } finally {
      setLoading(false);
    }
  };

  const fetchServiceCount = async (userId) => {
    setLoading(true);
    setError("");
    try {
      const response = await axios.get(`/api/products/services/count/${userId}`);
      setServiceCount(response.data.serviceCount || 0);
    } catch (err) {
      console.error("Error fetching service count:", err);
      setError("Failed to fetch service count.");
    } finally {
      setLoading(false);
    }
  };


  // Context value
  const value = {
    serviceCount,
    services,
    totalEarnings,
    monthlyEarnings,
    completedOrders,
    serviceSuggestions,
    loading,
    error,
    fetchServices,
    addService,
    fetchTotalEarnings,
    fetchMonthlyEarnings,
    fetchCompletedOrders,
    fetchServiceSuggestions,
    placeOrder,
    fetchServiceCount
  };

  return <ContentContext.Provider value={value}>{children}</ContentContext.Provider>;
};